import React from 'react';
import moment from 'moment';
import { withRouter, Link } from 'react-router-dom';
import { injectIntl } from 'react-intl';
import queryString from 'query-string';
import { registerLocale } from 'react-datepicker';
import { connect } from 'react-redux';
import sq from 'date-fns/locale/sq/index.js';
import en from 'date-fns/locale/en-US/index.js';
import _ from 'lodash';
import Grid from 'components/Grid.jsx';
import Modal from 'components/Modal.jsx';
import { APP_CONFIG, healthCareCdnUrl } from 'constants/global-variables.js';
import { getPreferredLanguageLocale, getStorage, stringToCamelCase } from 'infrastructure/helpers/commonHelpers.js';
import { uploadAttachment } from 'api/media.js';
import { getAppointmentDetails, getDoctorAvailability } from 'api/appointments.js';
import { reassignRequestsToDoctor, sendAppointmentRequest } from 'api/doctorRequests.js';
import ProfilePicture from 'components/ProfilePicture.jsx';
import Loader from 'components/Loader.jsx';
import PopUpAlert from 'components/PopUpAlert.jsx';
import {
	AlertTypes,
	AppointmentType,
	PresenceStatusType,
	CanceledReschedulingType,
	RequestStatus,
	WorkFlow,
	HealthcareErrorCode,
	UserStatus,
	ConversationType,
} from 'constants/enums.js';
import DoctorProfile from 'components/Doctors/DoctorProfile.jsx';
import translate from 'i18n-translations/translate.jsx';
import EmptyState from 'components/EmptyState.jsx';
import 'react-circular-progressbar/dist/styles.css';
import { getDoctorWorkflowAndNurses, getDoctorAssigned } from 'api/doctors.js';
import SocketEvents from 'constants/socket-events.js';
import { SocketContext } from 'infrastructure/socket-client/SocketContext.js';
import { getCompanyId, getUserId, getUserInfo, getUserRole } from 'infrastructure/auth.js';
import { updateAssignRequest } from 'api/patientRequests.js';
import { StartQueryStringKeys, UserRoles } from 'calls/enums/index.js';
import { getConversationByType } from 'api/messenger.js';
import { getDevices } from 'api/patients.js';
import CreateAppointment from 'components/Appointments/CreateAppointment.jsx';
import Button from 'components/Button.jsx';

registerLocale('en-US', en);
registerLocale('sq', sq);

class AvailableDoctors extends React.Component {
	doctorsWrapperElement = React.createRef();

	socket = this.context;

	state = {
		isViewProfileModalVisible: false,
		selectedDoctor: null,
		isTimeSlotsLoading: false,
		isAppointmentModalVisible: false,
		appointmentSelectedDate: new Date(),
		availableAppointments: [],
		error: null,
		attachments: [],
		doctors: [],
		searchedDoctors: [],
		pageIndex: 0,
		reachedEnd: false,
		isLoading: true,
		isScrollLoading: true,
		searchDoctorsValue: '',
		appointmentDetails: null,
		warningAlert: null,
		requestSent: null,
		cancelingCall: false,
		alreadyOnCallMessage: null,
	};

	userInfo = getUserInfo();

	patientId = getStorage().getItem('patientId');

	componentDidMount = async () => {
		this.getDoctors();
		const { isFromNotification, notificationActionType } = this.props;
		if (isFromNotification) {
			await this.getAppointmentDetail();
			if (notificationActionType === CanceledReschedulingType.RESCHEDULE) {
				this.toggleAppointmentModal(this.state.selectedDoctor);
			}
		}
		this.context.on(SocketEvents.HealthCare.ON_MEDIC_REQUEST_UPDATED, this.onLiveCallRequest);
		this.context.on(SocketEvents.Client.ON_NOTIFY_UPDATED_USER_PRESENCE, this.onUserUpdatedPresence);
		this.context.on(SocketEvents.Client.ON_DOCTOR_WORKFLOW_UPDATED, this.onDoctorWorkflowUpdated);
		this.context.on(SocketEvents.HelloDevice.ON_OFFLINE, this.onDeviceOffline);
		this.context.on(SocketEvents.HelloDevice.ON_BUSY, this.onDeviceBusy);
		this.context.on(SocketEvents.HelloDevice.ON_DOCTOR_REQUEST_RESPONSE, this.onDoctorRequestResponse);
		this.context.on(SocketEvents.Client.ON_USER_ONLINE, this.onUserOnline);
		this.context.on(SocketEvents.Client.ON_USER_OFFLINE, this.onUserOffline);
	};

	componentDidUpdate = prevProps => {
		const { isFromNotification, notificationActionType } = this.props;
		if (this.props.location.state && this.props.location.state !== prevProps.location.state) {
			if (isFromNotification && notificationActionType === CanceledReschedulingType.RESCHEDULE) {
				this.toggleAppointmentModal(this.state.selectedDoctor);
			}
		}
	};

	getAppointmentDetail = async () => {
		const response = await getAppointmentDetails(this.props.location.state?.notification?.payload.Id);
		if (response.error) {
			this.setState({ error: this.props.intl.formatMessage({ id: 'failedToLoad' }) });
			return;
		}
		const {
			appointmentDetails: { appointment },
		} = response;
		this.setState(prevState => ({
			appointmentDetails: appointment,
			selectedDoctor: appointment.createdBy?.id === getUserId() ? null : appointment.createdBy,
		}));
	};

	closeAppointmentModal = resetForm => {
		this.setState({ availableAppointments: [], isTimeSlotsLoading: false });
		this.toggleAppointmentModal();
		resetForm();
		this.setState({ appointmentSelectedDate: new Date() });
	};

	onUserUpdatedPresence = ({ objectId, presenceStatusTypeId }) => {
		const doctors = _.cloneDeep(this.state.doctors);
		const selectedDoctor = doctors.find(doctor => doctor.userId === parseInt(objectId, 10));
		if (selectedDoctor) {
			selectedDoctor.presenceStatusTypeId = presenceStatusTypeId;
			this.setState({ doctors });
			this.setState(prevState => ({
				searchedDoctors: prevState.doctors,
			}));
		}
	};

	onUserOnline = ({ userId }) => this.setUserStatus(userId, UserStatus.ONLINE);

	onUserOffline = ({ userId }) => this.setUserStatus(userId, UserStatus.OFFLINE);

	setUserStatus = (userId, status) => {
		const doctors = _.cloneDeep(this.state.searchedDoctors);
		const foundDoctor = doctors.find(doctor => doctor.userId === userId);
		if (foundDoctor) {
			foundDoctor.availability = status;
			this.setState({ doctors });
			this.setState(prevState => ({
				searchedDoctors: prevState.doctors,
			}));
		}
	};

	componentWillUnmount = () => {
		this.context.off(SocketEvents.HealthCare.ON_MEDIC_REQUEST_UPDATED, this.onLiveCallRequest);
		this.context.off(SocketEvents.Client.ON_NOTIFY_UPDATED_USER_PRESENCE, this.onUserUpdatedPresence);
		this.context.off(SocketEvents.Client.ON_DOCTOR_WORKFLOW_UPDATED, this.onDoctorWorkflowUpdated);
		this.context.off(SocketEvents.HelloDevice.ON_OFFLINE, this.onDeviceOffline);
		this.context.off(SocketEvents.HelloDevice.ON_DOCTOR_REQUEST_RESPONSE, this.onDoctorRequestResponse);
		this.context.off(SocketEvents.HelloDevice.ON_BUSY, this.onDeviceBusy);
		this.context.off(SocketEvents.Client.ON_USER_ONLINE, this.onUserOnline);
		this.context.off(SocketEvents.Client.ON_USER_OFFLINE, this.onUserOffline);
	};

	onLiveCallRequest = data => {
		const { userId, waitingRoomCount } = data;
		const doctors = [...this.state.doctors];
		const selectedDoctor = doctors.find(doctor => doctor.userId === userId);
		if (selectedDoctor) {
			selectedDoctor.waitingRoomCount = waitingRoomCount;
			this.setState({ doctors });
		}
		this.getDoctors(true);
	};

	onDoctorWorkflowUpdated = data => {
		const { userId, nurses } = data;
		const doctors = [...this.state.doctors];
		const selectedDoctor = doctors.find(doctor => doctor.userId === userId);
		if (selectedDoctor) {
			selectedDoctor.nurses = nurses;
			this.setState({ doctors });
		}
	};

	getDoctors = async isRequestUpdated => {
		const params = {
			pageIndex: !isRequestUpdated ? this.state.pageIndex : 0,
			pageSize: 20,
			search: this.state.searchDoctorsValue,
		};
		const doctors = await this.getDoctorAssigned(params);
		if (doctors.error) {
			this.setState({
				error: `${this.props.intl.formatMessage({ id: 'errorOccurred' })} ${doctors.error.message}`,
				isLoading: false,
				isScrollLoading: false,
			});
		} else {
			this.setState(prevState => ({
				doctors: isRequestUpdated ? doctors : prevState.doctors.concat(doctors),
				searchedDoctors: isRequestUpdated ? doctors : prevState.searchedDoctors.concat(doctors),
				pageIndex: prevState.pageIndex + 1,
				isScrollLoading: false,
				reachedEnd: doctors.length < 20,
				isLoading: false,
				requestSent: prevState.requestSent ? null : prevState.requestSent,
			}));
		}
	};

	getDoctorAssigned = async params => {
		const response = await getDoctorAssigned(
			this.props.departmentId ?? this.userInfo.patientExtendedProfile?.healthSystemId,
			params
		);
		if (response.error) {
			return response;
		}
		this.setState({ isLoading: false });
		return response.doctors;
	};

	toggleViewProfileModal = doctor => {
		this.setState(prevState => ({
			isViewProfileModalVisible: !prevState.isViewProfileModalVisible,
			selectedDoctor: doctor,
		}));
	};

	toggleAppointmentModal = doctor => {
		let doctorDetails;
		this.setState(
			prevState => ({
				isAppointmentModalVisible: !prevState.isAppointmentModalVisible,
				isTimeSlotsLoading: true,
				error: null,
			}),
			async () => {
				if (this.state.isAppointmentModalVisible) {
					if (!doctor && this.props.notificationActionType === CanceledReschedulingType.RESCHEDULE) {
						await this.getAppointmentDetail();
						const { appointmentDetails } = this.state;
						doctorDetails = appointmentDetails.appointmentInvitation.invitedUser?.specialty
							? appointmentDetails.appointmentInvitation.invitedUser
							: appointmentDetails.createdBy;
					}
					const userId = doctorDetails ? doctorDetails.id : doctor.id;
					const params = {
						userId,
						startDate: moment(this.state.appointmentSelectedDate).format('YYYY-MM-DD'),
						endDate: moment(this.state.appointmentSelectedDate).format('YYYY-MM-DD'),
					};
					const response = await getDoctorAvailability(params);
					if (response.error) {
						this.setState({ error: response.error.message, availableAppointments: [] });
						return;
					}
					let { availableAppointments } = response;
					availableAppointments = availableAppointments.sort(
						(a, b) =>
							new Date(`1970/01/01 ${this.getTimeSpan(a.startDateTime)}`).getTime() -
							new Date(`1970/01/01 ${this.getTimeSpan(b.startDateTime)}`).getTime()
					);
					this.setState({ availableAppointments, selectedDoctor: doctor, isTimeSlotsLoading: false, error: null });
					const { error, sortedAppointments } = await this.getDoctorAvailableSlots(params);
					if (error) {
						this.setState({ error, availableAppointments: [] });
						return;
					}
					this.setState({
						availableAppointments: sortedAppointments,
						isTimeSlotsLoading: false,
						error: null,
						selectedDoctor: doctor || doctorDetails,
					});
				}
			}
		);
	};

	getDoctorAvailableSlots = async params => {
		const response = await getDoctorAvailability(params);
		if (response.error) {
			return { error: response.error.message };
		}
		return {
			sortedAppointments: response.availableAppointments.sort(
				(a, b) =>
					new Date(`1970/01/01 ${this.getTimeSpan(a.startDateTime)}`).getTime() -
					new Date(`1970/01/01 ${this.getTimeSpan(b.startDateTime)}`).getTime()
			),
		};
	};

	getTimeSpan = dateTime => moment.utc(dateTime).local().locale(getPreferredLanguageLocale()).format('HH:mm:ss');

	getSelectedNurse = async () => {
		let selectedNurse = null;
		const response = await getDoctorWorkflowAndNurses(this.state.selectedDoctor.id);
		if (response.error) {
			this.setState({ error: response.error.message });
		} else {
			const { doctorWorkflowAndNurses } = response;
			if (doctorWorkflowAndNurses.workflowTypeId === WorkFlow.THROUGH_NURSES) {
				selectedNurse = doctorWorkflowAndNurses.associatedNurses.reduce((prev, curr) =>
					prev.waitingRoomCount < curr.waitingRoomCount ? prev : curr
				);
			}
		}
		return selectedNurse;
	};

	getErrorMessage = errorCode => {
		switch (errorCode) {
			case HealthcareErrorCode.PATIENT_EXISTING_PENDING_REQUEST:
				return this.props.intl.formatMessage({ id: 'alreadyWaitingForRequest' });
			case HealthcareErrorCode.PATIENT_EXISTING_APPOINTMENT_TIMESLOT:
				return this.props.intl.formatMessage({ id: 'timeSlotExists' });
			case HealthcareErrorCode.DEVICE_OFFLINE:
				return this.props.intl.formatMessage({ id: 'deviceOfflineNow' });
			case HealthcareErrorCode.DEVICE_NOT_AVAILABLE_FOR_CALL:
				return this.props.intl.formatMessage({ id: 'deviceNotAvailableForCall' });
			case HealthcareErrorCode.DEVICE_STATE_NOT_AVAILABLE:
				return this.props.intl.formatMessage({ id: 'couldNotGetDeviceState' });
			default:
				return this.props.intl.formatMessage({ id: 'somethingWentWrong' });
		}
	};

	onDoctorRequestResponse = data => {
		if (data.isSuccessful) {
			this.setState({ requestSent: this.props.intl.formatMessage({ id: 'requestHasBeenSent' }) });
			this.getDoctors(true);
		}
	};

	sendAppointmentRequest = async values => {
		this.setState({
			isScrollLoading: true,
			isLoading: true,
		});
		const description = this.props.additionalData.additionalNotes;
		const requestAttachments = await this.uploadAttachmentsToCdnStorage();
		const conversationResult = await getConversationByType(
			ConversationType.INDIVIDUAL,
			this.state.selectedDoctor?.userId,
			0,
			false
		);
		const invitedMember = {
			invitedUserId: this.state.selectedDoctor?.id,
			conversationId: conversationResult.conversation?.id,
		};
		const symptomIds = this.props.symptoms.map(symptom => symptom.id);
		const appointmentSelectedDate = moment(values.appointmentSelectedDate).format('YYYY-MM-DD');
		const appointment = {
			title: values.topic,
			date: appointmentSelectedDate,
			description,
			appointmentSlotId: values.appointmentSlotId,
			typeId: AppointmentType.REQUEST,
			appointmentInvitation: invitedMember,
			appointmentAttachments: requestAttachments,
		};
		const request = {
			medicId: this.state.selectedDoctor.id,
			nextMedicId: null,
			description,
			appointment,
			symptomIds,
			medicalQuestionAnswers: this.props.additionalData.medicalQuestionAnswers.filter(item => item.answer),
			pharmacy: this.props.selectedPharmacy,
			deliveryTypeId: this.props.selectedPharmacy?.deliveryTypeId,
			requestAttachments,
			patientId: getUserId(),
			deviceId: null,
		};
		const response = await sendAppointmentRequest(request, getCompanyId());
		if (response.error) {
			this.setState({ error: response.error.response.data.message || response.error.message, isRequestSuccessful: false });
		} else {
			this.setState({
				isRequestSuccessful: true,
				error: null,
			});
		}
		this.setState({ isScrollLoading: false, isLoading: false });
	};

	sendDoctorRequestEventOnHello = params => {
		const deviceId = getStorage().getItem('helloDeviceId');
		this.socket.emit(
			SocketEvents.HelloDevice.SEND_DOCTOR_REQUEST,
			{ helloDeviceId: parseInt(deviceId, 10), payload: params },
			ack => {
				if (!ack.isSuccessful) {
					this.setState({ error: this.props.intl.formatMessage({ id: 'noPermission' }) });
				}
			}
		);
	};

	sendDoctorRequest = async () => {
		this.setState({
			isScrollLoading: true,
			isLoading: true,
		});
		const description = this.props.additionalData.additionalNotes;
		const symptomIds = this.props.symptoms.map(symptom => symptom.id);
		const requestAttachments = await this.uploadAttachmentsToCdnStorage();
		const selectedNurse = await this.getSelectedNurse();
		const deviceId = getStorage().getItem('helloDeviceId');
		const request = {
			medicId: selectedNurse ? selectedNurse.nurse.id : this.state.selectedDoctor.id,
			nextMedicId: selectedNurse ? this.state.selectedDoctor.id : null,
			description,
			symptomIds,
			medicalQuestionAnswers: this.props.additionalData.medicalQuestionAnswers.filter(item => item.answer),
			pharmacy: this.props.selectedPharmacy,
			deliveryTypeId: this.props.selectedPharmacy?.deliveryTypeId,
			requestAttachments,
			patientId: this.props.isCheckInPatient ? this.patientId : getUserId(),
			deviceId: this.props.isCheckInPatient ? parseInt(deviceId, 10) : null,
			createdByUserId: this.props.isCheckInPatient ? getUserInfo().userId : null,
			createdById: this.props.isCheckInPatient ? getUserId() : null,
		};
		if (this.props.isCheckInPatient) {
			this.sendDoctorRequestEventOnHello(request);
		}
		this.setState(
			{
				isScrollLoading: false,
				isLoading: false,
			},
			() => {
				if (!this.props.isCheckInPatient) {
					this.startWaitingRoom(request);
					this.getDoctors(true);
				}
			}
		);
	};

	onDeviceOffline = data => {
		const helloDeviceId = getStorage().getItem('helloDeviceId');
		if (data.helloDeviceId === parseInt(helloDeviceId, 10)) {
			this.setState({
				error: this.props.intl.formatMessage({ id: 'deviceOfflineNow' }),
				requestSent: null,
			});
		}
	};

	onDeviceBusy = data => {
		const helloDeviceId = getStorage().getItem('helloDeviceId');
		if (data.helloDeviceId === parseInt(helloDeviceId, 10)) {
			this.setState({
				error: this.props.intl.formatMessage({ id: 'deviceIsBusy' }),
				requestSent: null,
			});
		}
	};

	startWaitingRoom = requestData => {
		const fullName = `${this.state.selectedDoctor.firstName} ${this.state.selectedDoctor.lastName}`;
		const queryParams = queryString.stringify(
			{
				[StartQueryStringKeys.CONFERENCE_NAME]: fullName,
				[StartQueryStringKeys.IS_MEETING_ROOM]: true,
			},
			{
				skipNull: true,
			}
		);
		const win = window.open(`/call?${queryParams.toString()}`, '_blank');
		win.addEventListener(
			'message',
			event => {
				if (event.origin.replace(/\/$/, '') !== APP_CONFIG.URL.localApiBasePath.replace(/\/$/, '')) {
					return;
				}
				if (event.data === 'conference.hasStarted') {
					const doctorRequestPayload = { messageType: 'doctorRequestData', requestData };
					win.postMessage(doctorRequestPayload);
					this.setState({ isRequestSuccessful: true, error: null });
				}
				if (['create.request.hasFailed', 'conference.hasFailed'].includes(event.data)) {
					this.setState({
						isRequestSuccessful: false,
						error:
							event.data === 'create.request.hasFailed'
								? this.props.intl.formatMessage({ id: 'sendRequestFailed' })
								: this.props.intl.formatMessage({ id: 'conferenceFailed' }),
					});
				}
			},
			false
		);
	};

	seeDoctorNow = selectedDoctor => {
		this.setState({ selectedDoctor }, async () => {
			if (this.canCancelRequest(selectedDoctor) && !this.state.cancelingCall) {
				this.cancelCall(selectedDoctor.pendingRequests.find(req => req.request.patient?.id).id);
				return;
			}
			if (this.props.isReassign && !this.props.isCheckInPatient) {
				this.reassignRequest(selectedDoctor.id);
			} else {
				if (this.props.room?.deviceBusy) {
					this.setState({ alreadyOnCallMessage: this.props.intl.formatMessage({ id: 'youAreAlreadyOnCall' }) });
					return;
				}
				this.sendDoctorRequest();
			}
		});
	};

	reassignRequest = async doctorId => {
		const response = await reassignRequestsToDoctor(this.props.location.state.selectedRequest.id, doctorId);
		if (response.error) {
			this.setState({ error: response.error.message });
		} else {
			this.startWaitingRoom(this.props.location.state.selectedRequest.id);
			this.setState({
				isRequestSuccessful: true,
			});
		}
	};

	onAppointmentsCalendarChange = async (selectedDate, setFieldValue) => {
		setFieldValue('appointmentSlotId', '');
		setFieldValue('appointmentSelectedDate', selectedDate);

		this.setState({ availableAppointments: [], isTimeSlotsLoading: true });
		const params = {
			userId: this.state.selectedDoctor.id,
			startDate: moment(selectedDate).format('YYYY-MM-DD'),
			endDate: moment(selectedDate).format('YYYY-MM-DD'),
		};
		let { availableAppointments } = await getDoctorAvailability(params);
		availableAppointments = availableAppointments.sort(
			(a, b) =>
				new Date(`1970/01/01 ${this.getTimeSpan(a.startDateTime)}`).getTime() -
				new Date(`1970/01/01 ${this.getTimeSpan(b.startDateTime)}`).getTime()
		);
		this.setState({ availableAppointments, isTimeSlotsLoading: false });
		const { error, sortedAppointments } = await this.getDoctorAvailableSlots(params);
		this.setState({ availableAppointments: error ? [] : sortedAppointments, isTimeSlotsLoading: false, error: error || null });
	};

	filterNonRejectedDoctors = (doctors, rejected) => {
		const filteredDocs = [];
		doctors.forEach(doc => {
			const foundItem = rejected.find(item => doc.id === item.doctor.id);
			if (!foundItem) {
				filteredDocs.push(doc);
			}
		});
		return filteredDocs;
	};

	onScheduleAppointmentClick = (values, { setSubmitting }) => {
		this.toggleAppointmentModal(this.state.selectedDoctor);
		setSubmitting(true);
		this.sendAppointmentRequest(values);
	};

	uploadAttachmentsToCdnStorage = async () => {
		const selectedAttachments = [...this.props.additionalData.attachments];
		const attachements = await Promise.all(
			selectedAttachments.map(async selectedAttachment => {
				const formData = new FormData();
				formData.append('File', selectedAttachment);
				const uploadResponse = await uploadAttachment(formData);
				if (uploadResponse.error) {
					return {};
				}
				const attachment = {
					fileName: uploadResponse.fileName,
					originalFileName: uploadResponse.originalFileName,
					fileTypeId: uploadResponse.fileType,
				};
				return attachment;
			})
		);
		return attachements.filter(item => Object.keys(item).length > 0);
	};

	onFileDeleteHandler = id => {
		const prevAttachments = [...this.state.attachments];
		const attachments = prevAttachments.filter(attachment => attachment.id !== id);
		this.setState({ attachments });
	};

	handleScroll = async e => {
		const isBottom = e.target.scrollHeight - Math.ceil(e.target.scrollTop) === e.target.clientHeight;
		if (isBottom && !this.state.isScrollLoading && !this.state.reachedEnd && !this.state.searchDoctorsValue) {
			this.setState({ isScrollLoading: true }, () => {
				this.getDoctors();
			});
		}
	};

	handleSearch = event => {
		this.setState({ searchDoctorsValue: event.target.value }, () => {
			if (this.onSearchTypeTimeout) clearTimeout(this.onSearchTypeTimeout);
			this.onSearchTypeTimeout = setTimeout(async () => {
				if (this.state.searchDoctorsValue.length < 2) {
					this.setState(prevState => ({
						searchedDoctors: prevState.doctors,
					}));
				} else {
					const params = {
						pageIndex: 0,
						pageSize: 20,
						search: this.state.searchDoctorsValue,
					};
					const doctors = await this.getDoctorAssigned(params);
					if (doctors.error) {
						this.setState({ error: `${this.props.intl.formatMessage({ id: 'errorOccurred' })} ${doctors.error}` });
					} else {
						this.setState({
							searchedDoctors: doctors,
						});
					}
				}
			}, 500);
		});
	};

	getWaitingRoomCount = doctor => {
		if (!this.props.isCheckInPatient && doctor.nurses?.length === 0) {
			return doctor.waitingRoomCount;
		}
		if (this.props.isCheckInPatient) {
			return (
				doctor?.pendingRequests?.length &&
				doctor.pendingRequests?.map(item => item.request).some(item => item.patient.id !== this.patientId)
			);
		}
		const selectedNurse = doctor.nurses?.reduce((prev, curr) => (prev.waitingRoomCount < curr.waitingRoomCount ? prev : curr));
		return selectedNurse?.waitingRoomCount + doctor.waitingRoomCount;
	};

	canCancelRequest = doctor => {
		const patientId = this.props.isCheckInPatient ? this.patientId : this.userInfo.id;
		return doctor.pendingRequests?.map(item => item.request).some(item => item?.patient?.id === patientId);
	};

	cancelCall = async assignedRequestId => {
		this.setState({ cancelingCall: true });
		const response = await updateAssignRequest(assignedRequestId, {
			RequestStatusId: RequestStatus.CANCELED,
		});
		if (response.error) {
			this.setState({ error: response.error.message, cancelingCall: false });
			return;
		}
		this.setState({ cancelingCall: false });
		this.getDoctors(true);
	};

	getBtnText = doctor => {
		if (this.canCancelRequest(doctor)) {
			return translate('cancelCall');
		}
		if (this.getWaitingRoomCount(doctor) > 0) {
			return translate('goToWaitingRoom');
		}
		return translate(this.props.isCheckInPatient ? 'seeDoctorInDC' : 'seeDoctorNow');
	};

	getBtnColor = doctor => {
		if (this.canCancelRequest(doctor)) {
			return '--red-background';
		}
		if (this.getWaitingRoomCount(doctor) > 0) {
			return '--orange-background';
		}
		return '--dark-green-background';
	};

	isUserOffline = doctor => (doctor?.availability ? doctor?.availability === UserStatus.OFFLINE : !doctor.isOnline);
	isUserOnline = doctor => (doctor?.availability ? doctor?.availability === UserStatus.ONLINE : doctor.isOnline);

	isDoctorUnAvailable = doctor => doctor?.presenceStatusTypeId === PresenceStatusType.UNAVAILABLE || this.isUserOffline(doctor);
	isDoctorAvailable = doctor => doctor?.presenceStatusTypeId === PresenceStatusType.AVAILABLE && this.isUserOnline(doctor);

	render() {
		return (
			<>
				<Grid columns='1fr' stretch='100%'>
					{!this.state.isLoading && (
						<div
							className='available-doctors'
							data-cy='availableDoctors'
							onScroll={this.handleScroll}
							ref={this.doctorsWrapperElement}>
							<div className='available-doctors-title'>
								<h3>{translate('availableDoctors')}</h3>
								<div className='position-relative flex flex-direction-row flex-justify-center doctor-search'>
									<img src={`${healthCareCdnUrl}search-icon.svg`} alt='ico' className='search-doctor-icon' />
									<input
										type='search'
										id='search'
										name='search'
										placeholder={this.props.intl.formatMessage({ id: 'searchForDoctors' })}
										maxLength={100}
										value={this.state.searchDoctorsValue}
										onChange={this.handleSearch}
										autoComplete='off'
									/>
								</div>
							</div>
							<div className='available-doctor-items'>
								{this.state.searchedDoctors.map(doctor => (
									<div key={doctor.id}>
										<div
											className='view-profile-icon'
											data-cy='viewProfileBtn'
											onClick={() => this.toggleViewProfileModal(doctor)}>
											<img
												src='https://static.solaborate.com/healthcare-system/doctor-request/view-more-profile.svg'
												alt='icon'
											/>
										</div>
										<ProfilePicture
											className='doctor-request-img available-doctor-request-img'
											isActive={doctor.presenceStatusTypeId === PresenceStatusType.AVAILABLE}
											firstName={doctor.firstName}
											lastName={doctor.lastName}
											profilePicture={doctor.profilePicture}
										/>
										<p>
											Dr. {doctor.firstName} {doctor.lastName}
										</p>
										<span>
											{doctor.specialty && doctor.specialty.name
												? translate(stringToCamelCase(doctor.specialty.name))
												: translate('familyPhysician')}
										</span>
										{this.getWaitingRoomCount(doctor) === 0 && this.isDoctorAvailable(doctor) && (
											<p className='available-doctor-paragraph break-word green-color'>
												Dr. {doctor.firstName} {doctor.lastName}{' '}
												{!this.props.isCheckInPatient ? translate('availableNow') : translate('availableForCallNow')}
											</p>
										)}
										{this.isDoctorUnAvailable(doctor) && (
											<p className='available-doctor-paragraph break-word --red-color'>
												Dr. {doctor.firstName} {doctor.lastName} {translate('unAvailableForCallNow')}
											</p>
										)}
										{this.getWaitingRoomCount(doctor) > 0 && doctor.presenceStatusTypeId === PresenceStatusType.AVAILABLE && (
											<p className='available-doctor-paragraph break-word orange-color'>
												Dr. {doctor.firstName} {doctor.lastName}
												{translate('hasPatientsInWaitingList', {
													value: this.getWaitingRoomCount(doctor),
												})}
											</p>
										)}
										<div className='available-doctor-btn full-width' data-cy='availableDoctorBtn'>
											{this.isDoctorAvailable(doctor) && (
												<Button onClick={_.debounce(() => this.seeDoctorNow(doctor), 500)} text={this.getBtnText(doctor)} />
											)}
											{getUserRole() !== UserRoles.DIGITAL_CLINICIAN && (
												<Button
													disabled={this.canCancelRequest(doctor)}
													onClick={() => this.toggleAppointmentModal(doctor)}
													text={this.props.intl.formatMessage({ id: 'scheduleAppointment' })}
												/>
											)}
										</div>
									</div>
								))}
							</div>
							{this.state.searchedDoctors.length === 0 && !this.state.isScrollLoading && (
								<div className='empty-state-wrapper-percent available-doc-empty-state-wrapper'>
									<EmptyState
										title={translate('noMatchesFound')}
										image='no-available-doctors.svg'
										paragraph={translate('noDoctorsBasedOnSymptoms')}
									/>
								</div>
							)}
							{this.state.isScrollLoading && (
								<div className='patient-request-loader-pagination'>
									<Loader />
								</div>
							)}
						</div>
					)}
					{this.state.isLoading && (
						<div className='full-width flex flex-justify-center available-doctors flex-align-center'>
							<span className='loader gif-loading'>
								<Loader />
							</span>
						</div>
					)}

					<Modal
						modalSelector='viewProfileModal'
						display={this.state.isViewProfileModalVisible}
						position='center'
						className='standard-modal-wrapper modal-wrapper-wo-btn '
						onModalClose={() => this.toggleViewProfileModal()}>
						{this.state.selectedDoctor && <DoctorProfile doctor={this.state.selectedDoctor} />}
					</Modal>
					<CreateAppointment
						appointmentSelectedDate={this.state.appointmentSelectedDate}
						selectedCalendarEventStart={this.state.selectedCalendarEventStart}
						isAppointmentModalVisible={this.state.isAppointmentModalVisible}
						isTimeSlotsLoading={this.state.isTimeSlotsLoading}
						availableAppointments={this.state.availableAppointments}
						toggleAppointmentModal={this.toggleAppointmentModal}
						closeAppointmentModal={this.closeAppointmentModal}
						onScheduleAppointmentClick={this.onScheduleAppointmentClick}
						onAppointmentsCalendarChange={this.onAppointmentsCalendarChange}
					/>
				</Grid>
				<PopUpAlert
					alertType={AlertTypes.DANGER}
					display={this.state.error || this.state.alreadyOnCallMessage}
					onAlertClose={() => this.setState({ error: null, alreadyOnCallMessage: null })}
					contentText={this.state.error || this.state.alreadyOnCallMessage}
					isSilent={true}
					center={true}
				/>
				<PopUpAlert
					alertType={AlertTypes.WARNING}
					display={this.state.warningAlert}
					onAlertClose={() => this.setState({ warningAlert: null })}
					contentText={this.state.warningAlert}
					isSilent={true}
					center={true}
				/>
				<PopUpAlert
					alertType={AlertTypes.SUCCESS}
					display={this.state.requestSent}
					onAlertClose={() => this.setState({ requestSent: null })}
					contentText={this.state.requestSent}
					isSilent={true}
					center={true}
				/>
				{this.state.isRequestSuccessful && (
					<div className='successful-request-wrapper' data-cy='successfulRequestModal'>
						<div className='successful-request'>
							<img src='https://static.solaborate.com/healthcare-system/doctor-request/request-success.svg' alt='icon' />
							<h3>{translate('thankYou')}</h3>
							<p>{translate('requestSentSuccessfully')}</p>
							<Link to='/doctor-requests'>
								<Button text={translate('done')} />
							</Link>
						</div>
					</div>
				)}
			</>
		);
	}
}

const mapStateToProps = state => {
	return {
		user: state.user,
	};
};

export default injectIntl(withRouter(connect(mapStateToProps)(AvailableDoctors)));

AvailableDoctors.contextType = SocketContext;
