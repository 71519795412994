import React, { useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import classNames from 'classnames';
import Grid from 'components/Grid.jsx';
import { healthCareCdnUrl, primaryCareImageUrl } from 'constants/global-variables.js';
import translate from 'i18n-translations/translate.jsx';
import ProfilePicture from 'components/ProfilePicture.jsx';
import Modal from 'components/Modal.jsx';
import EmptyState from 'components/EmptyState.jsx';
import Loader from 'components/Loader.jsx';
import { getCheckedInUsersByChannelType } from 'api/doctors.js';
import { Alert, Button } from 'components/index.js';
import { RoomTypes } from 'constants/visitEnums.js';
import { PresenceStatusType } from 'constants/enums.js';
import { useSelector } from 'react-redux';

const SelectDoctor = props => {
	const [isLoading, setIsLoading] = useState(true);
	const [specialists, setSpecialists] = useState([]);
	const [error, setError] = useState(null);
	const intl = useIntl();
	const doctorsWrapperElement = useRef(null);
	const companySettings = useSelector(state => state.company.companySettings);

	useEffect(() => {
		const fetchSpecialists = async () => {
			setIsLoading(true);
			const response = await getCheckedInUsersByChannelType(props.specialistType);
			if (response.error) {
				setError(response.error.message);
			} else {
				setSpecialists(response);
			}
			setIsLoading(false);
		};
		fetchSpecialists();
		return () => {
			props.setSpecialistType(RoomTypes.DOCTOR.type);
			props.setSelectedCase(null);
			props.setReferredVisitId(null);
		};
	}, []);

	const getRoomIcon = () => {
		let result = 'doctor.svg';
		switch (props.specialistType) {
			case RoomTypes.LABORATORY.type:
				result = 'laboratory.svg';
				break;
			case RoomTypes.INFUSION_ROOM.type:
				result = 'infusion-room.svg';
				break;
			case RoomTypes.INJECTION_ROOM.type:
				result = 'injection-room.svg';
				break;
			case RoomTypes.RADIOLOGY.type:
				result = 'radiology.svg';
				break;
			default:
				break;
		}
		return result;
	};

	return (
		<Grid columns='1fr' stretch='100%'>
			{!isLoading && (
				<div className='check-in-patient-wrapper position-relative'>
					<div className='available-doctors' data-cy='availableDoctors' ref={doctorsWrapperElement}>
						<div className='available-doctors-title'>
							<div className='check-in-title'>
								<h3>
									{translate('availableSomethings', {
										value: props.specialistType === RoomTypes.DOCTOR.type ? 'doctors' : companySettings.nurseDisplayName,
									})}
								</h3>
								<p>{translate('referToOneOfTheDoctors')}</p>
							</div>
						</div>
						<div className='flex flex-space-between primary-care-title-wrapper'>
							<Button
								imgIcon={`${primaryCareImageUrl}back-arrow.svg`}
								className={classNames('visit-btn blue-btn', !props.selectedPatient ? 'disabled' : '')}
								onClick={props.goBack}
								text={translate('goBack')}
							/>
						</div>
						<div className='available-doctor-items'>
							{specialists.map(doctor => (
								<div key={doctor.userId}>
									<ProfilePicture
										className='doctor-request-img available-doctor-request-img'
										isActive={doctor.presenceStatusTypeId === PresenceStatusType.AVAILABLE}
										fullName={doctor.fullName}
										profilePicture={
											props.specialistType === RoomTypes.DOCTOR.type
												? doctor.profilePicture
												: `${primaryCareImageUrl}icons/${getRoomIcon()}`
										}
									/>
									{props.specialistType === RoomTypes.DOCTOR.type && <span className='top-15'>Dr. {doctor.fullName}</span>}
									<p className={props.specialistType === RoomTypes.DOCTOR.type ? 'no-margin' : ''}>{doctor.teamChannelName}</p>
									<span className={classNames('margin-top-m', doctor.queueCount > 0 ? ' --blue-color' : ' --green-color')}>
										{translate('patientQueue')}: {doctor.queueCount} <br />
									</span>

									<div className='available-doctor-btn full-width'>
										<Button
											text={translate('referToThisRoom')}
											type='button'
											onClick={() => {
												props.submitMedicalCase(doctor);
											}}
											isLoading={props.isReferLoading === doctor.id}
											isDisabled={props.isReferLoading === doctor.id}
										/>
									</div>
								</div>
							))}
						</div>
						{specialists.length === 0 && (
							<div className='empty-state-wrapper-percent available-doc-empty-state-wrapper'>
								<EmptyState
									title={translate('noMatchesFound')}
									image='no-available-doctors.svg'
									paragraph={translate('noDoctorsBasedOnSymptoms')}
								/>
							</div>
						)}
					</div>
					<Modal
						display={props.showSuccessModal}
						onModalSubmit={props.setDoctorReferSuccess}
						onModalClose={props.setDoctorReferSuccess}
						primaryButtonLabel={intl.formatMessage({ id: 'done' })}
						position='center'
						shouldSubmitOnEnter={false}
						hideCloseButton={true}
						className='wrapper-modal border-radius-modal-wrapper appoinment-next-arrow-modal saved-successfully'>
						<div>
							<h3>{translate('success')}</h3>
							<p>{translate('patientAddedDoctorWaitingList')}</p>
							<p>{translate('referralDetails')}</p>
							<div className='saved-successfully-details-wrapper'>
								{props.selectedDoctor && (
									<>
										<div className='profile-details'>
											<div>
												<>
													<ProfilePicture
														fullName={props.selectedDoctor.fullName}
														profilePicture={
															props.specialistType === RoomTypes.DOCTOR.type
																? props.selectedDoctor.profilePicture
																: `${primaryCareImageUrl}icons/${getRoomIcon()}`
														}
														className='doctor-request-img appointment-img'
													/>
													<div>
														{props.specialistType === RoomTypes.DOCTOR.type && (
															<p className='regular-weight'>Dr. {props.selectedDoctor.fullName}</p>
														)}
														<p className={props.specialistType === RoomTypes.DOCTOR.type ? 'no-margin-top' : ''}>
															{props.selectedDoctor.teamChannelName}
														</p>
													</div>
												</>
											</div>
										</div>
										<div className='time-details'>
											<div>
												<img src={`${healthCareCdnUrl}appointments/calendar-icon.svg`} alt='calendar-icon' />
												<div>
													<p>
														{translate('number')}: {props.checkInNumber}
													</p>
												</div>
											</div>
										</div>
										<div>
											<div>
												<img src={`${healthCareCdnUrl}appointments/calendar-icon.svg`} alt='calendar-icon' />
												<div>
													<p>
														{translate('queueNumber')} : {props.selectedDoctor.queueCount}{' '}
													</p>
												</div>
											</div>
										</div>
										<div>
											<div>
												<img src={`${healthCareCdnUrl}appointments/calendar-icon.svg`} alt='calendar-icon' />
												<div>
													<p>
														{translate('waitingTime')}: {props.selectedDoctor.queueCount * 10} {translate('minutes')}
													</p>
												</div>
											</div>
										</div>
									</>
								)}
							</div>
						</div>
					</Modal>
				</div>
			)}
			{isLoading && (
				<div className='full-width flex flex-justify-center available-doctors flex-align-center'>
					<span className='loader gif-loading'>
						<Loader />
					</span>
				</div>
			)}
			<Alert display={error || props.error} fixed={true} hideCloseButton={true} message={error || props.error} variant='dark' />
		</Grid>
	);
};

export default SelectDoctor;
