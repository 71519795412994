import { IntegrationTypesSettings } from 'constants/configurationEnums.js';

export const groupTypes = {
	DEFAULT: { id: 1, title: 'default' },
	ECM: { id: 2, title: 'ecm' },
	SMART_FHIR: { id: 3, title: 'smartFHIR' },
	NURSE_CALLING: { id: 4, title: 'somethingCalling' },
	TRANSLATION_SERVICES: { id: 5, title: 'interpretationServices' },
	PATIENT_INFOTAINMENT: { id: 6, title: 'patientInfotainment' },
};

export const configurationTypeIds = {
	ECARE_API_KEY: {
		id: 1,
		description: 'E-care API Key',
		groupId: groupTypes.ECM.id,
		visiblePropery: 'apiKey',
		featureFlagKey: null,
		requiredFields: ['apiKey'],
	},
	ECARE_BASE_URL: {
		id: 2,
		description: 'E-care Base URL',
		groupId: groupTypes.ECM.id,
		visiblePropery: 'baseUrl',
		featureFlagKey: null,
		requiredFields: ['baseUrl'],
	},
	EPIC: {
		id: 3,
		description: 'Epic',
		groupId: groupTypes.SMART_FHIR.id,
		visiblePropery: 'clientId',
		featureFlagKey: IntegrationTypesSettings.EPIC,
		requiredFields: ['oauthScopes', 'returnUrl', 'issuer', 'clientSecret', 'clientId'],
	},
	CERNER: {
		id: 4,
		description: 'Cerner',
		groupId: groupTypes.SMART_FHIR.id,
		visiblePropery: 'clientId',
		featureFlagKey: IntegrationTypesSettings.CERNER,
		requiredFields: ['oauthScopes', 'returnUrl', 'issuer', 'clientSecret', 'clientId'],
	},
	// MEDPLUM: {
	// 	id: 5,
	// 	description: 'Med Plum',
	// 	groupId: groupTypes.NURSE_CALLING.id,
	// 	visiblePropery: '',
	// 	featureFlagKey: null,
	//  requiredFields: [],
	// },
	GLOBO: {
		id: 6,
		description: 'Globo',
		groupId: groupTypes.TRANSLATION_SERVICES.id,
		visiblePropery: 'baseUrl',
		featureFlagKey: IntegrationTypesSettings.GLOBO,
		requiredFields: ['authToken', 'sharedToken', 'authSecret'],
	},
	AMN: {
		id: 7,
		description: 'AMN',
		groupId: groupTypes.TRANSLATION_SERVICES.id,
		visiblePropery: 'baseUrl',
		featureFlagKey: IntegrationTypesSettings.AMN,
		requiredFields: ['sipEndpoint'],
	},
	HILL_ROM: {
		id: 8,
		description: 'Hill Rom',
		groupId: groupTypes.NURSE_CALLING.id,
		visiblePropery: 'secretKey',
		featureFlagKey: IntegrationTypesSettings.HILL_ROM,
		requiredFields: ['host', 'port', 'path', 'secretKey', 'partnerName'],
	},
	LANGUAGE_LINE: {
		id: 9,
		description: 'Language Line',
		groupId: groupTypes.TRANSLATION_SERVICES.id,
		visiblePropery: 'baseUrl',
		featureFlagKey: IntegrationTypesSettings.LANGUAGE_LINE,
		requiredFields: ['authCode'],
	},
	MOVIES: {
		id: 11,
		description: 'Movies',
		groupId: groupTypes.PATIENT_INFOTAINMENT.id,
		visiblePropery: 'type',
		featureFlagKey: null,
		requiredFields: [],
	},
	LIVE_TV_CHANNELS: {
		id: 12,
		description: 'Live TV Channels',
		groupId: groupTypes.PATIENT_INFOTAINMENT.id,
		visiblePropery: 'type',
		featureFlagKey: null,
		requiredFields: [],
	},
	MUSIC: {
		id: 13,
		description: 'Music',
		groupId: groupTypes.PATIENT_INFOTAINMENT.id,
		visiblePropery: 'type',
		featureFlagKey: null,
		requiredFields: [],
	},
	VOYCE: {
		id: 15,
		description: 'VOYCE',
		groupId: groupTypes.TRANSLATION_SERVICES.id,
		visiblePropery: 'baseUrl',
		featureFlagKey: IntegrationTypesSettings.VOYCE,
		requiredFields: ['token', 'callType'],
	},
};

export const PatientInfotainmentProviders = {
	MANUAL: 1,
	SWANK: 2,
	MAYO: 3,
};
