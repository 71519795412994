import { useMemo } from 'react';
import AiSummaryChart from 'components/DashboardCharts/PatientCentric/AI/AiSummaryChart.jsx';
import { AiAlertActivityType } from 'constants/enums.js';
import { AiAlertActivityTypeChartDetails } from 'constants/alerts.js';
import { useSelector } from 'react-redux';

const AiAlertActivityTypesStats = ({ aiAlertActivityTypes, totalAiAlertActivityTypes, isLoading }) => {
	const companySettings = useSelector(state => state.company.companySettings);

	const chartData = useMemo(() => {
		const alertActivityTypeMap = {
			[AiAlertActivityType.ACKNOWLEDGED]: 'acknowledged',
			[AiAlertActivityType.AI_FAKE_ALERT]: 'falseAlerts',
			[AiAlertActivityType.AI_FORWARD_TO_NURSES]: 'forwardedToSomething',
		};

		const result = aiAlertActivityTypes.reduce((acc, { activityType, count, percentage }) => {
			const key = alertActivityTypeMap[activityType];
			acc[key] = count;
			acc[`${key}Percentage`] = percentage;
			return acc;
		}, {});
		return result;
	}, [aiAlertActivityTypes]);

	return (
		<AiSummaryChart
			total={totalAiAlertActivityTypes}
			chartData={chartData}
			chartDetails={AiAlertActivityTypeChartDetails(companySettings)}
			dataSets={aiAlertActivityTypes.map(item => item.count)}
			isLoading={isLoading}
			title='aiAlertStats'
			displayInSeconds={false}
		/>
	);
};
export default AiAlertActivityTypesStats;
