import { getPatientInterventions, getPatientReport } from 'api/dashboard.js';
import LightTheme from 'calls/styles/LightTheme.js';
import { Alert, CustomTable, ToastMessage } from 'components/index.js';
import ProfilePicture from 'components/ProfilePicture.jsx';
import { DefaultTimezone, EventCategoryIcon, formatDateHeader } from 'constants/dashboard.js';
import { SortOrder, TaskStatus } from 'constants/enums.js';
import SocketEvents from 'constants/socket-events.js';
import translate from 'i18n-translations/translate.jsx';
import Download from 'icons/Dashboard/Download.jsx';
import Timer from 'icons/Dashboard/Timer.jsx';
import { formatTimeDurationWithSeconds, timezoneToUTCTimestamp, utcToTimezone } from 'infrastructure/helpers/dateHelper.js';
import { SocketContext } from 'infrastructure/socket-client/SocketContext.js';
import { debounce } from 'lodash';
import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { useLocation } from 'react-router';

const DefaultSorting = {
	columnId: 'date',
	order: SortOrder.ASCENDING,
};

const Interventions = ({ dateRange, downloadButtonRef }) => {
	const [isLoading, setIsLoading] = useState(true);
	const [error, setError] = useState('');
	const [taskId, setTaskId] = useState(null);
	const [reportTaskStatus, setReportTaskStatus] = useState(null);
	const [interventions, setInterventions] = useState([]);
	const [pagination, setPagination] = useState({ pageIndex: 0, pageSize: 10, totalCount: 0 });
	const [timezone, setTimezone] = useState(DefaultTimezone);
	const [sorting, setSorting] = useState(DefaultSorting);
	const socket = useContext(SocketContext);
	const location = useLocation();
	const intl = useIntl();
	const controller = useRef(null);

	const downloadPatientReport = useCallback(async () => {
		const { patient, healthSystemId } = location.state;

		const response = await getPatientReport({
			healthSystemId,
			patientId: patient.patientId,
			from: timezoneToUTCTimestamp(dateRange.from, timezone.zone),
			to: timezoneToUTCTimestamp(dateRange.to, timezone.zone),
			timezone: timezone.zone,
		});

		if (response.error) {
			setError(response.error.message);
			return;
		}
		setTaskId(response.taskId);
	}, [dateRange.from, dateRange.to, location.state, timezone.zone]);

	useEffect(() => {
		if (downloadButtonRef.current) {
			downloadButtonRef.current.addEventListener('click', downloadPatientReport);
		}
		return () => {
			if (downloadButtonRef.current) {
				downloadButtonRef.current.removeEventListener('click', downloadPatientReport);
			}
		};
	}, [downloadButtonRef, downloadPatientReport]);

	const fetchPatientInterventions = useCallback(
		debounce(async (params, signal) => {
			if (params.pageIndex === 0) {
				setInterventions([]);
			}
			const response = await getPatientInterventions(params, signal);
			if (!response.error) {
				setPagination(prevState => ({ ...prevState, totalCount: response.pagination.total }));
				setIsLoading(false);
				setInterventions(prevState => [...prevState, ...response.interventions]);
			} else if (response.error.code !== 'ERR_CANCELED') {
				setError(response.error.message);
				setIsLoading(false);
			}
		}, 500),
		[]
	);

	useEffect(() => {
		if (controller.current) {
			controller.current.abort();
		}
		controller.current = new AbortController();
		const signal = controller.current.signal;

		const {
			patient,
			healthSystemId,
			filters: { selectedTimezone },
		} = location.state;

		const params = {
			healthSystemId,
			patientId: patient.patientId,
			from: timezoneToUTCTimestamp(dateRange.from, selectedTimezone.zone),
			to: timezoneToUTCTimestamp(dateRange.to, selectedTimezone.zone),
			pageSize: pagination.pageSize,
			pageIndex: pagination.pageIndex,
			sortBy: sorting.columnId,
			sortOrder: sorting.order,
		};
		setIsLoading(true);
		fetchPatientInterventions(params, signal);
		setTimezone(selectedTimezone);
	}, [location, pagination.pageIndex, pagination.pageSize, dateRange, sorting, fetchPatientInterventions]);

	useEffect(() => {
		setPagination(prevState => ({ ...prevState, pageIndex: 0 }));
	}, [dateRange, sorting]);

	useEffect(() => {
		const handleTaskStatusUpdated = data => {
			if (taskId === data.taskId && [TaskStatus.RUNNING, TaskStatus.COMPLETED].includes(data.taskStatusId)) {
				setReportTaskStatus(data.taskStatusId);
			}
		};
		socket.on(SocketEvents.BACKGROUND.TASK_UPDATED, handleTaskStatusUpdated);
		return () => {
			socket.off(SocketEvents.BACKGROUND.TASK_UPDATED, handleTaskStatusUpdated);
		};
	}, [socket, taskId]);

	const displayPatientInterventions = () =>
		interventions.map(intervention => ({
			type: intervention.eventCategoryName ? (
				<div className='flex flex-align-center'>
					{EventCategoryIcon[intervention.eventCategoryValue]}
					<span className='left-margin-l'>{intervention.eventCategoryName}</span>
				</div>
			) : (
				'N/A'
			),
			event: intervention.event,
			description: intervention?.description || '',
			date: utcToTimezone(intervention.date?.replace('Z', ''), timezone.zone),
			duration: !isNaN(intervention.duration)
				? formatTimeDurationWithSeconds({ seconds: intervention.duration, joinWith: ', ', showDays: false, intl })
				: '',
			intervenedBy: intervention.providerName ? (
				<div className='flex flex-align-center'>
					<ProfilePicture
						fullName={intervention.providerName}
						profilePicture={intervention.providerProfilePicture ?? null}
						className='provider-intervening-img-wrapper'
					/>
					<p className='margin-s no-padding'>{intervention.providerName}</p>
				</div>
			) : (
				'N/A'
			),
		}));

	return (
		<div>
			<ToastMessage
				key={reportTaskStatus}
				className='dashboard-report'
				onClose={() => setReportTaskStatus(null)}
				showToast={reportTaskStatus}
				timer={10000}>
				<span>
					{reportTaskStatus === TaskStatus.COMPLETED && <Download color={LightTheme.colors.greenTwo} />}
					{reportTaskStatus === TaskStatus.RUNNING && <Timer color={LightTheme.colors.greenTwo} />}
				</span>
				<div>
					{reportTaskStatus === TaskStatus.COMPLETED && (
						<>
							<span>{translate('reportSentSuccessfully')}</span>
							<p>{translate('toAccessCheckEmail')}</p>
						</>
					)}
					{reportTaskStatus === TaskStatus.RUNNING && (
						<>
							<span>{translate('reportIsUnderway')}</span>
							<p>{translate('reportUnderwayDescription')}</p>
							<p>{translate('thankYouForPatience')}</p>
						</>
					)}
				</div>
			</ToastMessage>
			<div className='patients-table-wrapper'>
				<CustomTable
					headers={[
						{ title: translate('type'), id: 'type' },
						{ title: translate('event'), id: 'event' },
						{ title: translate('description'), id: 'description', columnWidth: '20%' },
						{ title: formatDateHeader(intl, 'date', timezone.zone), id: 'date' },
						{ title: translate('duration'), id: 'duration' },
						{ title: translate('intervenedBy'), id: 'intervenedBy' },
					]}
					rows={displayPatientInterventions()}
					isLoading={isLoading}
					sortable={true}
					sortingFunction={setSorting}
					defaultSort={DefaultSorting}
					stickyHeader={true}
					setPagination={setPagination}
				/>
			</div>
			<Alert display={error} fixed={true} onClose={() => setError(null)} message={error} variant='dark' />
		</div>
	);
};

export default Interventions;
