import React, { useState } from 'react';
import classNames from 'classnames';
import { AlertTypes, MeasurementActivityTypes } from 'constants/enums.js';
import translate from 'i18n-translations/translate.jsx';
import { addAlertActivity } from 'api/alerts.js';
import Alert from 'components/Alert.jsx';
import Button from 'components/Button.jsx';

const WarningDetection = props => {
	const [alertType, setAlertType] = useState(null);
	const [error, setError] = useState(null);

	const onSubmit = async activityType => {
		const params = {
			alertId: props.warningObject.alertId,
			activityType,
			isAiAlert: props.warningObject.isAiAlert,
			comment: '',
			isDraft: true,
		};
		const response = await addAlertActivity(params);
		if (response.error) {
			setError(response.error.message);
		}
		props.setSnoozeType(props.feedDeviceId, alertType);
		setAlertType(null);
		props.onAlertCloseClick(true);
	};

	return (
		<>
			{!alertType && (
				<div
					className={classNames(
						'detection-wrapper',
						!props.isFeedExpanded && props.numberOfFeeds !== 1 ? 'fall-detection-wrapper' : '',
						props.warningObject.isToastMessage ? 'toast-message-warning' : '',
						props.warningObject.value ? 'show' : ''
					)}>
					<div className={`alert-body fall-alert-body ${props.alertType}`}>
						<div className='alert-content'>
							<div>
								<i className='material-icons'>
									{props.alertType === AlertTypes.SUCCESS ? 'check_circle_outline' : 'error_outline'}
								</i>
							</div>
							<div>
								<p className='warning-wrapper-text'>{props.warningObject.text}</p>
							</div>
							{props.warningObject.isToastMessage && (
								<div>
									<i className='material-icons cursor-pointer close-alert' onClick={() => props.onAlertCloseClick(true)}>
										close
									</i>
								</div>
							)}
						</div>
						{!props.warningObject.isToastMessage && (
							<div className='alert-feed-sub-option flex flex-justify-center'>
								<div className='alert-feed-sub-options-wrapper'>
									<div
										className={classNames(
											'flex',
											props.showForwardToNurses ? 'alert-controls-with-forward' : '',
											'alert-controls flex-justify-center'
										)}>
										<Button
											className='flex flex-align-center flex-justify-center false-alert-btn'
											onClick={() => {
												setAlertType(MeasurementActivityTypes.MEASUREMENT_FAKE_ALERT);
												onSubmit(MeasurementActivityTypes.MEASUREMENT_FAKE_ALERT);
											}}
											icon='close'
											text={translate('falseAlert')}
										/>
										<Button
											className='flex flex-align-center flex-justify-center acknowledge-alert-btn'
											onClick={() => {
												setAlertType(MeasurementActivityTypes.ACKNOWLEDGED);
												onSubmit(MeasurementActivityTypes.ACKNOWLEDGED);
											}}
											marginRight='0'
											icon='done'
											text={translate('acknowledge')}
										/>
									</div>
									{props.showForwardToNurses && (
										<Button
											className='flex flex-align-center flex-justify-center forward-alert-btn'
											onClick={() => {
												props.onForwardToNurses(props.warningObject.alertId, props.warningObject.isAiAlert);
												props.onAlertCloseClick(true);
											}}
											icon='arrow_forward'
											text={translate('forwardToNurses')}
										/>
									)}
								</div>
							</div>
						)}
					</div>
				</div>
			)}
			{error && <Alert display={error} fixed={true} hideCloseButton={true} message={error} variant='dark' />}
		</>
	);
};

export default WarningDetection;
