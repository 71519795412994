import React from 'react';
import styled from 'styled-components';

import { WindowSize } from 'calls/enums/index.js';
import LightTheme from 'calls/styles/LightTheme.js';
import DarkTheme from 'calls/styles/DarkTheme.js';

/**
 * @type {import('styled-components').StyledComponent<"section", any, { $isRightToLeft: boolean,  $isDarkMode: boolean }, never>}
 */
const StyledCallControls = styled.section`
	display: flex;
	position: fixed;
	justify-content: center;
	bottom: 0;
	left: 0;
	z-index: 11;
	width: 100%;
	height: 60px;
	padding: ${LightTheme.spacing[1]}px;
	background: ${props => (props.$isDarkMode ? DarkTheme.colors.grayThree : LightTheme.colors.grayZero)};

	> main,
	> aside {
		flex: 1;
	}

	> aside {
		display: flex;
		align-items: center;
		&:last-of-type > div {
			margin-left: ${props => (props.$isRightToLeft ? '0' : 'auto')};
			margin-right: ${props => (props.$isRightToLeft ? 'auto' : '0')};
		}
	}

	> main,
	> aside > div {
		display: grid;
		grid-auto-flow: column;
		align-items: center;
		justify-content: center;
		grid-gap: 6px;
	}

	@media (max-width: ${WindowSize.TABLET}px) {
		height: unset;
		display: flex;
		flex-direction: column;
		align-items: center;
		background: linear-gradient(0deg, rgba(0, 0, 0, 0.9) 0%, rgba(0, 0, 0, 0.7) 33%, rgba(0, 0, 0, 0) 100%);
		transform: none;
		padding-bottom: ${LightTheme.spacing[3]}px;
		margin-bottom: env(safe-area-inset-bottom);

		> aside:first-of-type {
			margin-bottom: ${LightTheme.spacing[2]}px;
			> div {
				display: flex;
			}
		}

		> aside:last-of-type {
			display: none;
		}
	}
`;

const CallControls = ({ children, isRightToLeft, isDarkMode }) => {
	return (
		<StyledCallControls $isRightToLeft={isRightToLeft} $isDarkMode={isDarkMode}>
			{children}
		</StyledCallControls>
	);
};

const Info = ({ children }) => {
	return <aside>{children}</aside>;
};

const Controls = ({ children }) => {
	return <main>{children}</main>;
};

const OtherControls = ({ children }) => {
	return <aside>{children}</aside>;
};

CallControls.Info = Info;
CallControls.Controls = Controls;
CallControls.OtherControls = OtherControls;

export default CallControls;
