import translate from 'i18n-translations/translate.jsx';
import {
	checkIPPermission,
	clearStorage,
	getCompanyId,
	getUserId,
	getUserInfo,
	getUserRole,
	setUserRole,
} from 'infrastructure/auth.js';
import {
	buildTree,
	decodeHtml,
	getConfigurationMenu,
	getHealthSystemDevices,
	getIsOldUserExperience,
	getStorage,
	getUserRoleId,
	isMobileOrTabletDevice,
} from 'infrastructure/helpers/commonHelpers.js';
import { buildProfilePicUrl } from 'infrastructure/helpers/thumbnailHelper.js';
import React, { useContext, useEffect, useRef, useState } from 'react';
import UserRolesList from 'components/UserRolesList.jsx';
import Dropdown from 'components/Dropdown.jsx';
import { healthCareCdnUrl } from 'constants/global-variables.js';
import {
	AlertTypes,
	DeviceListLevel,
	EventSubscriberRoomType,
	HealthcareErrorCode,
	PresenceStatusType,
	UserRoles,
} from 'constants/enums.js';
import { useSelector } from 'react-redux';
import { updateUserPresence } from 'api/userPresence.js';
import { actionCreators as userActionCreators } from 'state/user/actions.js';
import { useHistory } from 'react-router';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { actionCreators as healthSystemsActionCreators } from 'state/healthSystems/actions.js';
import { actionCreators as devicesActionCreators } from 'state/devices/actions.js';
import { actionCreators as configurationActionCreators } from 'state/configurations/actions.js';
import ConfigurableMenu from 'components/ConfigurableMenu.jsx';
import { getUserPreferences, updateSession } from 'api/users.js';
import { getHealthSystems } from 'api/healthSystems.js';
import { getHealthSystemRoleConfigs, getTeamSettings } from 'api/adminConfigurations.js';
import { GeneralAndMenuSettings, RoundingSettings, SettingsCategory, UserSettingTypes } from 'constants/configurationEnums.js';
import { getRegionSubTree } from 'api/tree.js';
import Alert from 'components/Alert.jsx';
import PopUpAlert from 'components/PopUpAlert.jsx';
import _ from 'lodash';
import {
	buildCallSettings,
	buildHealthSystemConfigurations,
	buildHSMenuConfigsForAllRoles,
	buildMenuConfigsPerRole,
	buildRoleSettings,
	buildVisualsSettings,
} from 'infrastructure/helpers/configurationsHelpers.js';
import classNames from 'classnames';
import { SocketFunctionsContext } from 'infrastructure/socket-client/SocketFunctions.jsx';

const UserAccount = props => {
	const userInfo = getUserInfo();
	const healthSystems = useSelector(state => state.healthSystems);
	const companyConfigurations = useSelector(state => state.company.companySettings?.companyConfigurations);
	const { current: userId } = useRef(getUserId());
	const wrapperRef = useRef(null);
	const socketFunctions = useContext(SocketFunctionsContext);

	const [error, setError] = useState(null);
	const [showDropdownOnTouch, setShowDropdownOnTouch] = useState('');
	const [isMenuSettingsOpen, setIsMenuSettingsOpen] = useState(false);
	const [updateTreeError, setUpdateTreeError] = useState('');

	const intl = useIntl();
	const companyId = getCompanyId();
	const history = useHistory();
	const user = useSelector(state => state.user);
	const configurations = useSelector(state => state.configurations);
	const dispatch = useDispatch();
	const setUserSession = session => dispatch(userActionCreators.setUserSession(session));

	const onUpdateUserPresence = async presenceStatusTypeId => {
		const response = await updateUserPresence(userId, presenceStatusTypeId, '');
		if (response.error) {
			setError(response.error.message);
		} else {
			dispatch(userActionCreators.setUserPresenceStatus(presenceStatusTypeId));
		}
	};

	const openDropdownModal = className => {
		if (isMobileOrTabletDevice()) {
			setShowDropdownOnTouch(className);
		}
	};

	const handleTouchOutsideItem = event => {
		if (!wrapperRef.current || wrapperRef.current.contains(event.target)) {
			return;
		}
		setShowDropdownOnTouch('');
	};

	useEffect(() => {
		document.addEventListener('mousedown', handleTouchOutsideItem);
		return () => {
			document.removeEventListener('mousedown', handleTouchOutsideItem);
		};
	}, []);

	const toggleFullScreen = () => {
		if (healthSystems.isFullscreen) {
			closeFullscreen();
		} else {
			openFullscreen();
		}
	};

	const openFullscreen = () => {
		const elem = document.documentElement;
		if (elem.requestFullscreen) {
			elem.requestFullscreen();
		} else if (elem.mozRequestFullScreen) {
			/* Firefox */

			elem.mozRequestFullScreen();
		} else if (elem.webkitRequestFullscreen) {
			/* Chrome, Safari & Opera */

			elem.webkitRequestFullscreen();
		} else if (elem.msRequestFullscreen) {
			/* IE/Edge */

			elem.msRequestFullscreen();
		}
	};

	const closeFullscreen = () => {
		if (document.exitFullscreen) {
			document.exitFullscreen();
		} else if (document.mozCancelFullScreen) {
			document.mozCancelFullScreen();
		} else if (document.webkitExitFullscreen) {
			document.webkitExitFullscreen();
		} else if (document.msExitFullscreen) {
			document.msExitFullscreen();
		}
	};

	const checkOut = async checkedInChannelId => {
		const response = await updateSession({
			healthSystemId: user.userSession.healthSystem.id,
			checkedInChannelId,
			floorId: null,
			departmentId: null,
			companyId,
		});
		if (response.error) {
			if (response.error.response.data.code === HealthcareErrorCode.PATIENT_IN_QUEUE) {
				setError(translate('patientInQueue'));
			} else if (response.error.response.data.code === HealthcareErrorCode.MEDICAL_VISIT_INITIATED) {
				setError(translate('medicalVisitInitiated'));
			} else {
				setError(response.error.message);
			}
			return;
		}
		getStorage().removeItem('helloDeviceId');
		setUserSession({ ...user.userSession, checkedInChannelId: null });
	};

	const signOut = () => {
		if (user.userSession.checkedInChannelId) {
			checkOut(null);
		}
		clearStorage();
		history.push('/logout');
	};

	const hasAdminConfigs = () => Object.values(configurations.adminConfigurableMenu[getUserRole()]).some(item => item);

	const showMenuSettings = () => {
		if ([UserRoles.VISITOR, UserRoles.SUPER_ADMIN].includes(getUserRole())) {
			return false;
		}
		return ![UserRoles.NURSE, UserRoles.VIRTUAL_SITTER, UserRoles.DOCTOR].includes(getUserRole()) || hasAdminConfigs();
	};

	const updateTree = async (healthSystemId = user.userSession.healthSystem.id, regionId = user.userSession.regionId) => {
		setUpdateTreeError('');
		const subTreeResponse = await getRegionSubTree(healthSystemId, regionId);

		if (subTreeResponse.error) {
			setUpdateTreeError(`${intl.formatMessage({ id: 'errorOccurred' })} ${subTreeResponse.error.message}`);
			dispatch(devicesActionCreators.setBulkDevicesBusy([]));
			dispatch(devicesActionCreators.setBulkDevicesOnline([]));
			dispatch(devicesActionCreators.setBulkDevicesPrivacy([]));
			dispatch(devicesActionCreators.setBulkPairedRemoteDevice([]));
			dispatch(healthSystemsActionCreators.setHealthSystem(null));
			dispatch(healthSystemsActionCreators.setTreeData([]));
		} else {
			const { healthSystem } = subTreeResponse.organization;
			const treeData = buildTree(healthSystem);
			const { online, busy, privacy, pairedRemote } = getHealthSystemDevices(healthSystem);
			dispatch(devicesActionCreators.setBulkDevicesBusy(busy));
			dispatch(devicesActionCreators.setBulkDevicesOnline(online));
			dispatch(devicesActionCreators.setBulkDevicesPrivacy(privacy));
			dispatch(devicesActionCreators.setBulkPairedRemoteDevice(pairedRemote));
			dispatch(healthSystemsActionCreators.setHealthSystem(healthSystem));
			dispatch(healthSystemsActionCreators.setTreeData(treeData));
		}
	};

	const switchRole = async role => {
		props.setIsLoading(true);
		let roomsToUnsubcribe = [EventSubscriberRoomType.DEVICE_STATUS];
		if (getUserRole() === UserRoles.NURSE) {
			roomsToUnsubcribe = [...roomsToUnsubcribe, EventSubscriberRoomType.AI_EVENTS, EventSubscriberRoomType.PATIENT_EVENTS];
		}

		socketFunctions.unSubscribeFromHSEvents({ healthSystemId: user.userSession.healthSystem.id, rooms: roomsToUnsubcribe });

		const response = await updateSession({
			healthSystemId: user.userSession.healthSystem.id,
			checkedInChannelId: user.userSession.checkedInChannelId || null,
			floorId: user.userSession.floorId || null,
			departmentId: user.userSession.departmentId || null,
			companyId: getCompanyId(),
			roleId: role.id,
		});
		setUserRole(role.name);
		if (response.error) {
			if (response.error.response.data.code === HealthcareErrorCode.PATIENT_IN_QUEUE) {
				setError(translate('patientInQueue'));
			}
			if (response.error.response.data.code === HealthcareErrorCode.MEDICAL_VISIT_INITIATED) {
				setError(translate('medicalVisitInitiated'));
			} else {
				setError(response.error.message);
			}
			return;
		}
		dispatch(healthSystemsActionCreators.setIsHealthSystemFetched(false));
		const healthSystemsRes = await getHealthSystems();
		let selectedHs = user.userSession.healthSystem;
		let regionId = user.userSession.healthSystem.regionId || user.userSession.healthSystem.regions[0].id;
		if (!healthSystemsRes.error) {
			const foundHs = healthSystemsRes.find(item => item.id === user.userSession.healthSystem.id);
			if (!foundHs && healthSystemsRes.length > 0) {
				[selectedHs] = healthSystemsRes;
				regionId = selectedHs.regions[0].id;
			}

			const configurableMenu = getConfigurationMenu(configurations.configurableMenu, role.name);
			const [configs, navigationResponse] = await Promise.all([
				await getHealthSystemRoleConfigs(selectedHs.id),
				await getUserPreferences(UserSettingTypes.Navigation),
			]);

			if (role.name !== UserRoles.ADMIN) {
				await checkIPPermission();
			}
			await updateTree(selectedHs.id, regionId);
			if (!navigationResponse.error) {
				const navMenu = {
					...configurations.configurableMenu,
					[role.name]: buildMenuConfigsPerRole(navigationResponse, _.cloneDeep(configurableMenu)),
				};
				dispatch(configurationActionCreators.setConfigurableMenu(navMenu));
			}
			if (!configs.error) {
				const hsMenu = await buildHSMenuConfigsForAllRoles(configs.settings);
				dispatch(configurationActionCreators.setAdminConfigurableMenu(hsMenu));
				history.push('/');
				const roundingConfigs = buildRoleSettings(configs.settings, RoundingSettings, getUserRoleId(getUserRole()));
				const patientsConfigs = buildRoleSettings(configs.settings, GeneralAndMenuSettings, getUserRoleId(getUserRole()));
				dispatch(configurationActionCreators.setRoleRoundingConfigurations(roundingConfigs));
				dispatch(configurationActionCreators.setRolePatientConfigurations(patientsConfigs));
				dispatch(healthSystemsActionCreators.setIsHealthSystemFetched(true));
			}
			dispatch(healthSystemsActionCreators.setAllHealthSystems(healthSystemsRes));
			await fetchHealthSystemConfigurations(selectedHs.id);
			setUserSession({
				...user.userSession,
				healthSystem: selectedHs,
				regionId,
			});

			let roomsToSubcribe = [EventSubscriberRoomType.DEVICE_STATUS];
			if (getUserRole() === UserRoles.NURSE) {
				roomsToSubcribe = [...roomsToSubcribe, EventSubscriberRoomType.AI_EVENTS, EventSubscriberRoomType.PATIENT_EVENTS];
			}

			socketFunctions.subscribeToHSEvents({ healthSystemId: selectedHs.id, rooms: roomsToSubcribe });

			props.setIsLoading(false);
		}
	};

	const fetchHealthSystemConfigurations = async healthSystemId => {
		const fetchUnboundHealthSystemConfigs = async () => {
			const response = await getTeamSettings({
				teamId: healthSystemId,
				levelId: DeviceListLevel.HEALTH_SYSTEM,
				settingsCategory: null,
			});
			if (!response.error) {
				const isNewExperience = !getIsOldUserExperience(companyConfigurations, response.settings);
				const healthSystemSettings = {
					callSettings: buildCallSettings(response.settings),
					visualsSettings: buildVisualsSettings(response.settings),
				};
				dispatch(configurationActionCreators.setIsNewExperience(isNewExperience));
				dispatch(configurationActionCreators.setHealthSystemUnboundSettings(healthSystemSettings));
			}
		};

		const fetchBoundedHealthSystemConfigs = async () => {
			const response = await getTeamSettings({
				teamId: healthSystemId,
				levelId: DeviceListLevel.HEALTH_SYSTEM,
				settingsCategory: [SettingsCategory.MONITORING],
			});
			if (!response.error) {
				const hsConfigs = buildHealthSystemConfigurations(response.settings);
				dispatch(configurationActionCreators.setHealthSystemConfigs(hsConfigs));
			}
		};

		await fetchUnboundHealthSystemConfigs();
		await fetchBoundedHealthSystemConfigs();
	};

	return (
		<li>
			<Dropdown
				className='user header-user-dropdown'
				position='right'
				stayOpenOnClick={isMobileOrTabletDevice()}
				hasImageWrapper={true}
				isPortal={true}
				setIsPortalOpen={() => {}}
				portalClassName={classNames('portal-user-dropdown__items', { 'dark-mode': user.darkMode })}
				title={
					<div>
						<p>{decodeHtml(`${userInfo.firstName} ${userInfo.lastName}`)}</p>
						{healthSystems && (
							<p>
								<span>
									<i
										className={`material-icons ${
											user.presenceStatusTypeId === PresenceStatusType.AVAILABLE ? '--green-color' : '--red-color'
										}
                    `}>
										fiber_manual_record
									</i>
								</span>
							</p>
						)}
					</div>
				}
				imageUrl={buildProfilePicUrl(userInfo.profilePicture || `${healthCareCdnUrl}header/no-pic.svg`, 50)}>
				<div className='dropdown__items portal-header-user-dropdown'>
					<ul className='list-group' data-cy='listOfItems'>
						<UserRolesList
							ref={wrapperRef}
							switchRole={switchRole}
							showDropdownOnTouch={showDropdownOnTouch}
							openDropdownModal={openDropdownModal}
							isLeftText={true}
						/>
						<li ref={wrapperRef} className={showDropdownOnTouch === 'presence' ? showDropdownOnTouch : ''}>
							<span onClick={() => openDropdownModal('presence')}>
								<img
									src={`${healthCareCdnUrl}header/${
										user.presenceStatusTypeId === PresenceStatusType.AVAILABLE ? 'online' : 'offline'
									}-presence.svg`}
									alt='icon'
								/>
								<div className='flex text-align-left'>
									{translate('changePresence')}
									<span>
										{translate(
											user.presenceStatusTypeId === PresenceStatusType.AVAILABLE
												? 'statusCurrentlyOnline'
												: 'statusCurrentlyOffline'
										)}
									</span>
								</div>
								<div className='list-group-wrapper'>
									<ul className='list-group' data-cy='userPresenceOptions'>
										<li className='switch-presence-li'>
											<span onClick={() => onUpdateUserPresence(PresenceStatusType.AVAILABLE)}>
												<i className='material-icons --green-color'>fiber_manual_record</i>
												{translate('available')}
											</span>
										</li>
										<li className='switch-presence-li'>
											<span onClick={() => onUpdateUserPresence(PresenceStatusType.UNAVAILABLE)}>
												<i className='material-icons --red-color'>fiber_manual_record</i>
												{translate('unavailable')}
											</span>
										</li>
									</ul>
								</div>
							</span>
						</li>
						{showMenuSettings() && (
							<li>
								<span onClick={() => setIsMenuSettingsOpen(true)}>
									<img src={`${healthCareCdnUrl}header/account-settings-new.svg`} alt='icon' />
									<div className='flex text-align-left'>
										{translate('generalAndMenuSettings')}
										<span> {translate('editMenuSettings')}</span>
									</div>
								</span>
							</li>
						)}
						<li>
							<span onClick={() => props.openCheckInNewPatient('/account-settings')}>
								<img src={`${healthCareCdnUrl}header/account-settings-new.svg`} alt='icon' />
								<div className='flex text-align-left'>
									{translate('accountSettings')}
									<span> {translate('editChangeAccountInfo')}</span>
								</div>
							</span>
						</li>
						<li>
							<span onClick={toggleFullScreen}>
								{user.darkMode && (
									<img
										src={
											healthSystems.isFullscreen
												? `${healthCareCdnUrl}dark-mode/ExitFullScreen.svg?v2`
												: `${healthCareCdnUrl}dark-mode/FullScreen.svg?v2`
										}
										alt='icon'
									/>
								)}
								{!user.darkMode && (
									<img
										src={
											healthSystems.isFullscreen
												? `${healthCareCdnUrl}footer-icons/ExitFullScreen.svg?v2`
												: `${healthCareCdnUrl}footer-icons/FullScreen.svg?v2`
										}
										alt='icon'
									/>
								)}
								<div>{translate(healthSystems.isFullscreen ? 'exitFullScreen' : 'fullScreen')}</div>
							</span>
						</li>
						<li>
							<span onClick={signOut}>
								<img src={`${healthCareCdnUrl}header/sign-out-new.svg`} alt='icon' />
								{translate('logOut')}
							</span>
						</li>
					</ul>
				</div>
			</Dropdown>
			{isMenuSettingsOpen && <ConfigurableMenu setIsMenuSettingsOpen={() => setIsMenuSettingsOpen(prevState => !prevState)} />}
			<Alert display={updateTreeError} fixed={true} hideCloseButton={true} message={updateTreeError} variant='dark' />
			<PopUpAlert
				alertType={AlertTypes.WARNING}
				display={error}
				onAlertClose={() => setError('')}
				contentText={error}
				isSilent={true}
				center={true}
			/>
		</li>
	);
};

export default UserAccount;
