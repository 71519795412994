import React, { useEffect, useState, useContext } from 'react';
import { useIntl } from 'react-intl';
import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import ReactSelect from 'react-select';
import { getPatientsAiSettings, updatePatientAiSetting, updatePatientAiSettings } from 'api/patients.js';
import { Alert, ToastMessage } from 'components/index.js';
import {
	PatientAiSetting,
	SensitivityType,
	RailType,
	ExcludedAiSettings,
	ExcludedAiSettingsHello3,
	DeviceFamilyTypes,
	AlertCategoryMapping,
	KeyCodes,
	IncludedAiSettings,
	AiAlertId,
} from 'constants/enums.js';
import translate from 'i18n-translations/translate.jsx';
import {
	AiSetting,
	CallWorkflowType,
	SettingsCategory,
	UserSettingTypes,
	configurableAISettings,
} from 'constants/configurationEnums.js';
import { actionCreators as aiSettingsActionCreators } from 'state/aiSettings/actions.js';
import { getAiSettingsConfigurations, getConfigurationValue, validateMaxLength } from 'infrastructure/helpers/commonHelpers.js';
import DarkTheme from 'calls/styles/DarkTheme.js';
import LightTheme from 'calls/styles/LightTheme.js';
import { generateCustomStyles } from 'constants/react-select-style.js';
import { getRoomSettings } from 'api/adminConfigurations.js';
import { getUserPreferences } from 'api/users.js';
import { healthCareCdnUrl } from 'constants/global-variables.js';
import { SocketContext } from 'infrastructure/socket-client/SocketContext.js';
import SocketEvents from 'constants/socket-events.js';
import { Grid, Loader } from 'components/index.js';
import AiConfigurations from 'components/MonitoringSettings/AiConfigurations.jsx';
import {
	RailOptions,
	SensitivityTypes,
	TimerValues,
	aiFormatOptionLabel,
	aiTypesToShow,
	getAdminAiConfigurationsById,
	getCommonRoomAiConfigs,
	isAnySettingActive,
	getConfiguredAiSettings,
	BodyPosition,
	isSettingEnabled,
	areSettingValuesEqual,
	AiVoiceAnalysisOptions,
} from 'infrastructure/helpers/aiHelper.js';
import GoBack from 'icons/Monitoring/GoBack.jsx';
import { AiFieldToUpdate, FallPreventionSettingTypes, FallPreventionTypes } from 'constants/ai.js';
import AiMultipleSelect from 'components/MonitoringSettings/AiMultipleSelect.jsx';
import Button from 'components/Button.jsx';
import PatientMobilityHistory from 'containers/Monitoring/PatientMobilityHistory.jsx';
import AiVoiceAnalysisEvents from 'components/Monitoring/AI/AiVoiceAnalysisEvents.jsx';

const PatientAiSettings = ({
	deviceId,
	patientId,
	onAiSettingClick,
	areEmergencyButtonsHiden,
	isNoteShowing,
	isDarkMode,
	toggleFallPrevention,
	isFallPrevention,
	roomId,
	feedWarning,
	aiToastDetails,
	stopVoiceOver,
	onAlertInfoClose,
	deviceFamily,
	setIvBagPercentage,
	isRounding = false,
}) => {
	const intl = useIntl();
	const socket = useContext(SocketContext);
	const [adminAiSettingsConfigurations, setAdminAiConfigurations] = useState(configurableAISettings());
	const [isSettingsClicked, setIsSettingsClicked] = useState(false);
	const [selectedItem, setSelectedItem] = useState(null);
	const [isLoading, setIsLoading] = useState(true);
	const [adminAiConfigs, setAdminAiConfigs] = useState([]);
	const [sensitivityTypes, setSensitivityTypes] = useState(SensitivityTypes(intl));
	const [railsOptions, setRailOptions] = useState(RailOptions(intl));
	const [bodyPositionOptions, setBodyPositionOptions] = useState(BodyPosition(intl));
	const [selectedInactiveTime, setSelectedInactiveTime] = useState(null);
	const [selectedSensitivity, setSelectedSensitivity] = useState(null);
	const [selectedRails, setSelectedRails] = useState([]);
	const [selectedBodyPositions, setSelectedBodyPositions] = useState([]);
	const [inactiveHours, setInactiveHours] = useState(TimerValues(intl));
	const [error, setError] = useState(null);
	const [isMoreThanOneAi, setIsMoreThanOneAi] = useState(false);
	const [ivBagWarningValue, setIvBagWarningValue] = useState(null);
	const [isMenuOpen, setIsMenuOpen] = useState(false);
	const [isInit, setIsInit] = useState(true);
	const [isBodyPositionUpdated, setIsBodyPositionUpdated] = useState(false);
	const [aiVoiceEventOptions, setAiVoiceEventOptions] = useState(AiVoiceAnalysisOptions(intl));
	const aiSettingList = useSelector(state => state.aiSettingsList.aiSettings);
	const dispatch = useDispatch();
	const setAiSettingsAction = ai => dispatch(aiSettingsActionCreators.setPatientAiSettings(ai));
	const [disabledSettings, setDisabledSettings] = useState({});
	const user = useSelector(state => state.user);
	const workflowType = isRounding ? CallWorkflowType.ROUNDING : CallWorkflowType.MONITORING;
	const initialSettings = aiSettingList.find(item => item.deviceId === deviceId)?.settings;

	useEffect(() => {
		const fetchRoomSettings = async () => {
			const [adminAiSettings, aiRoomSettings] = await Promise.all([
				getRoomSettings(roomId, SettingsCategory.AI_SETTINGS),
				getUserPreferences(UserSettingTypes.AiSettings, roomId),
			]);
			const responseError = adminAiSettings.error || aiRoomSettings.error;
			if (responseError) {
				setError(responseError.message);
				return;
			}
			setAdminAiConfigs(adminAiSettings.settings);
			const aiResponse = getAiSettingsConfigurations(adminAiSettings, aiRoomSettings, workflowType);
			setAdminAiConfigurations(aiResponse.configs);
		};
		fetchRoomSettings();
	}, []);

	useEffect(() => {
		if (adminAiConfigs.length === 0 || !selectedItem) {
			return;
		}
		const aiConfigs = getAdminAiConfigurationsById(adminAiConfigs, selectedItem.aiTypeId, workflowType);
		if (!aiConfigs) {
			return;
		}

		handleSetSelectedOptions(selectedItem.aiTypeId, aiConfigs);
	}, [adminAiConfigs, intl, selectedItem]);

	useEffect(() => {
		const getPatientAiSettings = async () => {
			const response = await getPatientsAiSettings({
				patientId,
				deviceId,
				roomId,
				workflowType,
			});
			if (response.error) {
				setError(response.error.message);
				setIsLoading(false);
				return;
			}
			const pressureInjurySetting = response.patientAiSettings.find(
				item => item.settingTypeId === PatientAiSetting.PRESSURE_INJURY
			);
			if (pressureInjurySetting?.value) {
				setSelectedInactiveTime(inactiveHours.find(item => item.value === pressureInjurySetting.value));
			}

			const isAiDrawingsEnabled = isSettingEnabled(response.patientAiSettings, PatientAiSetting.AI_DRAWING);

			const fallPreventionStatus = response.patientAiSettings
				.filter(item => FallPreventionSettingTypes.includes(item.settingTypeId))
				.some(item => item.isEnabled);

			toggleFallPrevention(fallPreventionStatus, deviceId);

			onAiSettingClick(isAiDrawingsEnabled);

			setAiSettingsAction({ deviceId, settings: response.patientAiSettings });
			setIsInit(false);
			setIsLoading(false);
		};
		getPatientAiSettings();
	}, [patientId, deviceId, isSettingsClicked]);

	useEffect(() => {
		if (adminAiConfigs.length > 0) {
			const aiConfigs = getAdminAiConfigurationsById(adminAiConfigs, PatientAiSetting.PRESSURE_INJURY, workflowType);
			if (!aiConfigs) {
				return;
			}
			setInactiveHours(getCommonRoomAiConfigs(TimerValues(intl), aiConfigs?.values));
		}
	}, [adminAiConfigs, intl]);

	useEffect(() => {
		const found = aiSettingList.find(item => item.deviceId === deviceId);
		if (!found) {
			return;
		}
		const fallPreventionStatus = found.settings
			.filter(item => FallPreventionSettingTypes.includes(item.settingTypeId))
			.some(item => item.isEnabled);
		toggleFallPrevention(fallPreventionStatus, deviceId);
	}, [isFallPrevention]);

	useEffect(() => {
		const onPatientAiSettingsUpdated = data => {
			if (deviceId !== data.deviceId || data.workflowType !== workflowType) {
				return;
			}
			const pressureInjurySetting = data.patientAiSettings.find(item => item.settingTypeId === PatientAiSetting.PRESSURE_INJURY);
			if (pressureInjurySetting?.value) {
				setSelectedInactiveTime(inactiveHours.find(item => item.value === pressureInjurySetting.value));
			}

			const isAiDrawingsEnabled = isSettingEnabled(data.patientAiSettings, PatientAiSetting.AI_DRAWING);

			const fallPreventionStatus = data.patientAiSettings
				.filter(item => FallPreventionSettingTypes.includes(item.settingTypeId))
				.some(item => item.isEnabled);

			toggleFallPrevention(fallPreventionStatus, deviceId);
			onAiSettingClick(isAiDrawingsEnabled);
			setAiSettingsAction({ deviceId, settings: data.patientAiSettings });
			setIsBodyPositionUpdated(true);
		};
		socket.on(SocketEvents.Alerts.PATIENT_AI_SETTINGS_UPDATED, onPatientAiSettingsUpdated);
		return () => {
			socket.off(SocketEvents.Alerts.PATIENT_AI_SETTINGS_UPDATED, onPatientAiSettingsUpdated);
		};
	}, [socket, deviceId]);

	const selectedAiSetting = (foundSettings, settingTypeId) =>
		foundSettings.find(item => item.settingTypeId === settingTypeId)?.value;

	useEffect(() => {
		const foundDevice = aiSettingList.find(item => item.deviceId === deviceId);
		if (!foundDevice) {
			return;
		}
		handleSetSelectedSensitivity(foundDevice.settings);
		handleSetSelectedRails(foundDevice.settings);
		handleSetSelectedIVBag(foundDevice.settings);
		handleSetSelectedBodyPositions(foundDevice.settings);
	}, [aiSettingList, deviceId]);

	const handleSetSelectedOptions = (settingTypeId, aiConfigs) => {
		switch (settingTypeId) {
			case PatientAiSetting.PATIENT_GETTING_OUT_OF_BED:
				setSensitivityTypes(getCommonRoomAiConfigs(SensitivityTypes(intl), aiConfigs.values));
				break;
			case PatientAiSetting.RAILS:
				setRailOptions(getCommonRoomAiConfigs(RailOptions(intl), aiConfigs.values));
				break;
			case PatientAiSetting.PRESSURE_INJURY:
				setBodyPositionOptions(getCommonRoomAiConfigs(BodyPosition(intl), aiConfigs.bodyPositionValues));
				break;
			case PatientAiSetting.AI_VOICE_ANALYSIS:
				setAiVoiceEventOptions(getCommonRoomAiConfigs(AiVoiceAnalysisOptions(intl), aiConfigs.values));
				break;
			default:
				break;
		}
	};

	const handleSetSelectedSensitivity = foundSettings => {
		const selectedSensitivityValue = selectedAiSetting(foundSettings, PatientAiSetting.PATIENT_GETTING_OUT_OF_BED);
		if (selectedSensitivityValue) {
			setSelectedSensitivity(sensitivityTypes.find(item => item.value === selectedSensitivityValue));
		}
		if (!selectedSensitivityValue && isInit) {
			setSelectedSensitivity(null);
		}
	};

	const handleSetSelectedRails = foundSettings => {
		const foundRailDown = selectedAiSetting(foundSettings, PatientAiSetting.RAILS);
		if (foundRailDown) {
			const onMountRails = railsOptions.filter(rail => foundRailDown.includes(rail.value));
			setSelectedRails(onMountRails);
		}
		if (!foundRailDown && isInit) {
			setSelectedRails([]);
		}
	};

	const handleSetSelectedIVBag = foundSettings => {
		const ivBagFluidLevel = selectedAiSetting(foundSettings, PatientAiSetting.IV_BAG);
		if (ivBagFluidLevel) {
			setIvBagWarningValue(parseInt(ivBagFluidLevel, 10));
		} else {
			setIvBagWarningValue(null);
		}
	};

	const handleSetSelectedBodyPositions = foundSettings => {
		const foundBodyPosition = foundSettings.find(
			item => item.settingTypeId === PatientAiSetting.PRESSURE_INJURY
		)?.pressureInjuryBodyPositions;

		const shouldUpdatePositions = foundBodyPosition && (isInit || isBodyPositionUpdated);
		if (shouldUpdatePositions) {
			const onMountBodyPosition = bodyPositionOptions.filter(position => foundBodyPosition.includes(position.value));
			setSelectedBodyPositions(onMountBodyPosition);
			setIsBodyPositionUpdated(false);
		}
		if (!foundBodyPosition && isInit) {
			setSelectedBodyPositions([]);
		}
	};

	useEffect(() => {
		const toastTimeOut = setTimeout(() => {
			setIsMoreThanOneAi(false);
		}, 3000);

		return () => {
			clearTimeout(toastTimeOut);
		};
	}, [isMoreThanOneAi]);

	const updateAiSettings = async (params, isToggleBulk = false) => {
		const response = isToggleBulk ? await updatePatientAiSettings(params) : await updatePatientAiSetting(params);

		if (response.error) {
			setError(response.error.message);
			setAiSettingsAction({
				settings: initialSettings,
				deviceId,
			});
		}
	};

	const toggleBulkSettings = async isEnabled => {
		const dataToSend = [
			{
				settingTypeId: PatientAiSetting.PATIENT_GETTING_OUT_OF_BED,
				value: getConfigurationValue(adminAiSettingsConfigurations[AiSetting.GET_OUT_OF_BED])
					? selectedSensitivity?.value || SensitivityType.MEDIUM
					: null,
				isEnabled: getConfigurationValue(adminAiSettingsConfigurations[AiSetting.GET_OUT_OF_BED]) && isEnabled,
			},
			{
				settingTypeId: PatientAiSetting.RAILS,
				value: getConfigurationValue(adminAiSettingsConfigurations[AiSetting.RAILS])
					? `${RailType.TOP_RIGHT}-${RailType.TOP_LEFT}`
					: null,
				isEnabled: getConfigurationValue(adminAiSettingsConfigurations[AiSetting.RAILS]) && isEnabled,
			},
			{
				settingTypeId: PatientAiSetting.FALL_DETECTED,
				value: getConfigurationValue(adminAiSettingsConfigurations[AiSetting.FALL_DETECTION]) ? 'true' : 'false',
				isEnabled: getConfigurationValue(adminAiSettingsConfigurations[AiSetting.FALL_DETECTION]) && isEnabled,
			},
		];

		if (shouldDisableClick(PatientAiSetting.FALL_PREVENTION, isEnabled)) {
			setIsMoreThanOneAi(true);
			return;
		}
		if (isFallPrevention) {
			setSelectedSensitivity(null);
			setSelectedRails([]);
		}
		const found = aiSettingList.find(item => item.deviceId === deviceId);
		if (!found) {
			return;
		}

		setDisabledSettings(prev => ({ ...prev, [PatientAiSetting.FALL_PREVENTION]: true }));

		const sendData = dataToSend.map(item => ({
			isEnabled: item.isEnabled,
			settingTypeId: item.settingTypeId,
			workflowType,
		}));

		const params = {
			patientId,
			deviceId,
			roomId,
			sendData,
		};
		await updateAiSettings(params, true);
		setDisabledSettings(prev => ({ ...prev, [PatientAiSetting.FALL_PREVENTION]: false }));
	};

	const shouldDisableClick = (settingTypeId, value) => {
		const settings = _.cloneDeep(aiSettingList);
		const found = settings.find(item => item.deviceId === deviceId);

		const foundInFallPrevention = FallPreventionTypes.find(item => item === settingTypeId);
		if (!found) {
			return true;
		}

		const excludedSettings = deviceFamily === DeviceFamilyTypes.HELLO_3 ? ExcludedAiSettingsHello3 : ExcludedAiSettings;

		let isAiSettingEnabled = isAnySettingActive(found.settings, ExcludedAiSettings, settingTypeId);

		const isIndependentAiActive = isAnySettingActive(found.settings, IncludedAiSettings, settingTypeId, true);

		if (foundInFallPrevention) {
			isAiSettingEnabled = found.settings
				.filter(el => !ExcludedAiSettings.includes(el.settingTypeId))
				.reduce((acc, item) => {
					return (
						acc || (!FallPreventionTypes.includes(item.settingTypeId) && item.isEnabled && item.settingTypeId !== settingTypeId)
					);
				}, false);
		}

		return (
			isAiSettingEnabled &&
			value &&
			settingTypeId !== PatientAiSetting.HAND_WASHING &&
			(!excludedSettings.includes(settingTypeId) || isIndependentAiActive)
		);
	};

	const closeFeedAlerts = settingTypeId => {
		const found = aiSettingList.find(item => item.deviceId === deviceId);
		if (!found) {
			return;
		}
		const selectedSetting = found.settings.find(item => item.settingTypeId === settingTypeId);
		const isAiSettingOn = selectedSetting?.isEnabled || isOptionChecked(settingTypeId);
		const isAiToastDetails = aiToastDetails && Object.keys(aiToastDetails)?.length > 0;
		const { alertTypeId, value, alertCategory } = feedWarning || {};

		if (!isAiSettingOn || (!value && !isAiToastDetails)) {
			return;
		}
		const isFallPreventionAlert = [AiAlertId.PATIENT_GETTING_OUT_OF_BED, AiAlertId.RAILS, AiAlertId.FALL_DETECTION].includes(
			alertTypeId
		);

		const shouldStopVoiceOver =
			AlertCategoryMapping[settingTypeId] === alertCategory ||
			(isFallPreventionAlert && settingTypeId === PatientAiSetting.FALL_PREVENTION);

		if (shouldStopVoiceOver) {
			stopVoiceOver({ alertTypeId, deviceId, dismissActiveAlert: false });
		} else if (isAiToastDetails) {
			onAlertInfoClose(deviceId, settingTypeId === PatientAiSetting.PRESSURE_INJURY);
		}
	};

	const getUpdatedStatus = (setting, fieldToUpdate) =>
		fieldToUpdate === AiFieldToUpdate.IS_ENABLED ? !setting.isEnabled : setting.isEnabled;

	const toggleAiSettings = async ({ settingTypeId, customValue = null, fieldToUpdate = AiFieldToUpdate.VALUE }) => {
		const found = aiSettingList.find(item => item.deviceId === deviceId);
		if (!found) {
			return;
		}
		const selectedSetting = found.settings.find(item => item.settingTypeId === settingTypeId);

		if (!selectedSetting) {
			return;
		}

		const updatedStatus = getUpdatedStatus(selectedSetting, fieldToUpdate);

		if (shouldDisableClick(settingTypeId, updatedStatus)) {
			setIsMoreThanOneAi(true);
			return;
		}
		setDisabledSettings(prev => ({ ...prev, [settingTypeId]: true }));

		const params = {
			patientId,
			deviceId,
			roomId,
			fieldToUpdate,
			value: fieldToUpdate === AiFieldToUpdate.IS_ENABLED ? !selectedSetting.isEnabled : customValue,
			id: selectedSetting.id,
		};

		await updateAiSettings(params);
		setDisabledSettings(prev => ({ ...prev, [settingTypeId]: false }));
	};

	const getAiTypes = () => {
		const settings = _.cloneDeep(aiSettingList);
		const found = settings.find(item => item.deviceId === deviceId);
		if (!found) {
			return [];
		}
		return found.settings;
	};

	const getAiTypeValue = aiType => getAiTypes().find(item => item.settingTypeId === aiType)?.isEnabled;

	const isOptionChecked = aiTypeId => {
		if (aiTypeId === PatientAiSetting.FALL_PREVENTION && isFallPrevention) {
			return isFallPrevention;
		}
		return getAiTypeValue(aiTypeId);
	};

	const handleRailsOnSelect = (item, { action }) => {
		setSelectedRails(item);
		const stringifiedValues = item.map(rail => rail.value).join('-');
		toggleAiSettings({ settingTypeId: PatientAiSetting.RAILS, customValue: stringifiedValues });

		if (action === 'deselect-option' && selectedRails.length === 1) {
			setIsMenuOpen(false);
		}
	};

	const handleBodyPositionOnSelect = (item, { action }) => {
		setSelectedBodyPositions(item);
		const mappedValues = item.map(position => position.value);
		toggleAiSettings({
			settingTypeId: PatientAiSetting.PRESSURE_INJURY,
			customValue: mappedValues,
			fieldToUpdate: AiFieldToUpdate.PRESSURE_INJURY_BODY_POSITIONS,
		});
		if (action === 'deselect-option' && selectedBodyPositions.length === 1) {
			setIsMenuOpen(false);
		}
	};

	const handleOnEnter = event => {
		if (event.which === KeyCodes.ENTER) {
			toggleAiSettings({ settingTypeId: PatientAiSetting.IV_BAG, customValue: event.target.value });
			return;
		}
		event.preventDefault();
	};

	const handlePersonInactive = item => {
		if (areSettingValuesEqual(selectedInactiveTime, item)) {
			return;
		}
		setSelectedInactiveTime(item);
		toggleAiSettings({ settingTypeId: PatientAiSetting.PRESSURE_INJURY, customValue: item.value });
	};

	const handleSettingToggle = settingTypeId => {
		closeFeedAlerts(settingTypeId);
		if (settingTypeId === PatientAiSetting.FALL_PREVENTION) {
			toggleBulkSettings(!isFallPrevention);
		} else {
			toggleAiSettings({ settingTypeId, fieldToUpdate: AiFieldToUpdate.IS_ENABLED });
			if (settingTypeId === PatientAiSetting.IV_BAG) {
				setIvBagPercentage(deviceId, 0);
				setIvBagWarningValue('');
			}
		}
	};

	return (
		<div className='ai-alerts-wrapper'>
			{isLoading && (
				<Grid columns='1fr' rows='1fr' stretch='250px' horizAlign='center' vertAlign='center'>
					<div style={{ textAlign: 'center' }}>
						<Loader />
					</div>
				</Grid>
			)}
			{!isLoading &&
				!isSettingsClicked &&
				aiTypesToShow({ adminAiSettingsConfigurations, isDarkMode, intl }).map(
					aiType =>
						aiType.show && (
							<div
								className={classNames(
									'flex flex-space-between alert-settings',
									aiType.subTitle || getConfiguredAiSettings(aiType.aiTypeId) ? 'flex-wrap' : '',
									aiType.noBorder ? 'no-border-bottom' : '',
									aiType.isTitle ? 'title-alert-setting' : ''
								)}
								key={aiType.aiTypeId}>
								{aiType.subTitle && <span>{aiType.subTitle}</span>}
								<div className='flex flex-justify-center flex-align-center'>
									{aiType.img}
									<label>{aiType.label}</label>
								</div>
								<div className='flex flex-align-center'>
									<label
										className={classNames(
											'switch-label',
											aiType.aiTypeId === PatientAiSetting.IV_BAG && !isOptionChecked(aiType.aiTypeId) && !ivBagWarningValue
												? 'disabled'
												: '',
											FallPreventionSettingTypes.includes(aiType.aiTypeId) && !isFallPrevention ? 'disabled' : ''
										)}>
										<div className={classNames('toggle-switch toggle-blue', { disabled: disabledSettings[aiType.aiTypeId] })}>
											<input
												type={isOptionChecked(aiType.aiTypeId) ? 'checkbox' : ''}
												checked={isOptionChecked(aiType.aiTypeId)}
												disabled={disabledSettings[aiType.aiTypeId]}
												onClick={() => handleSettingToggle(aiType.aiTypeId)}
											/>
											<span className='toggle-body'>
												<span className='on-text'>{translate('on')}</span>
												<span className='off-text'>{translate('off')}</span>
											</span>
										</div>
									</label>
									{getConfiguredAiSettings(aiType.aiTypeId) && (
										<Button
											className='no-background padding-right-0'
											onClick={() => {
												setIsSettingsClicked(true);
												setSelectedItem(aiType);
											}}
											svgIcon={
												<img
													className='dropdown-icon-img'
													src={`${healthCareCdnUrl}${!isDarkMode ? 'header' : 'dark-mode'}/account-settings.svg`}
													alt={intl.formatMessage({
														id: 'monitoringSettings',
													})}
												/>
											}
										/>
									)}
								</div>
								{aiType.aiTypeId === PatientAiSetting.PRESSURE_INJURY && (
									<div className='flex full-width top-15'>
										<ReactSelect
											options={inactiveHours}
											value={selectedInactiveTime}
											id='inactiveElement'
											onChange={handlePersonInactive}
											classNamePrefix='react-select'
											styles={{
												...generateCustomStyles({ darkMode: user.darkMode }),
												menu: base => ({ ...base, width: '100%' }),
												menuList: base => ({
													...base,
													height: 'fit-content',
													width: '100%',
													backgroundColor: isDarkMode ? DarkTheme.colors.grayThree : LightTheme.colors.grayZero,
												}),
											}}
										/>
									</div>
								)}
								{aiType.aiTypeId === PatientAiSetting.IV_BAG && (
									<>
										<div className='flex flex-basis-100 top-15 flex-align-center'>
											<label className='right-s'>{translate('warningAlertAt')}</label>
											<div className='position-relative'>
												<input
													type='number'
													name='ivBagFluidLevel'
													className='full-width'
													value={ivBagWarningValue}
													onChange={e => setIvBagWarningValue(e.target.value)}
													onKeyDown={event => validateMaxLength(event, 2)}
													onKeyUp={handleOnEnter}
													placeholder={intl.formatMessage({ id: 'writeAndClickEnter' })}
													max={100}
												/>
												<span>%</span>
											</div>
										</div>
										<div className='flex-basis-100 top-15'>
											<small>{translate('enableIvBagWarning')}</small>
										</div>
									</>
								)}
							</div>
						)
				)}
			<Alert display={error} fixed={true} hideCloseButton={true} message={error} variant='dark' />
			<ToastMessage
				showToast={isMoreThanOneAi}
				onClose={() => setIsMoreThanOneAi(false)}
				className={classNames(
					'video-feed-toast-message',
					isNoteShowing || !areEmergencyButtonsHiden ? 'emergency-toast-message' : '',
					isNoteShowing && !areEmergencyButtonsHiden ? 'notes-toast-message' : ''
				)}>
				<span>{translate('noMoreThanOneAiType')}</span>
			</ToastMessage>
			{isSettingsClicked && (
				<div className='monitoring-timeline-wrapper no-horizontal-scroll'>
					<div className='flex flex-align-center margin-bottom-m'>
						<div className='cursor-pointer --blue-color go-back' onClick={() => setIsSettingsClicked(false)}>
							<GoBack color={isDarkMode ? DarkTheme.colors.graySix : LightTheme.colors.grayFive} />
						</div>
						<p className='semi-bold left-10 font-12'>{selectedItem?.label}</p>
					</div>
					{selectedItem.aiTypeId === PatientAiSetting.PATIENT_GETTING_OUT_OF_BED && (
						<>
							<p className='semi-bold font-14'>{intl.formatMessage({ id: 'sensitivity' })}</p>
							<div className='flex full-width top-15 ai-configurations-select no-border-bottom margin-bottom-0'>
								<ReactSelect
									options={sensitivityTypes}
									value={selectedSensitivity}
									onChange={item => {
										if (areSettingValuesEqual(selectedSensitivity, item)) {
											return;
										}
										setSelectedSensitivity(item);
										toggleAiSettings({ settingTypeId: PatientAiSetting.PATIENT_GETTING_OUT_OF_BED, customValue: item.value });
									}}
									classNamePrefix='react-select'
									placeholder={intl.formatMessage({ id: 'sensitivity' })}
									formatOptionLabel={data => aiFormatOptionLabel(data, intl)}
									styles={generateCustomStyles({ darkMode: user.darkMode })}
								/>
							</div>
						</>
					)}
					{selectedItem.aiTypeId === PatientAiSetting.RAILS && (
						<AiMultipleSelect
							isMenuOpen={isMenuOpen}
							options={railsOptions}
							selectedOptions={selectedRails}
							handleOnSelect={handleRailsOnSelect}
							setIsMenuOpen={setIsMenuOpen}
							customContent='rails'
							menulistTitle='sendRailsAlert'
							placeholder='selectRails'
						/>
					)}
					{selectedItem.aiTypeId === PatientAiSetting.PRESSURE_INJURY && (
						<AiMultipleSelect
							isMenuOpen={isMenuOpen}
							options={bodyPositionOptions}
							selectedOptions={selectedBodyPositions}
							handleOnSelect={handleBodyPositionOnSelect}
							setIsMenuOpen={setIsMenuOpen}
							customContent='patientPositions'
							menulistTitle='sendPatientPositionAlert'
							placeholder='selectPatientPosition'
						/>
					)}
					{selectedItem.aiTypeId === PatientAiSetting.AI_VOICE_ANALYSIS && (
						<AiVoiceAnalysisEvents
							toggleAiSettings={toggleAiSettings}
							aiSettingList={aiSettingList}
							deviceId={deviceId}
							aiVoiceEventOptions={aiVoiceEventOptions}
						/>
					)}
					{getConfiguredAiSettings(selectedItem.aiTypeId) && (
						<AiConfigurations
							deviceId={deviceId}
							patientId={patientId}
							selectedItem={selectedItem}
							roomId={roomId}
							adminAiConfigs={adminAiConfigs}
							isRounding={isRounding}
						/>
					)}
					{selectedItem.aiTypeId === PatientAiSetting.PATIENT_MOBILITY && (
						<PatientMobilityHistory patientId={patientId} deviceId={deviceId} isDarkMode={isDarkMode} />
					)}
				</div>
			)}
		</div>
	);
};

export default PatientAiSettings;
