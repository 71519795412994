import { enums } from '@solaborate/calls';
import { getMemberRooms } from 'api/organization.js';
import { getVisitingHours } from 'api/visitingHours.js';
import { StartQueryStringKeys } from 'calls/enums/index.js';
import { HelloIcon } from 'calls/icons/index.js';
import Alert from 'components/Alert.jsx';
import CustomTable from 'components/CustomTable.jsx';
import DisabledCountDown from 'components/DisabledCountDown.jsx';
import EmptyState from 'components/EmptyState.jsx';
import Grid from 'components/Grid.jsx';
import HelloFeatureBlock from 'components/HelloFeatureBlock.jsx';
import Modal from 'components/Modal.jsx';
import {
	DeviceAvailability,
	DeviceListLevel,
	ErrorComponentTypes,
	StreamError,
	UserPermissionDeniedErrors,
	VisitorType,
} from 'constants/enums.js';
import { healthCareCdnUrl } from 'constants/global-variables.js';
import SocketEvents from 'constants/socket-events.js';
import { RoomTypes } from 'constants/visitEnums.js';
import translate from 'i18n-translations/translate.jsx';
import { checkIfMediaDevicesPlugged } from 'infrastructure/helpers/commonHelpers.js';
import { convertUTCDateToLocalDate, timeDifference } from 'infrastructure/helpers/dateHelper.js';
import {
	getInitialVisitingHoursMaped,
	reArrangeVisitingHours,
	removeSeconds,
} from 'infrastructure/helpers/visitingHoursHelper.js';
import Button from 'components/Button.jsx';
import { SocketContext } from 'infrastructure/socket-client/SocketContext.js';
import { MediaPermissionsErrorType, requestMediaPermissions } from 'mic-check';
import { useContext, useEffect, useRef, useState } from 'react';
import { isChrome, isEdgeChromium, isMobileSafari } from 'react-device-detect';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { actionCreators as healthSystemsActionCreators } from 'state/healthSystems/actions.js';
import MainLayout from 'views/Layouts/MainLayout.jsx';
import useScreenType from 'hooks/useScreenType.js';

const Visitor = () => {
	const helloName = useSelector(state => state.company.companySettings.helloName);
	const [roomsAssigned, setRoomsAssigned] = useState([]);
	const [selectedRoom, setSelectedRoom] = useState(null);
	const [error, setError] = useState(null);
	const isAllowPermissionPrompt = useRef(false);
	const socket = useContext(SocketContext);
	const intl = useIntl();
	const [visitingHours, setVisitingHours] = useState([]);
	const [flattenedVisitingHours, setFlattenedVisitingHours] = useState([]);
	const [isDisabledModalShown, setIsDisabledModalShown] = useState(false);
	const [disabledVisitTime, setDisabledVisitTime] = useState({
		createdAt: new Date(),
		disableTime: 0,
		isDisabled: false,
	});
	const dispatch = useDispatch();
	const history = useHistory();
	const screenType = useScreenType();

	useEffect(() => {
		const fetchRooms = async () => {
			const response = await getMemberRooms();
			if (response.error) {
				setError(response.error.message);
				return;
			}
			if (response.rooms.length > 0) {
				setRoomsAssigned(response.rooms);
				setSelectedRoom(response.rooms[0]);
			}
		};
		fetchRooms();
	}, []);

	useEffect(() => {
		if (selectedRoom?.helloDeviceId) {
			const fetchVisitingHours = async () => {
				const response = await getVisitingHours(DeviceListLevel.ROOM, selectedRoom.id);
				if (response.error) {
					setError(response.error.message);
				} else {
					const createdAt = new Date(response.createdAt);
					const localTime = convertUTCDateToLocalDate(createdAt);
					setDisabledVisitTime({
						createdAt: localTime,
						disableTime: response.disableTime,
						isDisabled: response.isVisitDisabled,
					});
					if (selectedRoom?.visitorTypeId === VisitorType.FAMILY_MEMBER) {
						return;
					}
					const flattend = response?.visitingHours
						?.map(item =>
							item.hours.map((hour, index) => ({
								...hour,
								weekDay: item.weekDay,
								from: removeSeconds(hour.from),
								to: removeSeconds(hour.to),
								id: index,
							}))
						)
						.flat();
					setFlattenedVisitingHours(flattend || []);
					const result = getInitialVisitingHoursMaped(response?.visitingHours);
					const visitingHoursRearragned = reArrangeVisitingHours(result, intl);
					setVisitingHours(visitingHoursRearragned);
				}
			};
			fetchVisitingHours();
		}
		if (selectedRoom?.visitorTypeId === VisitorType.FAMILY_MEMBER) {
			setVisitingHours([]);
			setDisabledVisitTime({
				createdAt: new Date(),
				disableTime: 0,
				isDisabled: false,
			});
		}
	}, [intl, selectedRoom]);

	useEffect(() => {
		const handlePrivacyModeUpdate = data => {
			if (selectedRoom && selectedRoom.deviceId === data.deviceId) {
				const room = { ...selectedRoom, aiPrivacyStatus: data.privacyMode };
				setSelectedRoom(room);
				setRoomsAssigned([...roomsAssigned.filter(item => item.helloDeviceId !== room.helloDeviceId), room]);
			}
		};

		const onDeviceStateUpdate = data => {
			if (data.deviceId === Number(selectedRoom.helloDeviceId)) {
				const room = { ...selectedRoom, [data.name]: data.value };
				setSelectedRoom(room);
				setRoomsAssigned([...roomsAssigned.filter(item => item.helloDeviceId !== room.helloDeviceId), room]);
			}
		};

		const handleVisitingHoursUpdate = ({ isDisabled, createdAt, disableTime, deviceId }) => {
			if (selectedRoom && +selectedRoom?.helloDeviceId === deviceId) {
				setDisabledVisitTime({
					isDisabled,
					createdAt: isDisabled ? new Date(createdAt) : new Date(),
					disableTime: isDisabled ? disableTime : 0,
				});
			}
		};

		socket.on(SocketEvents.HelloDevice.ON_STATE_CHANGED, onDeviceStateUpdate);
		socket.on(SocketEvents.HelloDevice.PRIVACY_MODE_UPDATE, handlePrivacyModeUpdate);
		socket.on(SocketEvents.HealthCare.VISITS_TOGGLE_UPDATED, handleVisitingHoursUpdate);
		return () => {
			socket.off(SocketEvents.HelloDevice.ON_STATE_CHANGED, onDeviceStateUpdate);
			socket.off(SocketEvents.HelloDevice.PRIVACY_MODE_UPDATE, handlePrivacyModeUpdate);
			socket.off(SocketEvents.HealthCare.VISITS_TOGGLE_UPDATED, handleVisitingHoursUpdate);
		};
	}, [socket, selectedRoom, roomsAssigned]);

	useEffect(() => {
		const timeout = setTimeout(() => {
			setError(null);
		}, 5000);

		return () => clearTimeout(timeout);
	}, [error]);

	useEffect(() => {
		const onDeviceOffline = data => {
			if (data.helloDeviceId === +selectedRoom?.helloDeviceId) {
				setSelectedRoom(prevState => ({ ...prevState, isOnline: false }));
			}
		};
		const onDeviceOnline = data => {
			if (data.helloDeviceId === +selectedRoom?.helloDeviceId) {
				setSelectedRoom(prevState => ({ ...prevState, isOnline: true }));
			}
		};

		socket.on(SocketEvents.Client.ON_DEVICE_OFFLINE, onDeviceOffline);
		socket.on(SocketEvents.Client.ON_DEVICE_ONLINE, onDeviceOnline);
		return () => {
			socket.off(SocketEvents.Client.ON_DEVICE_OFFLINE, onDeviceOffline);
			socket.off(SocketEvents.Client.ON_DEVICE_ONLINE, onDeviceOnline);
		};
	}, [socket, selectedRoom]);

	const showAllowPermissionModal = () => {
		isAllowPermissionPrompt.current = true;
		setTimeout(() => {
			if (!isAllowPermissionPrompt.current) {
				return;
			}

			dispatch(
				healthSystemsActionCreators.setStreamPermissionMessage({
					component: ErrorComponentTypes.Modal,
					type: isChrome || isEdgeChromium ? StreamError.MICROPHONE_BLOCKED.type : StreamError.MICROPHONE_BLOCKED_GENERIC.type,
				})
			);
		}, 500);
	};

	const openCallViewUrl = callType => {
		const queryParams = new URLSearchParams({
			[StartQueryStringKeys.OBJECT_ID]: selectedRoom.helloDeviceId,
			[StartQueryStringKeys.OBJECT_TYPE]: enums.ObjectTypes.HELLO_DEVICE,
			[StartQueryStringKeys.CONFERENCE_NAME]: selectedRoom.name,
			[StartQueryStringKeys.CALL_TYPE]: callType,
			[StartQueryStringKeys.ROOM_TYPE]: selectedRoom.type,
			[StartQueryStringKeys.IS_MULTI_BED]: selectedRoom.isMultiBed,
		});
		clearPermissions();
		const url = callType === enums.CallTypes.SECURITYCAM ? 'patient-feed' : 'call';
		if (isMobileSafari) {
			history.replace(`/${url}?${queryParams.toString()}`);
		} else {
			window.open(`/${url}?${queryParams.toString()}`, '_blank');
		}
	};

	const clearPermissions = () => {
		dispatch(healthSystemsActionCreators.setStreamPermissionMessage(null));
	};

	const talkToPatient = async () => {
		if (!canCall()) {
			setIsDisabledModalShown(true);
			return;
		}
		if (selectedRoom.aiPrivacyStatus) {
			setError(translate('enablePrivacyButtons', { value: helloName }));
			return;
		}
		if (selectedRoom.camera === DeviceAvailability.OFFLINE) {
			setError(translate('cameraOffline'));
			return;
		}
		try {
			showAllowPermissionModal();
			await requestMediaPermissions({ audio: true, video: false });
			openCallViewUrl(enums.CallTypes.VIDEO);
		} catch (err) {
			handlePermissionErrors(enums.CallTypes.VIDEO, err);
		}
		isAllowPermissionPrompt.current = false;
	};

	const handlePermissionErrors = async (callType, err) => {
		const { camera, microphone } = await checkIfMediaDevicesPlugged();
		if ([enums.CallTypes.VIDEO, enums.CallTypes.AUDIO].includes(callType) && (!camera || !microphone)) {
			dispatch(
				healthSystemsActionCreators.setStreamPermissionMessage({
					component: ErrorComponentTypes.Modal,
					type: microphone ? StreamError.MICROPHONE_NOT_FOUND.type : StreamError.CAMERA_NOT_FOUND.type,
				})
			);
			return;
		}

		const { type, name } = err;
		if (type === MediaPermissionsErrorType.UserPermissionDenied) {
			if (name === UserPermissionDeniedErrors.NotAllowedError) {
				dispatch(
					healthSystemsActionCreators.setStreamPermissionMessage({
						component: isChrome || isEdgeChromium ? ErrorComponentTypes.Popup : ErrorComponentTypes.Modal,
						type: isChrome || isEdgeChromium ? StreamError.MICROPHONE_BLOCKED.type : StreamError.MICROPHONE_BLOCKED_GENERIC.type,
					})
				);
			}
		}
	};

	const viewPatient = async () => {
		if (disabledVisitTime.isDisabled) {
			return;
		}
		try {
			if (selectedRoom.aiPrivacyStatus) {
				setError(translate('enablePrivacyButtons', { value: helloName }));
				return;
			}
			if (selectedRoom.camera === DeviceAvailability.OFFLINE) {
				setError(translate('enablePrivacyButtons', { value: helloName }));
				return;
			}
			showAllowPermissionModal();
			await requestMediaPermissions({ audio: true, video: false });
		} catch (err) {
			setError(err.message);
		}
		isAllowPermissionPrompt.current = false;
		openCallViewUrl(enums.CallTypes.SECURITYCAM);
	};

	const canCall = () => {
		if (disabledVisitTime.isDisabled) {
			return false;
		}
		if (selectedRoom?.visitorTypeId === VisitorType.FAMILY_MEMBER) {
			return true;
		}
		return isDuringVisitingHours();
	};

	const isDuringVisitingHours = () => {
		let result = false;
		const stringToDate = stringDate => {
			const date = new Date();
			const hours = stringDate.substring(0, stringDate.lastIndexOf(':'));
			const minutes = stringDate.substring(stringDate.lastIndexOf(':') + 1, stringDate.length);
			date.setHours(hours);
			date.setMinutes(minutes);
			return date;
		};
		const list = flattenedVisitingHours.filter(item => item.to && item.weekDay === new Date().getDay());
		for (let i = 0; i < list.length; i += 1) {
			const fromDate = stringToDate(list[i].from);
			const toDate = stringToDate(list[i].to);
			if (isInBetweenFromAndToDate(fromDate, toDate)) {
				result = true;
				break;
			}
		}
		return result;
	};

	const isInBetweenFromAndToDate = (fromDate, toDate) => {
		const dateNow = new Date();
		return timeDifference(dateNow, fromDate) > 0 && timeDifference(dateNow, toDate) < 0;
	};

	const isVisitorCameraAvailable = () => {
		if (!selectedRoom.isOnline) {
			return false;
		}
		if (selectedRoom.camera === DeviceAvailability.OFFLINE) {
			return false;
		}
		if (disabledVisitTime.isDisabled) {
			return false;
		}
		if (selectedRoom.visitorTypeId === VisitorType.FAMILY_MEMBER) {
			return true;
		}
		return isDuringVisitingHours();
	};

	return (
		<MainLayout isMonitoring={true}>
			<Grid columns={screenType.isSmall ? '1fr' : '1fr 4fr'} stretch='100%' width='100%'>
				{((screenType.isSmall && !selectedRoom) || !screenType.isSmall) && (
					<aside className='hello-list'>
						<h4>{translate('devices')}</h4>
						{roomsAssigned.map(room => (
							<div key={room.id} className='flex cursor-pointer' onClick={() => setSelectedRoom(room)}>
								<HelloIcon color={room.id === selectedRoom?.id ? 'var(--blue-2)' : 'var(--gray-5)'} />
								<p className={`${room.id === selectedRoom?.id ? 'active' : ''}`}>{room.name}</p>
							</div>
						))}
						{roomsAssigned.length === 0 && <p>{translate('noData')}</p>}
					</aside>
				)}
				{((screenType.isSmall && selectedRoom) || !screenType.isSmall) && (
					<main className='main-view'>
						{roomsAssigned.length === 0 && (
							<div className='empty-state-wrapper'>
								<EmptyState title={translate('noHelloIsSelected', { value: helloName })} image='no-hello.svg' />
							</div>
						)}
						{roomsAssigned.length && !selectedRoom && (
							<div className='empty-state-wrapper'>
								<p>{translate('communicateWithPatient')}</p>
							</div>
						)}
						{selectedRoom && (
							<>
								<div className='flex flex-align-center align-self-center'>
									{screenType.isSmall && (
										<Button
											onClick={() => {
												setSelectedRoom(null);
											}}
											icon='chevron_left'
										/>
									)}
									<h4>{selectedRoom?.name}</h4>
								</div>
								<div className='visitors-content top-15 bottom-15'>
									<div className='flex flex-align-center'>
										<div className='flex flex-align-center microphone-status'>
											<div>
												<img
													src={`${healthCareCdnUrl}monitoring/video-feed/mic-${
														selectedRoom.microphone === DeviceAvailability.ONLINE ? 'on' : 'off-color'
													}.svg`}
													alt='mic-status'
												/>
											</div>
											{translate(selectedRoom.microphone === DeviceAvailability.ONLINE ? 'microphoneOn' : 'microphoneMuted')}
										</div>
										<div className='flex flex-align-center camera-status'>
											<div>
												<img
													src={`${healthCareCdnUrl}monitoring/video-feed/camera-${
														selectedRoom.camera === DeviceAvailability.ONLINE ? 'on' : 'off-color'
													}.svg`}
													alt='camera-status'
												/>
											</div>
											{translate(selectedRoom.camera === DeviceAvailability.ONLINE ? 'cameraOn' : 'cameraOff')}
										</div>
									</div>
									{selectedRoom.helloDeviceId && (
										<>
											<div className={`flex device-status ${isVisitorCameraAvailable() ? 'available' : 'unavailable'} top-15`}>
												<span />
												{translate(isVisitorCameraAvailable() ? 'available' : 'unavailable')}
											</div>
											{disabledVisitTime.isDisabled && (
												<div className='hello-feature-grid top-15'>
													<p>
														{translate('disabledFor')}:
														<DisabledCountDown
															disabledTime={disabledVisitTime}
															setDisabledTime={() => setDisabledVisitTime(prevState => ({ ...prevState, isDisabled: false }))}
														/>
													</p>
												</div>
											)}
											{selectedRoom.type !== RoomTypes.BABY_ROOM.type && (
												<HelloFeatureBlock
													onClick={talkToPatient}
													title={translate('talkToPatient')}
													className='hello-feature-audio top-15'
												/>
											)}
											{selectedRoom.type === RoomTypes.BABY_ROOM.type && (
												<HelloFeatureBlock
													title={translate('viewYourBaby')}
													onClick={viewPatient}
													className='hello-feature-camera-feed top-15'
												/>
											)}
										</>
									)}
								</div>
								{!disabledVisitTime.isDisabled && visitingHours.length > 0 && (
									<div className='visiting-hours-table'>
										<p>{translate('visitingHours')}</p>
										<CustomTable
											headers={[
												{ title: translate('monday'), id: 0 },
												{ title: translate('tuesday'), id: 1 },
												{ title: translate('wednesday'), id: 2 },
												{ title: translate('thursday'), id: 3 },
												{ title: translate('friday'), id: 4 },
												{ title: translate('saturday'), id: 5 },
												{ title: translate('sunday'), id: 6 },
											]}
											rows={visitingHours}
											className='admin-table'
											isEditable={false}
										/>
									</div>
								)}
							</>
						)}
					</main>
				)}
			</Grid>
			{error && (
				<Alert
					display={error !== null}
					message={error}
					variant='dark baby-room-alert'
					fixed={true}
					onClose={() => setError(null)}
				/>
			)}
			{isDisabledModalShown && (
				<Modal
					display={true}
					position='center'
					primaryButtonLabel='OK'
					hideCloseButton={true}
					onModalSubmit={() => setIsDisabledModalShown(false)}
					onModalClose={() => setIsDisabledModalShown(false)}>
					<form>
						<h3>{translate('visits')}</h3>
						<p>{translate('callDuringVisitingHours')}</p>
					</form>
				</Modal>
			)}
		</MainLayout>
	);
};

export default Visitor;
