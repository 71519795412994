import { getDeviceList } from 'api/devices.js';
import { getDoctorAssigned } from 'api/doctors.js';
import { deleteOrgUnit, getLogoUrl, saveLogo, uploadLogo } from 'api/healthSystems.js';
import { getRegionSubTree } from 'api/tree.js';
import { unAssignMember } from 'api/users.js';
import { getVisitingHours } from 'api/visitingHours.js';
import classNames from 'classnames';
import AssignDoctor from 'components/AssignDoctor.jsx';
import Pagination from 'components/Common/Pagination.jsx';
import DeviceActions from 'components/DeviceActions.jsx';
import { Modal, Form, Dropdown, DescriptionBox, CustomTable, Button, Breadcrumb, Alert } from 'components/index.js';
import FeatureFlagsCategories from 'components/FeatureFlagsCategories.jsx';
import ScheduleNightVision from 'components/ScheduleNightVision.jsx';
import SectorCallSettings from 'components/SectorCallSettings.jsx';
import SelectTimeZone from 'components/SelectTimeZone.jsx';
import { Tab, TabList, TabPanel, TabPanels, Tabs } from 'components/Tabs.jsx';
import VisitingHoursConfiguration from 'components/VisitingHoursConfiguration.jsx';
import { CompanySettings, IntegrationTypesSettings, hospitalFeatureFlagsCategories } from 'constants/configurationEnums.js';
import {
	ConnectivityStatus,
	DeviceListLevel,
	HospitalLogoType,
	SectorTypes,
	TeamTypes,
	TreeHierarchyType,
	UserRoles,
} from 'constants/enums.js';
import { healthCareCdnUrl } from 'constants/global-variables.js';
import AlexaSmartPropertiesWrapper from 'containers/AlexaSmartPropertiesWrapper.jsx';
import EditSectorModal from 'containers/EditSectorModal.jsx';
import PinLockWrapper from 'containers/PinLockWrapper.jsx';
import translate from 'i18n-translations/translate.jsx';
import TimeZoneIcon from 'icons/Organization/TimeZone.jsx';
import { getUserRole } from 'infrastructure/auth.js';
import {
	buildTree,
	decodeHtml,
	findDeviceById,
	findSectorById,
	getAppVersion,
	getCodeNameByOsVersion,
	getConfigurationValue,
	getHealthSystemDevices,
	getHierarchyByHelloDeviceId,
	getParentSector,
	sortDevicesByTreeRoomsOrder,
} from 'infrastructure/helpers/commonHelpers.js';
import { mapSectionsToRender } from 'infrastructure/helpers/mapSectorsToRender.js';
import { centerAspectCrop, getCroppedImg } from 'infrastructure/helpers/mediaHelper.js';
import { getInitialVisitingHoursMaped, reArrangeVisitingHours } from 'infrastructure/helpers/visitingHoursHelper.js';
import _ from 'lodash';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { CSVLink } from 'react-csv';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { actionCreators as devicesActionCreators } from 'state/devices/actions.js';
import { actionCreators } from 'state/healthSystems/actions.js';

const Hospital = props => {
	const intl = useIntl();
	const history = useHistory();
	const dispatch = useDispatch();
	const userSession = useSelector(state => state.user.userSession);
	const healthSystems = useSelector(state => state.healthSystems);
	const companyConfigurations = useSelector(state => state.company.companySettings?.companyConfigurations);
	const [devices, setDevices] = useState([]);
	const [isDeleteOrgUnitModalOpen, setIsDeleteOrgUnitModalOpen] = useState(false);
	const [isDeleteDeviceModalLoading, setIsDeleteDeviceModalLoading] = useState(false);
	const [currentTab, setCurrentTab] = useState(0);
	const [isEditSectorModalOpen, setIsEditSectorModalOpen] = useState(false);
	const [error, setError] = useState(null);
	const [doctors, setDoctors] = useState([]);
	const [isUploadLogoModalOpen, setIsUploadLogoModalOpen] = useState(false);
	const [src, setSrc] = useState(null);
	const [crop, setCrop] = useState({ unit: '%', width: 100, height: 100, aspect: 16 / 9, x: 0, y: 0 });
	const [logoType, setLogoType] = useState(null);
	const [isHospitalLogoModalLoading, setIsHospitalLogoModalLoading] = useState(false);
	const [fileError, setFileError] = useState('');
	const [selectedDoctor, setSelectedDoctor] = useState(null);
	const [isAddDoctorShown, setIsAddDoctorShown] = useState(false);
	const [helloDeviceId, setHelloDeviceId] = useState('');
	const [currentSector, setCurrentSector] = useState({});
	const [isVisitingHoursModalOpen, setIsVisitingHoursModalOpen] = useState(false);
	const [isTimeZoneModalOpen, setIsTimeZoneModalOpen] = useState(false);
	const [isNightVisionModalOpen, setIsNightVisionModalOpen] = useState(false);
	const [visitingHours, setVisitingHours] = useState([]);
	const [pagination, setPagination] = useState({ pageSize: 10, pageIndex: 0 });
	const [doctorsTotalCount, setDoctorsTotalCount] = useState(0);
	const [isAlexaSmartPropertiesOpen, setIsAlexaSmartPropertiesOpen] = useState(false);
	const [isTableDataLoading, setIsTableDataLoading] = useState(false);
	const [isPinLockOpen, setIsPinLockOpen] = useState(false);
	const [sectorToEdit, setSectorToEdit] = useState({ name: '', type: SectorTypes.HOSPITAL });
	const [isDeleteSectorLoading, setIsDeleteSectorLoading] = useState(false);
	const imgRef = useRef(null);

	const foundSector = useMemo(
		() => findSectorById(healthSystems.treeData.tree, props.match.params.hospitalId),
		[healthSystems.treeData.tree, props.match.params.hospitalId]
	);

	const onImageLoad = event => {
		const { naturalWidth, naturalHeight } = event.target;
		const newCrop = centerAspectCrop(naturalWidth, naturalHeight, crop.aspect);
		setCrop(newCrop);
	};

	const initDeleteOrg = useCallback(data => {
		setSectorToEdit(data);
		setIsDeleteOrgUnitModalOpen(prevState => !prevState);
	}, []);

	const openEditSectorModal = useCallback(
		selectedSector => {
			const sector = findSectorById(healthSystems.treeData.tree, selectedSector[`${selectedSector.type}Id`]);
			const sectorParent = getParentSector(healthSystems.treeData.tree, selectedSector);
			sector.parentSectorName = sectorParent.name;
			sector.parentSectorType = sectorParent.type;
			setSectorToEdit(sector);
			setIsEditSectorModalOpen(prevState => !prevState);
		},
		[healthSystems.treeData.tree]
	);

	const sectionChildrenData = useMemo(() => {
		let allSections = {
			departments: [],
			floors: [],
			rooms: [],
		};
		if (healthSystems.treeData.tree.length > 0) {
			allSections = mapSectionsToRender(
				healthSystems.treeData.tree,
				currentSector,
				sector => openEditSectorModal(sector),
				initDeleteOrg,
				userSession.healthSystem.treeHierarchyTypeId
			);
		}

		return allSections;
	}, [
		healthSystems.treeData.tree,
		currentSector,
		initDeleteOrg,
		userSession.healthSystem.treeHierarchyTypeId,
		openEditSectorModal,
	]);

	useEffect(() => {
		setCurrentSector(foundSector);
	}, [foundSector]);

	const fetchDevices = useCallback(async () => {
		setIsTableDataLoading(true);
		const devicesRes = await getDeviceList(DeviceListLevel.HOSPITAL, props.match.params.hospitalId);
		if (devicesRes.error) {
			setError(devicesRes.error.message);
		} else {
			if (foundSector?.subOptions) {
				devicesRes.map(deviceRes => {
					const device = findDeviceById(foundSector?.subOptions, deviceRes.solHelloDeviceId);
					if (device) {
						deviceRes.roomName = device.name;
						deviceRes.roomId = device.roomId;
					}
					return deviceRes;
				});
			}
			setDevices(devicesRes);
		}

		setIsTableDataLoading(false);
	}, [foundSector?.subOptions, props.match.params.hospitalId]);

	const fetchAssignedDoctors = useCallback(async () => {
		setIsTableDataLoading(true);
		const params = {
			pageIndex: pagination.pageIndex,
			pageSize: pagination.pageSize,
		};
		const assignedDoctorsRes = await getDoctorAssigned(props.match.params.hospitalId, params);

		if (assignedDoctorsRes.error) {
			setError(assignedDoctorsRes.error);
		} else {
			setDoctors(assignedDoctorsRes.doctors);
			setDoctorsTotalCount(assignedDoctorsRes.totalCount);
		}
		setIsTableDataLoading(false);
	}, [pagination.pageIndex, pagination.pageSize, props.match.params.hospitalId]);

	const fetchVisitingHours = useCallback(async () => {
		setIsTableDataLoading(true);
		const response = await getVisitingHours(DeviceListLevel.HOSPITAL, props.match.params.hospitalId);
		if (response.error) {
			setError(response.error.message);
		} else {
			const result = getInitialVisitingHoursMaped(response.visitingHours);
			const visitingHoursRearranged = reArrangeVisitingHours(result, intl);
			setVisitingHours(visitingHoursRearranged);
		}
		setIsTableDataLoading(false);
	}, [intl, props.match.params.hospitalId]);

	useEffect(() => {
		fetchDevices();
	}, [fetchDevices, props.match.params.hospitalId]);

	useEffect(() => {
		if (currentTab === tabsList().indexOf('doctorPooling')) {
			fetchAssignedDoctors();
		}

		if (currentTab === tabsList().indexOf('visitingHours')) {
			fetchVisitingHours();
		}
	}, [currentTab, props.match.params.hospitalId, pagination]);

	const openEditHospitalModal = async selectedSector => {
		const response = await getLogoUrl(selectedSector.hospitalId);
		if (response.error) {
			setError(response.error.message);
			return;
		}
		const clonedSector = _.cloneDeep(selectedSector);
		clonedSector.parentSectorName = userSession.healthSystem.name;
		clonedSector.parentSectorType = SectorTypes.HEALTH_SYSTEM;
		clonedSector.primaryDisplayLogo = response.picture;
		clonedSector.secondaryDisplayLogo = response?.secondaryPicture ?? null;
		setSectorToEdit(prevState => ({ ...prevState, ...clonedSector }));
		setIsEditSectorModalOpen(prevState => !prevState);
	};

	const mapDoctors = docs =>
		docs?.map(item => ({
			id: item.id,
			name: `${item.firstName} ${item.lastName}`,
			actions: (
				<>
					{item.teamTypeId === TeamTypes.HOSPITAL && (
						<div className='wrapped'>
							<i className='material-icons-outlined boxed-icon' id={item.id} onClick={() => setSelectedDoctor(item)}>
								delete
							</i>
						</div>
					)}
				</>
			),
		}));

	const getConnectionType = device => (
		<>
			{[device.wifi, device.ethernet].includes(ConnectivityStatus.ON) && (
				<>
					{device.wifi === ConnectivityStatus.ON && 'WiFi'}
					{device.ethernet === ConnectivityStatus.ON && device.wifi === ConnectivityStatus.ON && ','}{' '}
					{device.ethernet === ConnectivityStatus.ON && 'Ethernet'}
				</>
			)}

			{device.wifi === ConnectivityStatus.OFF && device.ethernet === ConnectivityStatus.OFF && 'N/A'}
		</>
	);

	const getDeviceOwner = device => (
		<div className='flex'>
			{!device?.isVirtualPatientAssigned && !device?.isDefaultOwner ? decodeHtml(device.owner) : translate('unassigned')}
		</div>
	);

	const displayDevices = () => {
		if (devices.length === 0 || healthSystems.treeData.tree.length === 0) return [];
		const sortedDevicesList = sortDevicesByTreeRoomsOrder(devices, sectionChildrenData.rooms);

		return sortedDevicesList.map(device => ({
			id: device.id,
			roomName: device.roomName,
			...(isPatientDisabled() ? [] : { owner: getDeviceOwner(device) }),
			serialNumber: device.serialNumber || 'N/A',
			macAddress: device.macAddress || 'N/A',
			appVersion: device.appVersion ? getAppVersion(device.appVersion) : 'N/A',
			androidVersion: device.appVersion ? getCodeNameByOsVersion(device.osVersion) : 'N/A',
			osVersion: device.firmwareRevision || 'N/A',
			ipAddress: device.ipAddress || 'N/A',
			ssid: device.ssid || 'N/A',
			connectionType: getConnectionType(device),
			actions: (
				<DeviceActions
					setHelloDeviceId={setHelloDeviceId}
					setIsDeleteDeviceModalLoading={setIsDeleteDeviceModalLoading}
					fetchTreeData={fetchTreeData}
					isDeleteDeviceModalLoading={isDeleteDeviceModalLoading}
					helloDeviceId={helloDeviceId}
					device={device}
					owner={getDeviceOwner(device)}
					isPatientDisabled={isPatientDisabled()}
					setDevices={setDevices}
					loadRoom={fetchDevices}
				/>
			),
		}));
	};

	const deleteOrgUnitSubmit = async () => {
		setIsDeleteSectorLoading(true);
		const response = await deleteOrgUnit(sectorToEdit?.typeId, sectorToEdit?.id);
		if (response?.error) {
			setError(response.error.message);
			setIsDeleteSectorLoading(false);
			return;
		}
		setIsDeleteOrgUnitModalOpen(prevState => !prevState);
		setIsDeleteSectorLoading(false);
		fetchTreeData();
		if (sectorToEdit.type === SectorTypes.HOSPITAL) {
			history.push('/health-system');
			dispatch(actionCreators.updateBreadcrumb([]));
		}
	};

	const fetchTreeData = async () => {
		const subTreeResponse = await getRegionSubTree(userSession.healthSystem.id, userSession.regionId);
		if (subTreeResponse.error) {
			setError(subTreeResponse.error.message);
		} else {
			const { healthSystem } = subTreeResponse.organization;
			const tree = buildTree(healthSystem);
			const { online, busy, privacy, pairedRemote } = getHealthSystemDevices(healthSystem);
			dispatch(devicesActionCreators.setBulkDevicesBusy(busy));
			dispatch(devicesActionCreators.setBulkDevicesOnline(online));
			dispatch(devicesActionCreators.setBulkDevicesPrivacy(privacy));
			dispatch(devicesActionCreators.setBulkPairedRemoteDevice(pairedRemote));
			dispatch(actionCreators.setHealthSystem(healthSystem));
			dispatch(actionCreators.setTreeData(tree));
		}
	};

	const deleteDoctor = async () => {
		const response = await unAssignMember(userSession.healthSystem.id, props.match.params.hospitalId, selectedDoctor.id);
		if (response.error) {
			setError(response.error.message);
		} else {
			setDoctors(prevState => prevState.filter(item => item.id !== selectedDoctor.id));
		}
		setSelectedDoctor(null);
	};

	const onSelectFile = async (e, logoType) => {
		if (e.target.files && e.target.files.length > 0) {
			const reader = new FileReader();
			reader.addEventListener('load', () => setSrc(reader.result));
			reader.readAsDataURL(e.target.files[0]);
		}
		setLogoType(logoType);
		setIsUploadLogoModalOpen(true);
		setIsEditSectorModalOpen(false);
	};

	const makeClientCrop = async () => {
		setIsHospitalLogoModalLoading(true);
		try {
			if (imgRef?.current && crop.width && crop.height) {
				const croppedImageUrl = await getCroppedImg(imgRef?.current, crop, 'newFile.jpeg');
				const response = await fetch(croppedImageUrl);
				const blob = await response.blob();
				const image = await uploadLogo(blob);
				const sendData = logoType === HospitalLogoType.PRIMARY ? { picture: image.url } : { secondarypicture: image.url };

				const isLogoSaved = await saveLogo(currentSector?.hospitalId, sendData);
				if (isLogoSaved.error) {
					setFileError(intl.formatMessage({ id: 'errorDuringRequestProcessing' }));
				} else {
					const logoKey = logoType === HospitalLogoType.PRIMARY ? 'primaryDisplayLogo' : 'secondaryDisplayLogo';
					setSectorToEdit(prevState => ({ ...prevState, [logoKey]: image.url }));
				}
				setSrc('');
			}
		} catch (e) {
			setFileError(`${intl.formatMessage({ id: 'errorOccurred' })} ${e.message}`);
		}
		setIsUploadLogoModalOpen(false);
		setIsHospitalLogoModalLoading(false);
		openEditHospitalModal(currentSector);
	};

	const toggleHospitalLogoCrop = () => {
		setIsUploadLogoModalOpen(prevState => !prevState);
		setCrop(prevState => ({ ...prevState, unit: '%', width: 100, height: 100 }));
	};

	const exportAsCsv = () =>
		devices.map(({ serialNumber, macAddress, appVersion, firmwareRevision, osVersion, solHelloDeviceId }) => ({
			serialNumber,
			room: getHierarchyByHelloDeviceId(healthSystems.treeData.tree, solHelloDeviceId),
			macAddress,
			appVersion,
			osVersion: firmwareRevision,
			androidVersion: getCodeNameByOsVersion(osVersion),
		}));

	const hasHospitalFloors = useCallback(
		() =>
			[
				TreeHierarchyType.DEFAULT_TREE,
				TreeHierarchyType.HOSPITAL_DEPT_FLOOR_ROOM,
				TreeHierarchyType.HOSPITAL_FLOOR_ROOM,
			].includes(userSession.healthSystem.treeHierarchyTypeId),
		[userSession.healthSystem.treeHierarchyTypeId]
	);

	const hasHospitalDepts = useCallback(
		() =>
			[TreeHierarchyType.DEFAULT_TREE, TreeHierarchyType.HOSPITAL_DEPT_FLOOR_ROOM, TreeHierarchyType.HOSPITAL_DEPT_ROOM].includes(
				userSession.healthSystem.treeHierarchyTypeId
			),
		[userSession.healthSystem.treeHierarchyTypeId]
	);

	const getDescriptionData = useCallback(() => {
		let result = [
			{
				title: translate('roomsInThisHospital'),
				description: sectionChildrenData.departments.length,
				id: 0,
			},
		];
		if (!healthSystems.isRoomsOnlyView) {
			result = [
				hasHospitalDepts() && {
					title: translate('departmentsInThisHospital'),
					description: sectionChildrenData.departments.length,
					id: 0,
				},
				hasHospitalFloors() && {
					title: translate('floorsInThisHospital'),
					description: sectionChildrenData.floors.length,
					id: 1,
				},
				{ title: translate('roomsInThisHospital'), description: sectionChildrenData.rooms.length, id: 2 },
				{ title: translate('enrolledDevices'), description: devices.length, id: 3 },
			];
		}
		return result;
	}, [devices, hasHospitalDepts, hasHospitalFloors, healthSystems.isRoomsOnlyView, sectionChildrenData]);

	const isPatientDisabled = () =>
		getConfigurationValue(companyConfigurations[CompanySettings.PATIENT_VIRTUAL_ADMISSION]) &&
		!getConfigurationValue(companyConfigurations[CompanySettings.PATIENT_MANUAL_ADMISSION]);

	const onBreadcrumbClick = (options, breadcrumbIndex) => {
		const breadcrumb = [];
		options.forEach((option, index) => {
			if (breadcrumbIndex + 1 > index) {
				breadcrumb.push(option);
			}
		});
		dispatch(actionCreators.updateBreadcrumb(breadcrumb));
	};

	const tabsList = () =>
		[
			'devices',
			'rooms',
			hasHospitalFloors() && !healthSystems.isRoomsOnlyView && 'floors',
			hasHospitalDepts() && !healthSystems.isRoomsOnlyView && 'departments',
			'doctorPooling',
			'visitingHours',
			getUserRole() !== UserRoles.SUPER_USER && 'featureFlags',
			'defaultCamera',
		].filter(Boolean);

	return (
		<div className='organization'>
			<div className='flex sector-header-wrapper'>
				<div className='breadcrumb-container hs-breadcrumb-container'>
					<Breadcrumb links={healthSystems.breadcrumb} onBreadcrumbClick={onBreadcrumbClick} isFromHealthSystems={true} />
				</div>
				<div className='sector-header flex'>
					<DescriptionBox data={getDescriptionData()} />
					<div className='sector-btn-actions'>
						<Button
							text={translate('deleteHospital')}
							imgIcon={`${healthCareCdnUrl}admin/delete-room.svg`}
							variant='red'
							onClick={() =>
								initDeleteOrg({ ...currentSector, id: props.match.params.hospitalId, typeId: DeviceListLevel.HOSPITAL })
							}
							disabled={!currentSector?.hospitalId}
						/>
						<Button
							text={translate('editDetails')}
							onClick={() => openEditHospitalModal(currentSector)}
							imgIcon={`${healthCareCdnUrl}admin/edit.svg`}
							variant='yellow'
							disabled={!currentSector?.hospitalId}
						/>
						<Dropdown
							position='bottom'
							icon='expand_more'
							className={classNames('organization-dropdown', { disabled: !currentSector?.hospitalId })}
							haveWarnings={!currentSector?.timezone}>
							<Button
								text={translate('addDoctor')}
								imgIcon={`${healthCareCdnUrl}admin/add-doctor.svg`}
								onClick={() => setIsAddDoctorShown(true)}
							/>
							<Button
								variant='white'
								imgIcon={`${healthCareCdnUrl}admin/visiting-hours.svg`}
								onClick={() => setIsVisitingHoursModalOpen(true)}
								text={intl.formatMessage({ id: 'visitingHours' })}
							/>
							{devices.length > 0 && (
								<>
									<Button
										variant='white'
										imgIcon={`${healthCareCdnUrl}admin/export-as-csv.svg`}
										text={
											<CSVLink
												data-cy='exportAsCSVBtn'
												data={exportAsCsv()}
												filename={`${currentSector?.name}-${(+new Date()).toString()}.csv`}>
												{translate('exportAsCSV')}
											</CSVLink>
										}
									/>
									{getConfigurationValue(companyConfigurations[IntegrationTypesSettings.ALEXA]) && (
										<Button
											variant='white blue-icon'
											text={intl.formatMessage({ id: 'alexaSmartProperties' })}
											size='medium'
											icon='home_max'
											onClick={() => setIsAlexaSmartPropertiesOpen(prev => !prev)}
										/>
									)}
									<Button
										variant='white blue-icon'
										text={intl.formatMessage({ id: 'pinLock' })}
										size='medium'
										imgIcon={`${healthCareCdnUrl}admin/pin-lock.svg`}
										onClick={() => setIsPinLockOpen(prev => !prev)}
									/>
								</>
							)}
							<Button
								variant='white'
								onClick={() => setIsTimeZoneModalOpen(true)}
								text={intl.formatMessage({ id: 'setTimeZone' })}
								svgIcon={<TimeZoneIcon color={!currentSector?.timezone ? '#E0AF4C' : '#5390FF'} />}
							/>
							<Button
								variant='white'
								imgIcon={`${healthCareCdnUrl}admin/night-vision.svg`}
								onClick={() => setIsNightVisionModalOpen(true)}
								text={intl.formatMessage({ id: 'scheduleNightVision' })}
							/>
						</Dropdown>
					</div>
				</div>
			</div>
			<Tabs activeIndex={currentTab} onChange={index => setCurrentTab(index)}>
				<TabList>
					{tabsList().map(item => (
						<Tab key={item}>{translate(item)}</Tab>
					))}
				</TabList>
				<TabPanels>
					<TabPanel>
						<CustomTable
							headers={[
								{ title: translate('room'), id: 'roomName' },
								...(isPatientDisabled() ? [] : [{ title: translate('patient'), id: 'owner' }]),
								{ title: translate('deviceSerialNumber'), id: 'serialNumber' },
								{ title: translate('macAddress'), id: 'macAddress' },
								{ title: translate('appVersion'), id: 'appVersion' },
								{ title: translate('androidVersion'), id: 'androidVersion' },
								{ title: translate('osVersion'), id: 'osVersion' },
								{ title: translate('ipAddress'), id: 'ipAddress' },
								{ title: 'SSID', id: 'ssid' },
								{ title: translate('connectionType'), id: 'connectionType' },
								{ title: translate('actions'), id: 'actions', columnClass: 'normal-wrap' },
							]}
							rows={isTableDataLoading ? [] : displayDevices()}
							className='admin-table'
							isLoading={isTableDataLoading}
						/>
					</TabPanel>
					<TabPanel>
						<CustomTable
							headers={[
								{ title: translate('roomNames'), id: 'name' },
								{ title: '', id: 'edit' },
							]}
							rows={sectionChildrenData[!healthSystems.isRoomsOnlyView ? 'rooms' : 'departments']}
							isEditable={false}
						/>
					</TabPanel>
					{hasHospitalFloors() && !healthSystems.isRoomsOnlyView && (
						<TabPanel>
							<CustomTable
								headers={[
									{ title: translate('floorNames'), id: 'name' },
									{ title: '', id: 'edit' },
								]}
								rows={sectionChildrenData.floors}
								isEditable={false}
							/>
						</TabPanel>
					)}
					{hasHospitalDepts() && !healthSystems.isRoomsOnlyView && (
						<TabPanel>
							<CustomTable
								headers={[
									{ title: translate('departmentNames'), id: 'name' },
									{ title: '', id: 'edit' },
								]}
								rows={sectionChildrenData.departments}
								isEditable={false}
							/>
						</TabPanel>
					)}
					<TabPanel>
						<CustomTable
							headers={[
								{ title: translate('doctorPooling'), id: 'name' },
								{ title: '', id: 'actions' },
							]}
							rows={isTableDataLoading ? [] : mapDoctors(doctors)}
							isEditable={false}
							isLoading={isTableDataLoading}
						/>
						{doctorsTotalCount > 10 && (
							<Pagination
								totalCount={doctorsTotalCount}
								pageSize={pagination.pageSize}
								pageIndex={pagination.pageIndex}
								onChange={(pageSize, pageIndex) => setPagination({ pageSize, pageIndex })}
							/>
						)}
					</TabPanel>
					<TabPanel>
						<CustomTable
							headers={[
								{ title: translate('monday'), id: 0 },
								{ title: translate('tuesday'), id: 1 },
								{ title: translate('wednesday'), id: 2 },
								{ title: translate('thursday'), id: 3 },
								{ title: translate('friday'), id: 4 },
								{ title: translate('saturday'), id: 5 },
								{ title: translate('sunday'), id: 6 },
							]}
							rows={isTableDataLoading ? [] : visitingHours}
							className='admin-table'
							isLoading={isTableDataLoading}
						/>
					</TabPanel>
					{getUserRole() !== UserRoles.SUPER_USER && (
						<TabPanel>
							<FeatureFlagsCategories
								selectedHealthSystem={{ value: userSession.healthSystem.id }}
								levelId={props.match.params.hospitalId}
								currentSectorType={DeviceListLevel.HOSPITAL}
								featureFlagsCategories={hospitalFeatureFlagsCategories}
								setError={setError}
							/>
						</TabPanel>
					)}
					<TabPanel>
						<SectorCallSettings
							selectedHealthSystem={userSession?.healthSystem?.id}
							levelId={DeviceListLevel.HOSPITAL}
							sectorId={props.match.params.hospitalId}
						/>
					</TabPanel>
				</TabPanels>
			</Tabs>
			<Modal
				modalSelector='deleteHospitalModal'
				display={isDeleteOrgUnitModalOpen}
				position='center'
				primaryButtonLabel={translate('delete')}
				onModalSubmit={deleteOrgUnitSubmit}
				onModalClose={() => setIsDeleteOrgUnitModalOpen(prevState => !prevState)}
				primaryButtonLoading={isDeleteSectorLoading}>
				<Form title={translate('warning')} onSubmit={event => event.preventDefault()}>
					<p className='paragraph-break-word'>
						{translate('sureToDeleteOrg', {
							value: `${intl.formatMessage({ id: sectorToEdit.type })} ${sectorToEdit.name}`,
						})}
					</p>
				</Form>
			</Modal>
			<Modal
				className='crop-modal'
				display={isUploadLogoModalOpen}
				position='center'
				submitButtonText='Save'
				onModalSubmit={makeClientCrop}
				onModalClose={toggleHospitalLogoCrop}
				isLoading={isHospitalLogoModalLoading}>
				{src && (
					<ReactCrop crop={crop} onChange={newCrop => setCrop(newCrop)}>
						<img ref={imgRef} alt='ico' src={src} onLoad={onImageLoad} />
					</ReactCrop>
				)}
			</Modal>
			{isEditSectorModalOpen && (
				<EditSectorModal
					onEditSector={fetchTreeData}
					onModalClose={() => setIsEditSectorModalOpen(false)}
					isEditSectorModalOpen={true}
					sectorData={sectorToEdit}
					onSelectFile={onSelectFile}
					fileError={fileError}
				/>
			)}
			{isAddDoctorShown && (
				<AssignDoctor
					onSubmit={() => {
						fetchAssignedDoctors();
						setIsAddDoctorShown(false);
					}}
					onModalClose={() => setIsAddDoctorShown(false)}
					levelId={props.match.params.hospitalId}
					doctors={doctors}
				/>
			)}
			{selectedDoctor && (
				<Modal
					display={true}
					position='center'
					primaryButtonLabel={translate('delete')}
					onModalSubmit={deleteDoctor}
					onModalClose={() => setSelectedDoctor(null)}>
					<Form title={translate('deleteDoctor')} onSubmit={event => event.preventDefault()}>
						<p>
							{translate('sureToDeleteDoctor', {
								value: `${selectedDoctor.firstName} ${selectedDoctor.lastName}`,
							})}
						</p>
					</Form>
				</Modal>
			)}
			<Alert display={error} message={error} variant='dark' fixed={true} onClose={() => setError(null)} />
			{isVisitingHoursModalOpen && (
				<VisitingHoursConfiguration
					setIsVisitingHoursModalOpen={setIsVisitingHoursModalOpen}
					sector={currentSector}
					onSubmit={fetchVisitingHours}
				/>
			)}
			{isTimeZoneModalOpen && (
				<SelectTimeZone onModalClose={() => setIsTimeZoneModalOpen(false)} currentHospital={currentSector} />
			)}
			{isNightVisionModalOpen && (
				<ScheduleNightVision
					onModalClose={() => setIsNightVisionModalOpen(false)}
					sector={currentSector}
					fetchTreeData={fetchTreeData}
				/>
			)}
			{isAlexaSmartPropertiesOpen && (
				<AlexaSmartPropertiesWrapper
					sector={currentSector}
					setIsAlexaSmartPropertiesOpen={setIsAlexaSmartPropertiesOpen}
					isAlexaSmartPropertiesOpen={isAlexaSmartPropertiesOpen}
				/>
			)}
			{isPinLockOpen && (
				<PinLockWrapper
					pin={currentSector?.pin}
					isPinLockOpen={isPinLockOpen}
					setIsPinLockOpen={setIsPinLockOpen}
					healthSystemId={userSession.healthSystem.id}
					regionId={userSession.regionId}
					hospitalId={props.match.params.hospitalId}
					fetchTreeData={fetchTreeData}
				/>
			)}
		</div>
	);
};

export default Hospital;
