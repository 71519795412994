import React from 'react';
import { enums } from '@solaborate/calls';

import ConferenceCall from 'calls/Conference.jsx';
import ConferenceProvider, { ConferenceCtx } from 'calls/ConferenceProvider.jsx';
import { StartQueryStringKeys } from 'calls/enums/index.js';
import translate from 'i18n-translations/translate.jsx';
import Popup from 'calls/components/Popup.jsx';
import LightTheme from 'calls/styles/LightTheme.js';

const conferenceRouteRender = ({ location }) => {
	const queryParams = new URLSearchParams(location.search);
	const conferenceInfo = {
		callType:
			location.state?.callType ||
			(queryParams.get(StartQueryStringKeys.CALL_TYPE) && +queryParams.get(StartQueryStringKeys.CALL_TYPE)) ||
			enums.CallTypes.VIDEO,
		participants: [
			{
				objectId:
					location.state?.actioneeObjectId ||
					(queryParams.get(StartQueryStringKeys.OBJECT_ID) && +queryParams.get(StartQueryStringKeys.OBJECT_ID)),
				objectType:
					location.state?.actioneeObjectType ||
					(queryParams.get(StartQueryStringKeys.OBJECT_TYPE) && +queryParams.get(StartQueryStringKeys.OBJECT_TYPE)),
			},
		],
		conferenceName: location.state?.conferenceName || queryParams.get(StartQueryStringKeys.CONFERENCE_NAME),
		conversationId: location.state?.conversationId || queryParams.get(StartQueryStringKeys.CONVERSATION_ID),
		conferenceId: location.state?.conferenceId || queryParams.get(StartQueryStringKeys.CONFERENCE_ID),
		participantId: location.state?.participantId || queryParams.get(StartQueryStringKeys.PARTICIPANT_ID),
		localTrackTypes: location.state?.localTrackTypes,
		isGuest: location.state?.isGuest ?? false,
		isMeetingRoom:
			location.state?.isMeetingRoom ??
			(queryParams.get(StartQueryStringKeys.IS_MEETING_ROOM) && queryParams.get(StartQueryStringKeys.IS_MEETING_ROOM) === 'true'),
		interpreterId: location.state?.interpreterId,
		refToken: location.state?.refToken,
		roundingCallType: queryParams.get(StartQueryStringKeys.ROUNDING_CALL_TYPE),
		isAudioOnly: location.state?.isAudioOnly,
	};

	if (conferenceInfo.isMeetingRoom) {
		conferenceInfo.participants = [];
	}

	if (
		!conferenceInfo.conferenceId &&
		!conferenceInfo.isMeetingRoom &&
		(!conferenceInfo.participants[0].objectId ||
			!Object.values(enums.ObjectTypes).includes(conferenceInfo.participants[0].objectType))
	) {
		return (
			<Popup containerWidth={600} background={LightTheme.colors.blueThree}>
				<h4>{translate('missingInformation')}</h4>
			</Popup>
		);
	}

	return (
		<ConferenceProvider conferenceInfo={conferenceInfo}>
			<ConferenceCtx.Consumer>
				{conferenceData => (
					<>
						{conferenceData && (
							<ConferenceCall
								participantsToCall={conferenceInfo.participants}
								isJoin={location.state?.isJoin ?? !!conferenceInfo.conferenceId}
								refToken={conferenceInfo.refToken}
							/>
						)}
					</>
				)}
			</ConferenceCtx.Consumer>
		</ConferenceProvider>
	);
};

export default conferenceRouteRender;
