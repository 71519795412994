import React, { useEffect, useRef, useState } from 'react';
import moment from 'moment';
import { subDays } from 'date-fns';
import { useIntl } from 'react-intl';
import { registerLocale } from 'react-datepicker';
import sq from 'date-fns/locale/sq/index.js';
import en from 'date-fns/locale/en-US/index.js';
import { getMeasurements, getDailyAveragesByDateRange } from 'api/measurements.js';
import 'react-datepicker/dist/react-datepicker.css';
import EmptyState from 'components/EmptyState.jsx';
import Loader from 'components/Loader.jsx';
import { MeasurementTypes, AverageTypes } from 'constants/enums.js';
import { convertToUnixTimeStamp, formattedDate, getMonthByName } from 'infrastructure/helpers/dateHelper.js';
import { healthCareCdnUrl } from 'constants/global-variables.js';
import HealthDataAverages from 'components/Patients/HealthData/HealthDataAverages.jsx';
import {
	convertSecondsToHoursFormat,
	getPreferredLanguageLocale,
	skipDuplicatedObjects,
	stringToCamelCase,
} from 'infrastructure/helpers/commonHelpers.js';
import {
	convertMeasurementTypes,
	getMeasurementSource,
	getLowestAverage,
	getAverage,
} from 'infrastructure/helpers/measurementsHelper.js';
import translate from 'i18n-translations/translate.jsx';
import Alert from 'components/Alert.jsx';
import { getUserRole } from 'infrastructure/auth.js';
import { UserRoles } from 'calls/enums/index.js';
import DateRange from 'components/DateRange.jsx';
import ManageMeasurements from 'containers/HealthMeasurements/ManageMeasurements.jsx';
import Button from 'components/Button.jsx';
import MeasurementsGraph from 'containers/HealthMeasurements/MeasurementsGraph.jsx';

registerLocale('en-US', en);
registerLocale('sq', sq);

const HealthDataTabContent = props => {
	const today = new Date();
	const fromDefault = subDays(today, 30);
	const toDefault = today;
	const [average, setAverage] = useState(null);
	const [highest, setHighest] = useState(null);
	const [lowest, setLowest] = useState(null);
	const [measurements, setMeasurements] = useState([]);
	const [chartLabels, setChartLabels] = useState([]);
	const [chartData, setChartData] = useState([]);
	const [chartDataSecond, setChartDataSecond] = useState([]);
	const [isLoadingMore, setIsLoadingMore] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [pageIndex, setPageIndex] = useState(0);
	const [showAddMeasurementPopUp, setShowAddMeasurementPopUp] = useState(false);
	const [measurementValue, setMeasurementValue] = useState(null);
	const [measurementValueSecond, setMeasurementValueSecond] = useState(null); // this property is used when the type is bloodpressure since we have two input fields
	const [measurementDate, setMeasurementDate] = useState(new Date());
	const [measurementEndDate, setMeasurementEndDate] = useState(new Date()); // this property is used when the type is sleep or step since we need two inputs with dates\
	const [isDataSourceModalVisible, setIsDataSourceModalVisible] = useState(false);
	const [selectedMeasurement, setSelectedMeasurement] = useState(null);
	const [isDeleteAllDataModalVisible, setIsDeleteAllDataModalVisible] = useState(false);
	const [showAllMeasurements, setShowAllMeasurements] = useState(false);
	const [error, setError] = useState(null);
	const [currentTab, setCurrentTab] = useState(0);
	const [dateRange, setDateRange] = useState({ from: fromDefault, to: toDefault });
	const [avgType, setAvgType] = useState(1);
	const measurementRecentElement = useRef(null);
	const [hasReachedEnd, setHasReachedEnd] = useState(true);
	const intl = useIntl();
	const isBloodPressure = props.measurement.type === MeasurementTypes.BLOOD_PRESSURE;
	const isSleep = props.measurement.type === MeasurementTypes.SLEEP;

	useEffect(() => {
		mapChartDataAndLabels();
	}, [currentTab, dateRange, avgType]);

	useEffect(() => {
		if (showAllMeasurements) {
			getAllMeasurements();
		}
	}, [pageIndex, dateRange]);

	const mapChartDataAndLabels = async () => {
		const params = {
			patientId: props.selectedPatient?.patientId,
			measurementType: props.measurement.type,
			startDate: convertToUnixTimeStamp(new Date(dateRange.from)),
			endDate: convertToUnixTimeStamp(new Date(dateRange.to).setHours(23, 59, 59, 999)),
			averageType: avgType,
		};
		const response = await getDailyAveragesByDateRange(params);
		if (response.error) {
			setError(response.error.message);
			return;
		}
		const { averages, averageType } = response;

		let chartData = [];
		let chartDataSecond = [];
		const chartLabels = averages
			.sort((a, b) => new Date(a.measurementDate).getTime() - new Date(b.measurementDate).getTime())
			.map(measurement => {
				if (averageType === AverageTypes.WEEKLY) {
					return `Week ${measurement.weekNumber}`;
				}
				if (averageType === AverageTypes.MONTHLY) {
					return getMonthByName(measurement.month);
				}
				if (averageType === AverageTypes.QUARTERLY) {
					return `Quarter ${measurement.quarter}, ${measurement.year}`;
				}
				return `Day ${moment(measurement.measurementDate).dayOfYear()}`;
			});
		if (isBloodPressure) {
			chartData = averages
				.sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime())
				.map(measurement => {
					return +measurement.firstPartAvg;
				});

			chartDataSecond = averages
				.sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime())
				.map(measurement => {
					return +measurement.secondPartAvg;
				});
		} else {
			chartData = averages
				.sort((a, b) => new Date(a.measurementDate).getTime() - new Date(b.measurementDate).getTime())
				.map(measurement => {
					return isSleep
						? measurement.firstPartAvg / 60 / 60
						: convertMeasurementTypes(props.measurement.categoryId, measurement.firstPartAvg, props.measurement.unitSystemId);
				});
		}
		if (averages.length > 0) {
			const lowest = getLowestAverage(averages, true);
			const highest = getLowestAverage(averages);
			const average = getAverage(averages);
			setLowest(lowest);
			setHighest(highest);
			setAverage(average);
		}
		setChartLabels(chartLabels);
		setChartData(chartData);
		setChartDataSecond(chartDataSecond);
	};

	const getAllMeasurements = async () => {
		if (isLoadingMore) {
			return;
		}
		setIsLoadingMore(true);
		const start = moment(dateRange.from).format('MM/DD/YYYY');
		const end = moment(dateRange.to).format('MM/DD/YYYY');
		const params = {
			patientId: props.selectedPatient?.userId,
			measurementType: props.measurement.type,
			startDate: start,
			endDate: end,
			pageIndex,
			pageSize: 20,
		};
		const response = await getMeasurements(params);
		let measurementsData = [];
		if (response.error) {
			setIsLoadingMore(false);
			setIsLoading(false);
			setMeasurements(measurements);
			setError(response.error.message);
		} else {
			setHasReachedEnd(response.measurements.length < 20);
			measurementsData = measurements.concat(response.measurements);
			const uniqueArray = skipDuplicatedObjects(measurementsData, 'id');
			uniqueArray.sort((a, b) => (+new Date(b.startDate) > +new Date(a.startDate) ? 1 : -1));
			setMeasurements(measurementsData);
			setIsLoadingMore(false);
			setIsLoading(false);
		}
	};

	const handleSelect = index => {
		setCurrentTab(index);
		setAvgType(index + 1);
		setPageIndex(0);
	};
	const handleRangeChange = range => {
		setDateRange(range);
		setPageIndex(0);
		setMeasurements([]);
	};

	const downloadCSV = () => {
		let csvContent = 'data:text/csv;charset=utf-8,\uFEFF,';
		measurements.forEach(measurement => {
			const date = moment.utc(measurement.startDate).local().locale(getPreferredLanguageLocale()).format('YYYY-MM-DD HH:mm:ss A');
			const hasDeviceContent = measurement.device ? measurement.device.type : intl.formatMessage({ id: 'unknown' });
			const deviceType = measurement.isManuallyInserted ? intl.formatMessage({ id: 'manuallyAdded' }) : hasDeviceContent;
			const patientName = props.selectedPatient?.firstName
				? `${props.selectedPatient?.firstName} ${props.selectedPatient?.lastName}`
				: props.selectedPatient?.fullName;
			const unit = measurement.measurementUnit === '' ? intl.formatMessage({ id: 'noData' }) : measurement.measurementUnit;
			const row = `${intl.formatMessage({ id: 'patientName' })}: ${patientName}, ${intl.formatMessage({
				id: 'type',
			})}: ${deviceType}, ${intl.formatMessage({ id: 'unit' })}: ${unit},
			${intl.formatMessage({ id: 'value' })}: ${measurement.measurementValue}, ${intl.formatMessage({
				id: 'measuredAt',
			})}: ${date}`;
			csvContent += `${row}\r\n`;
		});
		const encodedUri = encodeURI(csvContent);
		const link = document.createElement('a');
		link.setAttribute('href', encodedUri);
		link.setAttribute('download', `${props.measurement.link}_data.csv`);
		document.body.appendChild(link);
		link.click();
	};

	const handleScroll = e => {
		const isBottom = e.target.scrollHeight - Math.ceil(e.target.scrollTop) === e.target.clientHeight;
		if (isBottom && !hasReachedEnd) {
			setPageIndex(prevState => prevState + 1);
		}
	};

	const toggleAddMeasurementModal = () => {
		setShowAddMeasurementPopUp(prevState => !prevState);
		setMeasurementValue('');
		setMeasurementValueSecond('');
		setMeasurementDate(new Date());
		setMeasurementEndDate(new Date());
	};

	const toggleDataSourceModal = measurement => {
		setSelectedMeasurement(measurement);
		setIsDataSourceModalVisible(prevState => !prevState);
	};

	const showDeleteAllDataModal = () => {
		setIsDeleteAllDataModalVisible(prevState => !prevState);
	};

	return (
		<>
			{!props.isFromVitalSigns && (
				<div className='patient-iot-history-data' onScroll={handleScroll} ref={measurementRecentElement}>
					<div className='patient-iot-history-title flex health-data-content'>
						<div>
							<h2>{props.measurement.link}</h2>
							<p>
								<img src={`${healthCareCdnUrl}add-data-manually.svg`} alt='add data' />
								<Button onClick={toggleAddMeasurementModal} text={translate('addData')} />
							</p>
						</div>
						<div className='flex patient-iot-start-end-date'>
							<DateRange
								startDate={dateRange.from}
								endDate={dateRange.to}
								handleRangeChange={handleRangeChange}
								maxDays={365}
								currentTab={currentTab}
								handleSelect={handleSelect}
								isHealthData={true}
								isCallView={props.isCallView}
							/>
						</div>
						{measurements.length > 0 && (
							<div className='flex download-csv'>
								<span onClick={downloadCSV} data-tooltip={intl.formatMessage({ id: 'downloadCSV' })} data-position='bottom'>
									<img className='cursor-pointer' src={`${healthCareCdnUrl}health-data/download-csv.svg`} alt='icon' />
								</span>
							</div>
						)}
					</div>
					{chartData.length > 0 && (
						<>
							<MeasurementsGraph
								chartData={chartData}
								chartDataSecond={chartDataSecond}
								chartLabels={chartLabels}
								measurement={props.measurement}
							/>
							<HealthDataAverages lowest={lowest} average={average} highest={highest} measurement={props.measurement} />
							<div className='full-width flex flex-justify-center'>
								{!showAllMeasurements && (
									<Button
										onClick={() => {
											setPageIndex(0);
											getAllMeasurements();
											setShowAllMeasurements(true);
										}}
										className='view-all-measurements-btn'
										text={translate('viewAll')}
									/>
								)}
							</div>
							{showAllMeasurements && chartData.length > 0 && (
								<div className='patient-iot-history-recent'>
									<div className='flex patient-iot-history-delete'>
										<h2>{translate('allRecordedData')}</h2>
										{getUserRole() === UserRoles.PATIENT && (
											<span className='cursor-pointer flex' onClick={showDeleteAllDataModal}>
												<i className='material-icons'>close</i>
												{translate('deleteAllDataRecords')}
											</span>
										)}
									</div>
									{measurements.length > 0 &&
										measurements.map(measurement => (
											<div
												key={measurement.id}
												className='patient-iot-history-recent-item cursor-pointer flex'
												data-cy='measurementList'
												onClick={() => toggleDataSourceModal(measurement)}>
												<div>
													<div className='flex'>
														<p>
															{measurement.measurementType === MeasurementTypes.SLEEP &&
																convertSecondsToHoursFormat(measurement.measurementValue)}

															{measurement.measurementType !== MeasurementTypes.SLEEP &&
																convertMeasurementTypes(
																	props.measurement.categoryId,
																	measurement.measurementValue,
																	props.measurement.unitSystemId
																)}

															<span>
																{' '}
																{measurement.measurementType !== MeasurementTypes.SLEEP &&
																	props.measurement.unit &&
																	intl.formatMessage({ id: stringToCamelCase(props.measurement.unit) }).toLowerCase()}
															</span>
														</p>
													</div>
													{getMeasurementSource(measurement, intl)}
												</div>
												<div className='patient-iot-item-source'>
													<div>
														<img src={`${healthCareCdnUrl}health-data/info.svg`} alt='icon' />
													</div>
													<div>
														<span>{formattedDate(measurement.startDate)}</span>
													</div>
												</div>
											</div>
										))}
									{!hasReachedEnd && isLoadingMore && (
										<div className='full-width text-align-center'>
											<Loader />
										</div>
									)}
								</div>
							)}
						</>
					)}
					{isLoading && (
						<div className='full-width text-align-center'>
							<Loader />
						</div>
					)}
					{chartData.length === 0 && (
						<div className='empty-state-wrapper-percent'>
							<EmptyState title={translate('noDataFound')} image='health-data.svg' paragraph={translate('tryOtherDateRange')} />
						</div>
					)}
					<ManageMeasurements
						showAddMeasurementPopUp={showAddMeasurementPopUp}
						measurementValue={measurementValue}
						measurementValueSecond={measurementValueSecond}
						measurementEndDate={measurementEndDate}
						isDataSourceModalVisible={isDataSourceModalVisible}
						selectedMeasurement={selectedMeasurement}
						isDeleteAllDataModalVisible={isDeleteAllDataModalVisible}
						setMeasurementValueSecond={setMeasurementValueSecond}
						setMeasurementValue={setMeasurementValue}
						isBloodPressure={isBloodPressure}
						isSleep={isSleep}
						toggleAddMeasurementModal={toggleAddMeasurementModal}
						measurementDate={measurementDate}
						setIsDataSourceModalVisible={setIsDataSourceModalVisible}
						setMeasurements={setMeasurements}
						setIsDeleteAllDataModalVisible={setIsDeleteAllDataModalVisible}
						setMeasurementEndDate={setMeasurementEndDate}
						setMeasurementDate={setMeasurementDate}
						showDeleteAllDataModal={showDeleteAllDataModal}
						toggleDataSourceModal={toggleDataSourceModal}
						measurements={measurements}
						dateRange={dateRange}
						measurement={props.measurement}
						selectedPatient={props.selectedPatient}
						mapChartDataAndLabels={mapChartDataAndLabels}
						showAllMeasurements={showAllMeasurements}
					/>
				</div>
			)}
			<Alert display={error} fixed={true} hideCloseButton={true} message={error} variant='dark' />
		</>
	);
};

export default HealthDataTabContent;
