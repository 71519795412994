import Button from 'components/Button.jsx';
import CustomTable from 'components/CustomTable.jsx';
import Form from 'components/Form.jsx';
import Input from 'components/Input.jsx';
import Modal from 'components/Modal.jsx';
import Select from 'react-select';
import { Formik } from 'formik';
import { useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import MainLayout from 'views/Layouts/MainLayout.jsx';
import Badge from 'components/Badge.jsx';
import TreeViewMayo from 'components/TreeViewMayo.jsx';
import { findSectorById, searchSectors, showPath } from 'infrastructure/helpers/commonHelpers.js';
import { getAssignedDevices, getUnassociatedDevices, assignDevice, unassignDevice } from 'api/nurseStation.js';
import Pagination from 'components/Common/Pagination.jsx';
import translate from 'i18n-translations/translate.jsx';
import Alert from 'components/Alert.jsx';
import TableDeleteButton from 'icons/Admin/TableDeleteButton.jsx';
import * as Yup from 'yup';
import classNames from 'classnames';
import TableEditButton from 'icons/Admin/TableEditButton.jsx';
import ManageNurseStation from 'views/NurseStationTablets/ManageNurseStation.jsx';

const NurseStation = () => {
	const healthSystems = useSelector(state => state.healthSystems);
	const currentHealthSystem = useSelector(state => state.user.userSession.healthSystem);
	const user = useSelector(state => state.user);
	const intl = useIntl();
	const [searchQuery, setSearchQuery] = useState('');
	const [isAddDeviceModalOpen, setIsAddDeviceModalOpen] = useState(false);
	const [alertFeeds, setAlertFeeds] = useState([]);
	const [unassociatedDevicesToSelect, setUnassociatedDevicesToSelect] = useState([]);
	const [assignedDevices, setAssignedDevices] = useState([]);
	const [pagination, setPagination] = useState({ pageSize: 10, pageIndex: 0, totalCount: 0 });
	const [isLoading, setIsLoading] = useState(false);
	const [isAssignLoading, setIsAssignLoading] = useState(false);
	const [expandAllTreeItems, setExpandAllTreeItems] = useState(false);
	const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
	const [isEditModalOpen, setIsEditModalOpen] = useState(false);
	const [isBtnLoading, setIsBtnLoading] = useState(false);
	const [currentNSTObj, setCurrentNSTObj] = useState(null);
	const [currentNSTEditObj, setCurrentNSTEditObj] = useState(null);
	const delayTimerRef = useRef(null);
	const delayTreeTimerRef = useRef(null);
	const roomsErrorRef = useRef(null);
	const [error, setError] = useState(null);
    const maximumFeedLength = 32;

	const initialValues = {
		name: '',
		searchTree: '',
		selectDevice: null,
	};

	useEffect(() => {
		const fetchDevices = async () => {
			setIsLoading(true);
			const assignedDevices = await getAssignedDevices(
				currentHealthSystem.id,
				pagination.pageSize,
				pagination.pageIndex,
				searchQuery
			);
			if (assignedDevices.error) {
				setError(translate('somethingWentWrong'));
			} else {
				setAssignedDevices(assignedDevices?.data);
				setPagination(prevState => ({ ...prevState, totalCount: assignedDevices.totalRows || 0 }));
			}
			setIsLoading(false);
		};
		fetchDevices();
		return () => {
			clearTimeout(delayTimerRef.current);
		};
	}, [searchQuery, pagination.pageSize, pagination.pageIndex]);

	const nurseStationTableHeaders = [
		{ title: intl.formatMessage({ id: 'tabletName' }), id: 'tabletName' },
		{ title: intl.formatMessage({ id: 'rooms' }), id: 'rooms' },
		{ title: intl.formatMessage({ id: 'serialNumber' }), id: 'serialNumber' },
		{ title: intl.formatMessage({ id: 'status' }), id: 'status' },
		{ title: '', id: 'actions' },
	];

	const getMappedFeed = deviceId => {
		const sector = findSectorById(healthSystems.treeData.tree, deviceId);
		if (!sector || alertFeeds.some(feed => feed.deviceId === deviceId)) {
			return null;
		}
		const { roomId, hospitalId, departmentId, floorId, name, aiPrivacyStatus } = sector;
		return {
			deviceId,
			roomName: name,
			roomId,
			hospitalId,
			departmentId,
			floorId,
			deviceOwner: {},
			aiPrivacyStatus,
			hospitalName: name,
			lastCondition: { code: '', display: '', risk: '' },
			alertInfo: {},
			healthSystemId: user.userSession.healthSystem.id,
			intervention: {},
		};
	};

	const onAddDevice = async selection => {
		if (alertFeeds.length < maximumFeedLength) {
			const { helloDeviceId } = selection;
			const selectedFeed = alertFeeds.find(item => item.helloDeviceId === helloDeviceId);
			if (selectedFeed) {
				return;
			}
			const feed = getMappedFeed(helloDeviceId);
			setAlertFeeds(prevState => {
				const alreadyExists = prevState.some(existingFeed => existingFeed.deviceId === feed.deviceId);

				if (alreadyExists) {
					return prevState;
				}
				const newState = [...prevState, feed];

				if (newState.length >= 32) {
					roomsErrorRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
				}

				return newState;
			});
		}
	};

	useEffect(() => {
		const fetchUnassociatedDevices = async () => {
			const unassociatedDevicesRes = await getUnassociatedDevices();
			if (unassociatedDevicesRes.error) {
				setError(translate('somethingWentWrong'));
			} else {
				const buildDevices = unassociatedDevicesRes.devices.map(item => ({ label: item.uid, value: item.solHelloDeviceId }));
				setUnassociatedDevicesToSelect(buildDevices);
			}
		};
		fetchUnassociatedDevices();
	}, [isAddDeviceModalOpen]);

	const onSubmit = async (values, { resetForm }) => {
		setIsAssignLoading(true);
		const { value: deviceId } = values.selectDevice;
		const { name } = values;
		const payload = {
			deviceName: name,
			subscribedDevices: alertFeeds.map(feed => ({
				deviceId: feed.deviceId,
				roomId: feed.roomId,
			})),
		};
		const assignDeviceResponse = await assignDevice(currentHealthSystem.id, deviceId, payload);

		if (assignDeviceResponse.error) {
			setError(translate('somethingWentWrong'));
			return;
		}
		setIsAssignLoading(false);
		setIsAddDeviceModalOpen(false);
		const assignedDevices = await getAssignedDevices(currentHealthSystem.id, pagination?.pageSize, pagination?.pageIndex);
		if (assignedDevices.error) {
			setError(translate('somethingWentWrong'));
			return;
		}
		setAssignedDevices(assignedDevices.data);
		resetForm({ values: initialValues });
	};

	const handleSearch = e => {
		setIsLoading(true);
		clearTimeout(delayTimerRef.current);
		const timer = setTimeout(() => {
			setSearchQuery(e.target.value);
		}, 500);
		delayTimerRef.current = timer;
	};

	const handleTreeSearch = (e, values) => {
		const { value } = e.target;
		clearTimeout(delayTreeTimerRef.current);
		delayTreeTimerRef.current = setTimeout(() => {
			values.searchTree = value;

			if (value.length > 1) {
				const sectors = searchSectors(healthSystems.treeData.tree, value);
				showPath(healthSystems.treeData.tree, sectors);
				setExpandAllTreeItems(true);
			} else {
				searchSectors(healthSystems.treeData.tree, '');
				setExpandAllTreeItems(false);
			}
		}, 500);
	};
	const deviceRows = assignedDevices
		?.filter(
			device =>
				device.deviceName.toLowerCase().includes(searchQuery.toLowerCase()) ||
				device.serialNumber.toLowerCase().includes(searchQuery.toLowerCase())
		)
		.map(device => ({
			id: device.deviceId,
			tabletName: device.deviceName,
			rooms: device.numberOfRooms,
			serialNumber: device.serialNumber,
			status: <Badge text={translate(device.isOnline ? 'online' : 'offline')} variant={device.isOnline ? 'green' : 'gray'} />,
			actions: (
				<div className='wrapped'>
					<TableEditButton onClick={() => openEditNSTModal(device)} />
					<TableDeleteButton onClick={() => openUnassignNSTModal(device)} />
				</div>
			),
		}));

	const openUnassignNSTModal = device => {
		setCurrentNSTObj(device);
		setIsDeleteModalOpen(true);
	};

	const openEditNSTModal = device => {
		setCurrentNSTEditObj(device);
		setIsEditModalOpen(true);
	};

	const handleUnassignNST = async () => {
		setIsLoading(true);
		setIsBtnLoading(true);
		const unassignedDevice = await unassignDevice(currentNSTObj.deviceId);
		if (unassignedDevice.error) {
			setError(translate('somethingWentWrong'));
		} else {
			setAssignedDevices(prevDevices => prevDevices.filter(device => device.deviceId !== currentNSTObj.deviceId));
			setIsDeleteModalOpen(false);
		}
		setIsLoading(false);
		setIsBtnLoading(false);
		setCurrentNSTObj(null);
	};

	const validationSchema = Yup.object().shape({
		selectDevice: Yup.object()
			.shape({
				label: Yup.string().required(intl.formatMessage({ id: 'mustSelectDevice' })),
				value: Yup.string().required(intl.formatMessage({ id: 'mustSelectDevice' })),
			})
			.required(intl.formatMessage({ id: 'mustSelectDevice' })),
		name: Yup.string().required(intl.formatMessage({ id: 'mustSetName' })),
	});

	return (
		<MainLayout>
			<div className='nurse-station-table-wrapper'>
				<div>
					<h3>{translate('nurseStation')}</h3>
				</div>
				<CustomTable isLoading={isLoading} rows={!isLoading ? deviceRows : []} headers={nurseStationTableHeaders}>
					<div className='flex'>
						<input type='text' placeholder={intl.formatMessage({ id: 'searchByNameOrSn' })} onChange={handleSearch} />
						<Button
							className='button white cursor-pointer'
							icon='compare_arrows'
							text={translate('assignNurseStation')}
							onClick={() => setIsAddDeviceModalOpen(!isAddDeviceModalOpen)}
						/>
					</div>
					<Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={validationSchema}>
						{formikProps => {
							const { values, errors, touched, handleChange, setFieldValue, resetForm, handleSubmit } = formikProps;
							return (
								<Modal
									className='wrapper-modal border-radius-modal-wrapper appoinment-next-arrow-modal'
									display={isAddDeviceModalOpen}
									position='right'
									isLoading={isAssignLoading}
									onModalClose={() => {
										resetForm({ values: initialValues });
										setAlertFeeds([]);
										setIsAddDeviceModalOpen(false);
									}}
									onModalSubmit={handleSubmit}>
									<Form>
										<h3>{translate('assignNewDevice')}</h3>
										<div className='input'>
											<p className='label'>{translate('selectDevice')}</p>
											<Select
												name='selectDevice'
												value={values.selectDevice}
												onChange={event => setFieldValue('selectDevice', event)}
												options={unassociatedDevicesToSelect}
											/>
											{errors.selectDevice && touched.selectDevice && <p className='error'>{errors.selectDevice}</p>}
										</div>
										<div
											className={classNames('input', {
												disabled: !values.selectDevice,
											})}>
											<Input
												type='text'
												label={translate('deviceName')}
												name='name'
												value={values.name}
												placeholder={intl.formatMessage({ id: 'deviceName' })}
												onChange={handleChange}
												validationOptions={{}}
												bottomSpace='20px'
											/>
											{errors.name && touched.name && <p className='error'>{errors.name}</p>}
										</div>

										<div className={values.selectDevice ? '' : 'disabled'}>
											<Input
												className='tree-search'
												type='text'
												name='searchTree'
												label={translate('roomsWhichWillBeMonitored')}
												placeholder={intl.formatMessage({ id: 'search' })}
												value={values.searchTree}
												onChange={e => {
													handleChange(e);
													handleTreeSearch(e, values);
												}}
												validationOptions={{}}
												bottomSpace='20px'
												autoComplete='off'
											/>
											<div ref={roomsErrorRef}>
												{alertFeeds.length >= 32 && <p className='error'>{intl.formatMessage({ id: 'roomAmountError' })}</p>}
											</div>
											<TreeViewMayo
												data={healthSystems.treeData.tree}
												isNurseStation={true}
												onAddDevice={onAddDevice}
												alertFeeds={alertFeeds}
												expandAll={expandAllTreeItems}
											/>
										</div>
									</Form>
								</Modal>
							);
						}}
					</Formik>
				</CustomTable>
				<Modal
					display={isDeleteModalOpen}
					position='center'
					className='wrapper-modal border-radius-modal-wrapper appoinment-next-arrow-modal admin-delete-modal'
					primaryButtonLabel={translate('unassign')}
					onModalSubmit={handleUnassignNST}
					primaryButtonLoading={isBtnLoading}
					onModalClose={() => {
						setCurrentNSTObj(null);
						setIsDeleteModalOpen(false);
					}}>
					<form>
						<h3>{translate('unassignNST')}</h3>
						<p>{translate('unassignNSTConfirmation')}</p>
					</form>
				</Modal>
				{!isLoading && (
					<Pagination
						totalCount={pagination.totalCount}
						pageSize={pagination.pageSize}
						pageIndex={pagination.pageIndex}
						onChange={(pageSize, pageIndex) => {
							setIsLoading(true);
							setPagination(prevState => ({ ...prevState, pageSize, pageIndex }));
						}}
					/>
				)}
				{currentNSTEditObj && (
					<ManageNurseStation
						initialValues={currentNSTEditObj}
						onSubmit={onSubmit}
						setIsEditModalOpen={setIsEditModalOpen}
						isEditModalOpen={isEditModalOpen}
						setAlertFeeds={setAlertFeeds}
						handleTreeSearch={handleTreeSearch}
						alertFeeds={alertFeeds}
						expandAllTreeItems={expandAllTreeItems}
						data={healthSystems.treeData.tree}
						isLoading={isLoading}
						setIsLoading={setIsLoading}
						pagination={pagination}
						setAssignedDevices={setAssignedDevices}
					/>
				)}
			</div>
			<Alert display={error} message={error} variant='dark' fixed={true} onClose={() => setError(null)} />
		</MainLayout>
	);
};

export default NurseStation;
