import classNames from 'classnames';

const Button = props => (
	<button
		ref={props.buttonRef}
		key={props.key}
		type={props.type ? props.type : 'button'}
		disabled={props.isDisabled || props.disabled}
		className={classNames('button', props.className, props.variant, props.size, props.display, props.isLoading ? 'loading' : '')}
		onClick={props.onClick}
		onBlur={props.onBlur}
		onMouseDown={props.onMouseDown}
		onMouseUp={props.onMouseUp}
		onMouseOut={props.onMouseOut}
		data-position={props.position}
		data-tooltip={props.tooltip}
		data-active={props.active}
		style={{
			justifySelf: props.horizAlign,
			backgroundColor: props.background,
			borderColor: props.background || props.borderColor,
			borderRadius: props.borderRadius,
			marginRight: props.marginRight,
			marginLeft: props.marginLeft,
			marginTop: props.marginTop,
			marginBottom: props.marginBottom,
			color: props.color,
			display: props.display,
			border: props.border || (!props.text ? 'none' : ''),
		}}>
		{props.imgIcon && <img className={!props.text ? 'no-margin' : ''} src={props.imgIcon} alt={props.alt || 'icon'} />}
		{!props.imgIcon && props.icon && (
			<i style={{ fontSize: props.iconSize }} className={classNames('material-icons-outlined', !props.text ? 'no-margin' : '')}>
				{props.icon}
			</i>
		)}
		{props.svgIcon && props.svgIcon}
		{props.text}
		{props.nextStepIcon && <img src={props.nextStepIcon} alt={props.alt || 'icon'} />}
	</button>
);

export default Button;
