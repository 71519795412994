import { getAuditLogs, getLogTypes } from 'api/auditLogs.js';
import { UserRoles } from 'constants/enums.js';
import Alert from 'components/Alert.jsx';
import AuditLogsDetails from 'components/AuditLogs/AuditLogsDetails.jsx';
import Pagination from 'components/Common/Pagination.jsx';
import CustomTable from 'components/CustomTable.jsx';
import Grid from 'components/Grid.jsx';
import { AdminsLogTypes, LanguageTypes } from 'constants/configurationEnums.js';
import { ObjectType } from 'constants/enums.js';
import AuditLogsFilters from 'containers/AuditLogsFilters.jsx';
import { Formik } from 'formik';
import translate from 'i18n-translations/translate.jsx';
import { getCompanyId, getUserRole } from 'infrastructure/auth.js';
import {
	capitalizeLetterAndAddSpaces,
	getPreferredLanguageLocale,
	getRoleValueById,
	getUserTypeBasedOnCompanyRole,
	isObjectValue,
	isValidJSON,
} from 'infrastructure/helpers/commonHelpers.js';
import _ from 'lodash';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import MainLayout from 'views/Layouts/MainLayout.jsx';

const DisplayEnums = {
	1: 'portrait',
	2: 'landscape',
	3: 'portraitReverse',
	mainDisplay: 'mainDisplay',
	secondDisplay: 'secondDisplay',
};

const PrivacyEnums = {
	1: 'monitoring',
	2: 'alexa',
	3: 'wakeword',
	true: 'enabled',
	false: 'disabled',
	source: 'source',
	active: 'active',
};

const AuditLogsEnums = {
	language: 'Language',
	display: 'Display',
	...PrivacyEnums,
	...DisplayEnums,
};

const AuditLogs = () => {
	const intl = useIntl();
	const companySettings = useSelector(state => state.company.companySettings);
	const locale = useSelector(state => state.language.locale);
	const [logs, setLogs] = useState([]);
	const [logTypes, setLogTypes] = useState([]);
	const [error, setError] = useState(null);
	const [isLoading, setIsLoading] = useState(false);
	const [pagination, setPagination] = useState({ pageSize: 10, pageIndex: 0, totalCount: 0 });
	const [isViewDetailsModal, setIsViewDetailsModal] = useState(false);
	const [selectedMetaData, setSelectedMetaData] = useState(null);
	const [filtersParams, setFiltersParams] = useState({});
	const isClientAuditLog = UserRoles.ADMIN === getUserRole();

	const tableHeaders = [
		{ title: translate('client'), id: 'client', showColumn: !isClientAuditLog },
		{ title: translate('companyId'), id: 'tenantId', showColumn: !isClientAuditLog },
		{ title: translate('logType'), id: 'logType', showColumn: true },
		{ title: translate('inputter'), id: 'inputter', showColumn: true },
		{ title: translate('inputterType'), id: 'objectType', showColumn: !isClientAuditLog },
		{ title: translate('inputterId'), id: 'objectId', showColumn: true },
		{ title: translate('email'), id: 'email', showColumn: true, columnClass: 'break-word' },
		{ title: translate('sector'), id: 'sector', showColumn: isClientAuditLog },
		{ title: translate('featureChanged'), id: 'featureChanged', showColumn: isClientAuditLog, columnClass: 'break-word' },
		{ title: translate('variant'), id: 'variant', showColumn: isClientAuditLog },
		{ title: translate('changedForRole'), id: 'changedForRole', showColumn: isClientAuditLog },
		{ title: translate('sourceOfInput'), id: 'sourceOfInput', showColumn: !isClientAuditLog },
		{ title: translate('subjectName'), id: 'actioneeName', showColumn: !isClientAuditLog },
		{ title: translate('subjectId'), id: 'actioneeObjectId', showColumn: !isClientAuditLog },
		{ title: translate('subjectType'), id: 'actioneeObjectType', showColumn: !isClientAuditLog },
		{ title: translate('source'), id: 'source', showColumn: !isClientAuditLog },
		{ title: translate('creationDate'), id: 'creationDate', showColumn: true },
		{ title: '', id: 'details', showColumn: !isClientAuditLog },
	];

	useEffect(() => {
		getAuditLogsList();
	}, []);

	const getInitialValues = () => ({
		clientId: null,
		logTypeId: isClientAuditLog
			? {
					value: AdminsLogTypes.HEALTH_SYSTEM_SETTINGS.value,
					label: transformLogName(AdminsLogTypes.HEALTH_SYSTEM_SETTINGS.label),
				}
			: null,
		objectType: null,
		firstName: '',
		lastName: '',
		objectId: '',
		email: '',
		platformId: null,
		actioneeObjectId: '',
		actioneeObjectType: null,
		source: '',
		tenantId: '',
		fromDate: null,
		toDate: null,
		pageSize: pagination.pageSize,
		pageIndex: pagination.pageIndex,
	});

	const getAuditLogsList = async () => {
		setIsLoading(true);
		let logTypesData;
		const fetchLogTypes = async () => {
			const auditLogTypesResponse = await getLogTypes();
			if (auditLogTypesResponse.error) {
				setError(auditLogTypesResponse.message);
				return;
			}
			if (isClientAuditLog) {
				logTypesData = auditLogTypesResponse.logTypes.filter(item =>
					Object.values(AdminsLogTypes).some(log => log.label === item.name)
				);
				setFiltersParams({
					logTypeId: logTypesData.find(item => item.name === AdminsLogTypes.HEALTH_SYSTEM_SETTINGS.label)?.id,
				});
			} else {
				logTypesData = auditLogTypesResponse.logTypes;
			}

			setLogTypes(logTypesData);
		};

		const fetchLogs = async () => {
			const params = {
				pageSize: 10,
				pageIndex: 0,
				...(isClientAuditLog && {
					logTypeId: logTypesData?.find(item => item.name === AdminsLogTypes.HEALTH_SYSTEM_SETTINGS.label)?.id,
					tenantId: getCompanyId(),
				}),
			};
			const auditLogsResponse = await getAuditLogs(params);
			if (auditLogsResponse.error) {
				setError(auditLogsResponse.message);
				return;
			}
			setPagination(prevState => ({
				...prevState,
				pageIndex: auditLogsResponse.pageIndex,
				totalCount: auditLogsResponse.totalCount,
			}));
			setLogs(auditLogsResponse.logs);
		};

		await fetchLogTypes();
		fetchLogs();
		setIsLoading(false);
	};

	const openDetails = details => {
		setIsViewDetailsModal(true);
		setSelectedMetaData(details);
	};

	const transformLogName = logType => capitalizeLetterAndAddSpaces(logType).replace(/\./g, ' ');

	const buildFeatureChangedText = newSetting => {
		const configName = newSetting?.SettingType;
		const formattedConfigName = configName ? intl.formatMessage({ id: _.camelCase(configName) }) : 'N/A';

		const getLogEnums = log => {
			if (!!PrivacyEnums[log.label]) {
				return PrivacyEnums[log.value];
			} else if (!!DisplayEnums[log.label]) {
				return DisplayEnums[log.value];
			}
			return AuditLogsEnums[log.label];
		};

		const getEnabledDisabledText = value => {
			const status = value === 'true' ? 'enabled' : 'disabled';
			return `${formattedConfigName}: ${intl.formatMessage({ id: status })} `;
		};

		const getLanguageText = value => {
			const language = LanguageTypes[value] ?? value;
			return `${formattedConfigName}: ${intl.formatMessage({ id: language })} `;
		};

		const getJsonTransformedText = value => {
			const jsonValue = JSON.parse(value);
			const transformedSettings = Object.keys(jsonValue).reduce((acc, key) => {
				const jsonPropValue = jsonValue[key];
				if (jsonPropValue !== null && jsonPropValue !== '' && !isObjectValue(jsonPropValue)) {
					acc.push({ label: key, value: jsonValue[key] });
				}
				return acc;
			}, []);
			return transformedSettings.map(item => {
				const hasValue = Object.values(AuditLogsEnums).includes(item.label);
				const itemValue = hasValue ? intl.formatMessage({ id: getLogEnums(item) }) : item.value;
				return `${intl.formatMessage({ id: item.label })} : ${itemValue} `;
			});
		};

		if (newSetting?.Value === 'true' || newSetting?.Value === 'false') {
			return getEnabledDisabledText(newSetting.Value);
		}
		if (configName === AuditLogsEnums.language) {
			return getLanguageText(newSetting?.Value);
		}
		if (isValidJSON(newSetting?.Value)) {
			return getJsonTransformedText(newSetting?.Value);
		}
		return `${formattedConfigName} : ${newSetting?.Value}`;
	};

	const getRoleName = roleId => (roleId ? getRoleValueById(getUserTypeBasedOnCompanyRole(roleId), companySettings) : 'N/A');

	const getTableRows = () =>
		logs.map(log => {
			const changeDetails = log.details && isValidJSON(log.details) ? JSON.parse(log.details) : {};
			let newSettings;
			if (changeDetails.NewSettings) {
				newSettings = changeDetails.NewSettings[0] ?? changeDetails.NewSettings;
			}

			return {
				id: log.creationDate + log.logType + log.details,
				...(!isClientAuditLog && { client: log.client || 'N/A', tenantId: log.tenantId || 'N/A' }),
				logType: <div className='font-13'>{transformLogName(log.logType) || 'N/A'}</div>,
				inputter: log.inputter || 'N/A',
				...(!isClientAuditLog && { objectType: intl.formatMessage({ id: getObjectName(log.objectType) }) || 'N/A' }),
				objectId: log.objectId || 'N/A',
				email: log.email || 'N/A',
				...(isClientAuditLog && {
					sector: changeDetails?.Name || 'N/A',
					featureChanged: buildFeatureChangedText(newSettings),
					variant: newSettings?.Variant ? _.startCase(newSettings.Variant) : 'N/A',
					changedForRole: getRoleName(newSettings?.RoleId),
				}),
				...(!isClientAuditLog && {
					sourceOfInput: log.sourceOfInput || 'N/A',
					actioneeName: log.actioneeName || 'N/A',
					actioneeObjectId: log.actioneeObjectId || 'N/A',
					actioneeObjectType: intl.formatMessage({ id: getObjectName(log.actioneeObjectType) }) || 'N/A',
					source: log.source || 'N/A',
				}),
				creationDate: (
					<div className='font-13'>
						<span className='block'>
							{moment.utc(log.creationDate).local().locale(getPreferredLanguageLocale()).format('MM/DD/YYYY-hh:mm A')}
						</span>
					</div>
				),
				...(log.details &&
					!isClientAuditLog && {
						details: (
							<div className='badge blue audit-logs-view-details' onClick={() => openDetails(log.details)}>
								{translate('viewDetails')}
							</div>
						),
					}),
			};
		});

	const getFilteredLogs = async values => {
		setIsLoading(true);
		const params = {
			...(values.clientId?.value && { clientId: values.clientId?.value }),
			logTypeId: values.logTypeId?.value ?? null,
			objectType: values.objectType?.value,
			...(values.firstName && { firstName: values.firstName }),
			...(values.lastName && { lastName: values.lastName }),
			...(values.email && { email: values.email }),
			...(values.objectId && { objectId: values.objectId }),
			...(values.platformId?.value && { platformId: values.platformId?.value }),
			...(values.actioneeObjectId && { actioneeObjectId: values.actioneeObjectId }),
			...(values.actioneeObjectType?.value && { actioneeObjectType: values.actioneeObjectType?.value }),
			...(values.source && { source: values.source }),
			tenantId: isClientAuditLog ? getCompanyId() : (values.tenantId ?? null),
			fromDate: values.fromDate ?? null,
			toDate: values.toDate ?? null,
			pageSize: pagination.pageSize,
			pageIndex: pagination.pageIndex,
		};
		setFiltersParams(params);
		const auditLogsRes = await getAuditLogs(params);
		if (auditLogsRes.error) {
			setError(auditLogsRes.error.message);
			return;
		}
		setIsLoading(false);
		setLogs(auditLogsRes.logs);
		setPagination(prevState => ({
			...prevState,
			pageIndex: pagination.pageIndex,
			totalCount: auditLogsRes.totalCount,
		}));
	};

	const onChange = async (pageSize, pageIndex) => {
		setIsLoading(true);
		const auditLogsRes = await getAuditLogs({
			...filtersParams,
			pageSize,
			pageIndex,
			...(isClientAuditLog && {
				tenantId: getCompanyId(),
			}),
		});
		if (auditLogsRes.error) {
			setError(auditLogsRes.error.message);
			setIsLoading(false);
			return;
		}
		setIsLoading(false);
		setLogs(auditLogsRes.logs);
		setPagination(prevState => ({ ...prevState, pageIndex, pageSize, totalCount: auditLogsRes.totalCount }));
	};

	const getObjectName = objectType => {
		switch (objectType) {
			case ObjectType.USER: {
				return 'user';
			}
			case ObjectType.HELLO_DEVICE: {
				return 'helloDevice';
			}
			case ObjectType.SYSTEM: {
				return 'systemExecutedActions';
			}
			default:
				return 'na';
		}
	};

	return (
		<MainLayout>
			<div className='call-logs-view'>
				<Grid columns='0 3fr' stretch='100%'>
					<aside className='hello-list' />
					<main className='main-view'>
						<section>
							<div className='full-width'>
								<h3>{translate('auditLogs')}</h3>
								<CustomTable
									isLoading={isLoading}
									headers={tableHeaders.filter(item => item.showColumn)}
									rows={isLoading ? [] : getTableRows()}
									tableEmptyText={translate('noAuditLogs')}
									className='margin-top-l'>
									<Formik initialValues={getInitialValues()} onSubmit={values => getFilteredLogs(values)}>
										{formikProps => {
											const { values, handleChange, handleSubmit, setFieldValue, resetForm } = formikProps;
											return (
												<AuditLogsFilters
													logTypes={logTypes}
													values={values}
													handleChange={handleChange}
													setFieldValue={setFieldValue}
													handleSubmit={handleSubmit}
													resetForm={() => {
														getAuditLogsList();
														setFiltersParams({});
														resetForm();
													}}
													locale={locale}
													transformLogName={transformLogName}
												/>
											);
										}}
									</Formik>
								</CustomTable>
								{isViewDetailsModal && (
									<AuditLogsDetails
										selectedMetaData={selectedMetaData}
										isViewDetailsModal={isViewDetailsModal}
										closeDetailsModal={() => setIsViewDetailsModal(false)}
									/>
								)}
								<Pagination
									totalCount={pagination.totalCount}
									pageSize={pagination.pageSize}
									pageIndex={pagination.pageIndex}
									onChange={(pageSize, pageIndex) => onChange(pageSize, pageIndex)}
								/>
							</div>
						</section>
					</main>
				</Grid>
			</div>
			<Alert display={error} fixed={true} hideCloseButton={true} message={error} variant='dark' />
		</MainLayout>
	);
};

export default AuditLogs;
