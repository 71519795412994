import { getPatientSession } from 'api/dashboard.js';
import ProfilePicture from 'components/ProfilePicture.jsx';
import { Alert, Badge, CustomTable, Modal } from 'components/index.js';
import { DefaultTimezone, formatDateHeader } from 'constants/dashboard.js';
import { CallStatus } from 'constants/enums.js';
import translate from 'i18n-translations/translate.jsx';
import { getCallStatus, getCallType } from 'infrastructure/helpers/callLogsHelper.js';
import { stringToCamelCase } from 'infrastructure/helpers/commonHelpers.js';
import { formatTimeDurationWithSeconds, timezoneToUTCTimestamp, utcToTimezone } from 'infrastructure/helpers/dateHelper.js';
import { debounce } from 'lodash';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { useLocation } from 'react-router';

const Session = ({ dateRange }) => {
	const [error, setError] = useState(null);
	const [isLoading, setIsLoading] = useState(true);
	const [pagination, setPagination] = useState({ pageSize: 10, pageIndex: 0, totalCount: 0 });
	const intl = useIntl();
	const location = useLocation();
	const [sessions, setSessions] = useState([]);
	const [sessionDetails, setSessionDetails] = useState(null);
	const [sessionStatAlarms, setSessionStatAlarms] = useState([]);
	const [showSessionDetailsModal, setShowSessionDetailsModal] = useState(false);
	const [timezone, setTimezone] = useState(DefaultTimezone);
	const [copiedConferenceId, setCopiedConferenceId] = useState('');
	const conferenceIdCopiedTimeout = useRef(null);
	const controller = useRef(null);

	const fetchPatientSession = useCallback(
		debounce(async (params, signal) => {
			const response = await getPatientSession(params, signal);
			if (!response.error) {
				setPagination(prevState => ({ ...prevState, totalCount: response.totalCount }));
				setIsLoading(false);
				setSessions(prevState => [...prevState, ...response.sessions]);
			} else if (response.error.code !== 'ERR_CANCELED') {
				setError(response.error.message);
				setIsLoading(false);
			}
		}, 500),
		[]
	);

	useEffect(() => {
		if (controller.current) {
			controller.current.abort();
		}
		controller.current = new AbortController();
		const signal = controller.current.signal;

		const {
			patient,
			filters: { additionalFilters, selectedTimezone },
		} = location.state;

		const params = {
			patientId: patient.healthcareUserIntId,
			solHelloDeviceId: patient.solHelloDeviceId,
			start: timezoneToUTCTimestamp(dateRange.from, selectedTimezone.zone),
			end: timezoneToUTCTimestamp(dateRange.to, selectedTimezone.zone),
			pageSize: pagination.pageSize,
			pageIndex: pagination.pageIndex,
			...additionalFilters,
		};
		setIsLoading(true);
		setTimezone(selectedTimezone);
		fetchPatientSession(params, signal);
	}, [location, pagination.pageIndex, pagination.pageSize, dateRange, fetchPatientSession]);

	useEffect(() => {
		setSessions([]);
		setPagination(prevState => ({ ...prevState, pageIndex: 0 }));
	}, [dateRange]);

	const openSessionDetailsModal = session => {
		const { usedAudio, usedScreen, usedVideo, statAlarms } = session;
		setSessionDetails({
			usedAudio: translate(usedAudio ? 'yes' : 'no'),
			usedVideo: translate(usedVideo ? 'yes' : 'no'),
			usedScreen: translate(usedScreen ? 'yes' : 'no'),
		});
		setSessionStatAlarms(
			statAlarms?.map(statAlarm => ({
				activationTime: utcToTimezone(statAlarm.enabledDate, timezone.zone),
				deactivationTime: utcToTimezone(statAlarm.disabledDate, timezone.zone),
				duration:
					statAlarm.enabledDate && statAlarm.disabledDate
						? formatTimeDurationWithSeconds({
								seconds: statAlarm.durationInSeconds || 0,
								joinWith: ', ',
								showDays: false,
								intl,
						  })
						: 'N/A',
			})) || []
		);
		setShowSessionDetailsModal(true);
	};

	const displayCallStatus = status => {
		const translationKey = stringToCamelCase(getCallStatus(status));
		return (
			<Badge
				text={translationKey ? translate(`${translationKey}OnList`) : 'N/A'}
				variant={status === CallStatus.SUCCESSFUL.type ? 'green' : 'red'}
			/>
		);
	};

	const handleCopyConferenceId = conferenceId => {
		if (conferenceIdCopiedTimeout.current) {
			clearTimeout(conferenceIdCopiedTimeout.current);
		}
		navigator.clipboard.writeText(conferenceId);
		setCopiedConferenceId(`${intl.formatMessage({ id: 'copied' })} ${intl.formatMessage({ id: 'conferenceId' })}!`);
		conferenceIdCopiedTimeout.current = setTimeout(() => {
			setCopiedConferenceId('');
		}, 3000);
	};

	const displaySessions = () => {
		if (!sessions || sessions.length === 0) {
			return [];
		}
		return sessions.map(item => ({
			conferenceId: (
				<i
					className='material-icons-outlined cursor-pointer font-14'
					data-tooltip={`${intl.formatMessage({ id: 'copy' })} ${intl.formatMessage({ id: 'conferenceId' })}`}
					onClick={() => handleCopyConferenceId(item.conferenceId)}>
					content_copy
				</i>
			),
			callOrigin: translate(item.isInitiator ? 'incoming' : 'outgoing'),
			type: getCallType(item.callType),
			careEventType: item?.careEventTypeName || 'N/A',
			careGiver: (
				<div className='flex flex-align-center'>
					<ProfilePicture
						fullName={item.provider}
						profilePicture={item.profilePicture ?? null}
						className='provider-intervening-img-wrapper'
					/>
					<p className='margin-s no-padding'>{item.provider}</p>
				</div>
			),
			room: item.room,
			startTime: utcToTimezone(item.startTime, timezone.zone),
			endTime: utcToTimezone(item.endedTime, timezone.zone),
			duration: formatTimeDurationWithSeconds({ seconds: item.duration, joinWith: ', ', showDays: false, intl }),
			status: displayCallStatus(item.status),
			sessionDetails: (
				<span className='text-underline cursor-pointer' onClick={() => openSessionDetailsModal(item)}>
					{translate('viewDetails')}
				</span>
			),
		}));
	};

	return (
		<div>
			<div className='patients-table-wrapper'>
				<CustomTable
					headers={[
						{ title: 'ID', id: 'conferenceId' },
						{ title: translate('callOrigin'), id: 'callOrigin' },
						{ title: translate('sessionType'), id: 'type' },
						{ title: translate('careEventType'), id: 'careEventType' },
						{ title: translate('careGiver'), id: 'careGiver' },
						{ title: translate('room'), id: 'room' },
						{ title: formatDateHeader(intl, 'sessionStart', timezone.zone), id: 'startTime' },
						{ title: formatDateHeader(intl, 'sessionEnd', timezone.zone), id: 'endtime' },
						{ title: translate('sessionDuration'), id: 'duration' },
						{ title: translate('sessionStatus'), id: 'status' },
						{ title: translate('sessionDetails'), id: 'sessionDetails' },
					]}
					rows={displaySessions()}
					isLoading={isLoading}
					stickyHeader={true}
					setPagination={setPagination}
				/>
			</div>
			<Modal
				display={showSessionDetailsModal}
				position='center'
				hideActionButtons={true}
				onModalSubmit={null}
				onModalClose={() => setShowSessionDetailsModal(false)}>
				<form className='call-details'>
					<h4>{translate('mediaUsage')}</h4>
					{!!sessionDetails && (
						<CustomTable
							headers={[
								{ title: translate('audioUsed'), id: 'usedAudio' },
								{ title: translate('videoUsed'), id: 'usedVideo' },
								{ title: translate('screenUsed'), id: 'usedScreen' },
							]}
							rows={[sessionDetails]}
						/>
					)}
					<h4>{translate('statAlarms')}</h4>
					{!!sessionStatAlarms && (
						<CustomTable
							headers={[
								{ title: translate('activationTime'), id: 'activationTime' },
								{ title: translate('deactivationTime'), id: 'deactivationTime' },
								{ title: translate('duration'), id: 'duration' },
							]}
							rows={sessionStatAlarms}
							tableEmptyText={translate('noStatAlarmsActivated')}
						/>
					)}
				</form>
			</Modal>
			<Alert display={error} fixed={true} onClose={() => setError(null)} message={error} variant='dark' />
			<Alert display={copiedConferenceId} fixed={true} message={copiedConferenceId} variant='dark' />
		</div>
	);
};

export default Session;
