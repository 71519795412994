import Modal from 'components/Modal.jsx';
import { Form, Formik } from 'formik';
import Input from 'components/Input.jsx';
import TreeViewMayo from 'components/TreeViewMayo.jsx';
import translate from 'i18n-translations/translate.jsx';
import { useIntl } from 'react-intl';
import { useEffect, useState } from 'react';
import { editNurseStation, getAssignedDevices, getSubscribedRooms } from 'api/nurseStation.js';
import { findSectorById } from 'infrastructure/helpers/commonHelpers.js';
import { useSelector } from 'react-redux';
import Alert from 'components/Alert.jsx';

const ManageNurseStation = props => {
	const intl = useIntl();
	const [alertFeeds, setAlertFeeds] = useState([]);
	const [error, setError] = useState(null);
	const healthSystems = useSelector(state => state.healthSystems);
    const userSession = useSelector(state => state.user.userSession);
    const maximumFeedLength = 32;

	useEffect(() => {
		const fetchRooms = async deviceId => {
			const response = await getSubscribedRooms(deviceId);
			if (response.error) {
				setError(translate('somethingWentWrong'));
			} else {
                const subscribedRooms = response.data.subscribedDevices.map(room => getMappedFeed(room.deviceId));
                setAlertFeeds(subscribedRooms);
			}
		};
		if (props.initialValues?.deviceId) {
			fetchRooms(props.initialValues.deviceId);
		}
	}, [props.initialValues?.deviceId, props.isEditModalOpen]);

	const getMappedFeed = deviceId => {
		const sector = findSectorById(healthSystems.treeData.tree, deviceId);

		const { roomId, hospitalId, departmentId, floorId, name, aiPrivacyStatus } = sector;
		return {
			deviceId,
			roomName: name,
			roomId,
			hospitalId,
			departmentId,
			floorId,
			deviceOwner: {},
			hospitalName: name,
			lastCondition: {
				code: '',
				display: '',
				risk: '',
			},
			alertInfo: {},
			aiPrivacyStatus,
			healthSystemId: userSession.healthSystem.id,
			intervention: {},
		};
	};

	const onAddDevice = async selection => {
		if (alertFeeds.length < maximumFeedLength) {
			const { helloDeviceId } = selection;
			const selectedFeed = alertFeeds.find(item => item.helloDeviceId === helloDeviceId);
			if (selectedFeed) {
				return;
			}
			const feed = getMappedFeed(helloDeviceId);
			setAlertFeeds(prevState => {
				const alreadyExists = prevState.some(existingFeed => existingFeed.deviceId === feed.deviceId);

				if (alreadyExists) {
					return prevState;
				}
				const newState = [...prevState, feed];

				return newState;
			});
		}
	};

	const onSubmit = async () => {
		props.setIsLoading(true);
		const { initialValues } = props;
		const deviceId = initialValues.deviceId;

		const payload = alertFeeds.map(feed => ({
			deviceId: feed.deviceId,
			roomId: feed.roomId,
		}));

		const editDeviceResponse = await editNurseStation(userSession.healthSystem.id, deviceId, payload);

		if (editDeviceResponse.error) {
			setError(translate('somethingWentWrong'));
		}

		const assignedDevices = await getAssignedDevices(
			userSession.healthSystem.id,
			props.pagination.pageSize,
			props.pagination.pageIndex
		);

		if (assignedDevices.error) {
			setError(translate('somethingWentWrong'));
		} else {
			props.setAssignedDevices(assignedDevices?.data);
		}

		props.setIsLoading(false);
	};

	const onRemoveFeed = deviceId => {
		setAlertFeeds(alertFeeds.filter(feed => feed.deviceId !== deviceId));
	};

	return (
		<div>
			<Formik initialValues={props.initialValues} onSubmit={onSubmit}>
				{formikProps => {
					const { values, handleChange, handleSubmit } = formikProps;
					return (
						<Modal
							className='wrapper-modal border-radius-modal-wrapper appoinment-next-arrow-modal'
							display={props.isEditModalOpen}
							position='right'
							isLoading={props.isLoading}
							onModalClose={() => {
								setAlertFeeds([]);
								props.setIsEditModalOpen(false);
							}}
							onModalSubmit={handleSubmit}>
							<Form>
								<h3>Editing {props.initialValues.deviceName}</h3>
								<div className='disabled'>
									<Input
										type='text'
										label={translate('deviceName')}
										name='name'
										value={props.initialValues?.deviceName}
										placeholder={intl.formatMessage({ id: 'deviceName' })}
										onChange={handleChange}
										validationOptions={{}}
										bottomSpace='20px'
									/>
								</div>

								<div>
									<Input
										className='tree-search'
										type='text'
										name='searchTree'
										label={translate('roomsWhichWillBeMonitored')}
										placeholder={intl.formatMessage({ id: 'search' })}
										onChange={e => {
											handleChange(e);
											props.handleTreeSearch(e, values);
										}}
										validationOptions={{}}
										bottomSpace='20px'
										autoComplete='off'
									/>
									<TreeViewMayo
										data={props.data}
										isNurseStation={true}
										onAddDevice={onAddDevice}
										alertFeeds={alertFeeds}
										onRemoveFeed={onRemoveFeed}
										expandAll={props.expandAllTreeItems}
									/>
								</div>
							</Form>
						</Modal>
					);
				}}
			</Formik>
			<Alert display={error} message={error} variant='dark' fixed={true} onClose={() => setError(null)} />
		</div>
	);
};

export default ManageNurseStation;
